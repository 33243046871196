<template>
  <div>
    <Display />
  </div>
</template>

<script>
import Display from "@/components/ui/verify/Display.vue";
export default {
  components: {
    Display,
  },
  name: "Verify",
  data() {
    return {};
  },
};
</script>

<style scoped src="@/assets/styles/dbt-style.css"></style>