import Vue from "vue";
import VueRouter from "vue-router";
// PAGE
import Register from "../views/Register.vue";
import Followerlist from "../components/ui/register/detail/Followerlist";
import Registerdetail from "../components/ui/register/detail/Registerdetail";
import Follower from "../components/ui/register/detail/Follower";

import qrcode from "../components/ui/register/detail/qrcode";
import Verify from "@/views/Verify.vue";
import Detail from "@/views/Detail.vue";
import Manual from "@/views/Manual.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/register/follower",
    name: "follower",
    component: Follower,
    props: true,
  },
  {
    path: "/register/followerlist",
    name: "Followerlist",
    component: Followerlist,
    props: true,
  },
  {
    path: "/register/registerdetail",
    name: "registerdetail",
    component: Registerdetail,
    props: true,
  },
  {
    path: "/register/qrcode",
    name: "qrcode",
    component: qrcode,
    props: true,
  },
  {
    path: "/Verify",
    name: "Verify",
    component: Verify,
    props: true,
  },
  {
    path: "/Detail",
    name: "Detail",
    component: Detail,
  },
  {
    path: "/Manual",
    name: "Manual",
    component: Manual,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
