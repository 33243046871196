<template>
  <div class="dbt-content">
    <v-row>
      <v-col md="10" sm="12" offset-md="1">
        <v-row>
          <v-col sm="12">
            <v-card elevation="2" class="dbt-content-card">
              <div class="title">ข้อมูลหนังสือผ่านแดน</div>
              <div class="context">
                <v-row>
                  <v-col cols="12">
                    <div class="context">
                      <v-row>
                        <v-flex
                          md="12"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <center>
                            <img
                              :src="imageFace"
                              style="cursor: zoom-in; width: 142px"
                            />
                          </center>
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="6"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label">ด่าน</div>
                          <v-text-field v-model="borderDesc" readonly />
                        </v-flex>
                        <v-flex
                          md="6"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label">ประเภทหนังสือ</div>
                          <v-text-field v-model="bpTypeDesc" readonly />
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="6"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label">เลขประจำตัวประชาชน</div>
                          <v-text-field v-model="pid" readonly />
                        </v-flex>
                        <v-flex
                          md="6"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label">ชื่อ - สกุล</div>
                          <v-text-field v-model="nameFormat" readonly />
                        </v-flex>
                      </v-row>
                      <v-row v-if="dataRegister.bpopNo !== 0">
                        <v-flex
                          md="6"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label">วันที่ออกหนังสือผ่านแดน</div>
                          <v-text-field v-model="issDate" readonly />
                        </v-flex>
                        <v-flex
                          md="6"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label">
                            วันที่หมดอายุหนังสือผ่านแดน
                          </div>
                          <v-text-field v-model="expDate" readonly />
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="6"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <v-card>
                            <v-tabs
                              v-model="tab"
                              background-color="#1F4680"
                              centered
                              dark
                              icons-and-text
                              show-arrows
                            >
                              <v-tabs-slider></v-tabs-slider>
                              <v-tab
                                href="#tab-1"
                                v-if="dataRegister.bpopNo !== 0"
                              >
                                QRCode
                                <v-icon>ri-qr-code-line</v-icon>
                              </v-tab>
                              <v-tab
                                href="#tab-2"
                                v-if="
                                  isBpType === 1 && dataRegister.bpopNo !== 0
                                "
                              >
                                Barcode
                                <v-icon>ri-barcode-line</v-icon>
                              </v-tab>
                              <v-tab
                                href="#tab-3"
                                v-if="
                                  isBpType === 2 && dataRegister.bpopNo !== 0
                                "
                              >
                                MRZ
                                <v-icon>ri-passport-line</v-icon>
                              </v-tab>
                              <v-tab href="#tab-4">
                                ตัวอย่างหนังสือผ่านแดน
                                <v-icon>ri-book-open-line</v-icon>
                              </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab">
                              <v-tab-item
                                :value="`tab-1`"
                                v-if="dataRegister.bpopNo !== 0"
                              >
                                <v-card flat>
                                  <v-card-text>
                                    <center>
                                      <v-flex>
                                        <VueQrcode :value="qrCodeData" />
                                      </v-flex>
                                      <v-flex>
                                        <label style="color: black">
                                          {{ qrCodeData }}
                                        </label>
                                      </v-flex>
                                    </center>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item
                                :value="`tab-2`"
                                v-if="
                                  isBpType === 1 && dataRegister.bpopNo !== 0
                                "
                              >
                                <v-card flat class="ro90BCode">
                                  <v-card-text>
                                    <center class="ro90wBCode">
                                      <v-flex>
                                        <VueBarcode
                                          :value="barCodeData"
                                          width="2"
                                          fontSize="16"
                                          id="testGen"
                                        >
                                          Show this if the rendering fails.
                                        </VueBarcode>
                                      </v-flex>
                                    </center>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item
                                :value="`tab-3`"
                                v-if="
                                  isBpType === 2 && dataRegister.bpopNo !== 0
                                "
                              >
                                <v-card flat class="ro90Mrz">
                                  <v-card-text>
                                    <center class="ro90wMrz">
                                      <v-flex>
                                        <label
                                          style="
                                            font-family: ORCB;
                                            color: black;
                                          "
                                          >{{ mrz1 }}</label
                                        >
                                      </v-flex>
                                      <v-flex>
                                        <label
                                          style="
                                            font-family: 'ORCB';
                                            color: black;
                                          "
                                          >{{ mrz2 }}</label
                                        >
                                      </v-flex>
                                    </center>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                              <v-tab-item :value="`tab-4`">
                                <v-card flat>
                                  <v-card-text>
                                    <center>
                                      <exBookTemp
                                        :dataPara="dataRegister"
                                        :face="imageFace"
                                        v-if="isBpType === 1"
                                      />
                                      <exBook
                                        :dataPara="dataRegister"
                                        :face="imageFace"
                                        v-if="isBpType === 2"
                                      />
                                      <!-- <img
                                        id="img"
                                        :src="exPB"
                                        style="width: -webkit-fill-available"
                                        @click="saveImg"
                                      />
                                      <v-btn class="btn dbt-btn-blue">
                                        <a
                                          class="button"
                                          style="
                                            color: white;
                                            text-decoration: unset;
                                          "
                                          :href="exPB"
                                          download="example_book.png"
                                          >ดาวน์โหลดตัวอย่างหนังสือผ่านแดน</a
                                        >
                                      </v-btn> -->
                                    </center>
                                  </v-card-text>
                                </v-card>
                              </v-tab-item>
                            </v-tabs-items>
                          </v-card>
                        </v-flex>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <hr />
                <div class="dbt-btn-row">
                  <v-btn class="btn dbt-btn-blue" @click="handleBack">
                    กลับ
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import VueBarcode from "vue-barcode";
import exBookTemp from "./reqJs/exBookTemp.vue";
import exBook from "./reqJs/exBook.vue";
import borderlist from "@/js/borderlist.js";
import { getpicAPI } from "@/api/imgcard.js";
import { getFollowAPI } from "@/api/register.js";
import { getAADescAPI, getccAPI } from "@/api/house.js";

export default {
  name: "DetailBook",
  props: {
    dataPara: {
      type: Object,
    },
  },
  components: {
    VueQrcode,
    VueBarcode,
    exBookTemp,
    exBook,
  },
  data() {
    return {
      dataRegister: null,
      tab: null,
      imageFace: null,
      isBpType: null,
      mrz1: null,
      mrz2: null,
      qrCodeData: null,
      barCodeData: null,
      borderDesc: null,
      bpTypeDesc: null,
      pid: null,
      issDate: null,
      expDate: null,
      nameFormat: null,
      dataExBook: null,
    };
  },
  // watch: {
  //   dataPara: function () {
  //     this.dataRegister = this.dataPara;
  //     this.prepareData();
  //   },
  // },
  async mounted() {
    this.dataRegister = this.dataPara;
    this.prepareData();
  },
  methods: {
    async prepareData() {
      this.isBpType = this.dataRegister.bpType;
      if (this.dataRegister.bpType === 1) {
        if (this.dataRegister.bpopNo !== 0) {
          const barcodeGen = `${this.dataRegister.ccaa}${String(
            this.dataRegister.borderCode
          ).padStart(3, "0")}${this.dataRegister.bpopYear}${String(
            this.dataRegister.bpopNo
          ).padStart(7, "0")}`;
          this.barCodeData = barcodeGen;
          const qrCodeGen = `${this.dataRegister.pid}${
            this.dataRegister.title
          }${this.dataRegister.faname}${
            this.dataRegister.lname
          }${this.dateFormatNumber(this.dataRegister.dob)}${
            this.dataRegister.sex
          }ไทย${barcodeGen}${this.dateFormatNumber(
            this.dataRegister.expireDate
          )}`;
          this.qrCodeData = qrCodeGen;
        }
        this.bpTypeDesc = "หนังสือผ่านแดนชั่วคราว";
      } else {
        this.bpTypeDesc = "หนังสือผ่านแดนถาวร";
        if (this.dataRegister.bpopNo !== 0) {
          const qrCodeGen = `${this.dataRegister.pid};${
            this.dataRegister.title
          };${this.dataRegister.faname};${
            this.dataRegister.lname
          };${this.dateFormatNumber(this.dataRegister.dob)};${
            this.dataRegister.sex
          };ไทย;เลขที่หนังสือ;${this.dateFormatNumber(
            this.dataRegister.expireDate
          )};`;
          this.qrCodeData = qrCodeGen;
          const nameEng = this.dataRegister.ename.split("#");
          const mrzGen = {
            fname: nameEng[1].toUpperCase(),
            lname: nameEng[3].toUpperCase(),
            mrzGen: this.dataRegister.bpNo,
            dob: String(this.dataRegister.dob - 5430000).substring(2, 8),
            sex: this.dataRegister.sex === 1 ? "M" : "F",
            issueDate: String(this.dataRegister.expireDate - 5430000).substring(
              2,
              8
            ),
            pid: `${this.dataRegister.pid}<`,
          };
          this.mrz(mrzGen);
        }

        if (Number(String(this.dataRegister.ccaa).substring(2, 4)) === 0) {
          let ccDesc = await getccAPI(
            String(this.dataRegister.ccaa).substring(0, 2)
          );
          if (ccDesc.result) {
            Object.assign(this.dataRegister, {
              ccDescTh: ccDesc.result[0].description.thai,
              ccDescEn: ccDesc.result[0].description.english,
              aaDescTh: "",
              aaDescEn: "",
            });
          }
        } else {
          let aaDesc = await getAADescAPI(this.dataRegister.ccaa);
          if (aaDesc.result) {
            Object.assign(this.dataRegister, {
              aaDescTh: aaDesc.result[0].description.thai,
              aaDescEn: aaDesc.result[0].description.english,
              ccDescTh: "",
              ccDescEn: "",
            });
          }
        }
      }
      let pic = await getpicAPI(this.dataRegister.pid);
      if (pic.result) {
        this.imageFace = `${pic.result.mineType},${pic.result.image}`;
      }
      if (this.dataRegister.bpopNo !== 0) {
        this.issDate = this.dateFormat(
          this.dataRegister.issueDate
        ).toStringType1ThaiShort();
        this.expDate = this.dateFormat(
          this.dataRegister.expireDate
        ).toStringType1ThaiShort();
      }
      this.pid = this.PIDFormat(this.dataRegister.pid);
      this.borderDesc = borderlist.maplistplace(
        Number(
          `${this.dataRegister.ccaa}${String(
            this.dataRegister.borderCode
          ).padStart(3, "0")}`
        )
      );
      if (this.dataRegister.titleCode === 999) {
        if (
          this.dataRegister.titleSex === 5 ||
          this.dataRegister.titleSex === 6
        ) {
          if (this.dataRegister.title === "พระ") {
            this.nameFormat = `${this.dataRegister.title}${this.dataRegister.fname} ${this.dataRegister.lname}`;
          } else {
            this.nameFormat = `${this.dataRegister.title}(${this.dataRegister.fname} ${this.dataRegister.lname})`;
          }
        }
      } else if (
        this.dataRegister.titleSex === 5 ||
        this.dataRegister.titleSex === 6
      ) {
        if (this.dataRegister.title === "พระ") {
          this.nameFormat = `${this.dataRegister.title}${this.dataRegister.fname} ${this.dataRegister.lname}`;
        } else {
          this.nameFormat = `${this.dataRegister.title}(${this.dataRegister.fname} ${this.dataRegister.lname})`;
        }
      } else {
        this.nameFormat = `${this.dataRegister.title}${this.dataRegister.fname} ${this.dataRegister.lname}`;
      }

      if (this.dataRegister.follower > 0) {
        let follow = await getFollowAPI(
          this.dataRegister.reqId,
          this.dataRegister.reqYear
        );
        if (follow.result) {
          this.dataRegister.listFollow = follow.result;
          for (let i = 0; i < this.dataRegister.listFollow.length; i++) {
            pic = await getpicAPI(this.dataRegister.listFollow[i].pid);
            if (pic.result) {
              Object.assign(this.dataRegister.listFollow[i], {
                face: `${pic.result.mineType},${pic.result.image}`,
              });
            }
          }
        }
      }
    },
    dateFormatNumber(date) {
      date = String(date);
      return `${date.substring(6, 8)}${date.substring(4, 6)}${date.substring(
        0,
        4
      )}`;
    },
    handleBack() {
      this.$emit("returnBack", true);
    },
    mrz(Data) {
      let mrzCodeLine1 = "";
      let mrzCodeLine2 = "";
      let fname = Data.fname;
      let lname = Data.lname;
      // เลขที่หนังสือ
      let mrzGen = Data.mrzGen;
      let dob = Data.dob;
      let sex = Data.sex;
      let issueDate = Data.issueDate;
      let pid = Data.pid;
      let finalDigitData = this.checkDigit(
        `${mrzGen}${this.checkDigit(mrzGen)}${dob}${this.checkDigit(
          dob
        )}${issueDate}${this.checkDigit(issueDate)}${pid}${this.checkDigit(
          pid
        )}`
      );
      mrzCodeLine1 = `P<THA${lname}<<${fname}`;
      mrzCodeLine1 = mrzCodeLine1.padEnd(44, "<");
      mrzCodeLine2 = `${mrzGen}${this.checkDigit(
        mrzGen
      )}THA${dob}${this.checkDigit(dob)}${sex}${issueDate}${this.checkDigit(
        issueDate
      )}${pid}${this.checkDigit(pid)}${finalDigitData}`;
      this.mrz1 = mrzCodeLine1;
      this.mrz2 = mrzCodeLine2;
    },
    checkDigit(data) {
      let sumDigit = 0;
      let Weight = [7, 3, 1];
      let indexWeight = 0;
      for (let i = 0; i < data.length; i++) {
        if (indexWeight == 3) {
          indexWeight = 0;
        }
        if (data.substring(i, i + 1) != "<") {
          if (isNaN(Number(data.substring(i, i + 1)) * Weight[indexWeight])) {
            sumDigit +=
              (data.substring(i, i + 1).charCodeAt(0) - 55) *
              Weight[indexWeight];
          } else {
            sumDigit += Number(data.substring(i, i + 1)) * Weight[indexWeight];
          }
          indexWeight += 1;
        } else {
          indexWeight += 1;
        }
      }
      return sumDigit % 10;
    },
  },
};
</script>

<style scoped src="@/assets/styles/dbt-style.css"></style>
