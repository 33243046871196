<template>
  <div class="dbt-content">
    <v-row justify="center" v-resize="handleResize">
      <v-dialog
        content-class="shadow-none"
        v-model="dialog"
        :fullscreen="window.width < 765"
        :persistent="window.width > 765"
        hide-overlay
        max-width="1150"
        transition="dialog-bottom-transition"
      >
        <v-col md="12" sm="12" style="padding: 0px;">
          <v-card elevation="2" class="dbt-content-card login5Feild">
            <div class="title" style="padding-bottom: 5px;">
              <div style="font-size:1.5em"><center>E-Borderpass</center></div>
              <hr style="margin-top: 5px;margin-bottom: 0px" />
              <div>ข้อมูลตามบัตรประจำตัวประชาชน</div>
            </div>
            <div class="context">
              <v-row>
                <v-col cols="12" style="padding: 0px">
                  <div class="context">
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-row>
                              <v-col cols="12">
                                <div class="dbt-label">เลขประจำตัวประชาชน</div>
                                <v-text-field
                                  outlined
                                  prefix="*"
                                  class="red--text"
                                  v-mask="'#-####-#####-##-#'"
                                  v-model="login5feild.pid"
                                  placeholder="0-0000-00000-00-0"
                                  :readonly="isProgress"
                                  hide-details="auto"
                                />
                              </v-col>
                              <v-col cols="12">
                                <div class="dbt-label">เลขรหัสกำกับบัตร</div>
                                <v-text-field
                                  outlined
                                  prefix="*"
                                  class="red--text"
                                  v-mask="'AA#-#######-##'"
                                  v-model="login5feild.laser"
                                  placeholder="AA0-0000000-00"
                                  :readonly="isProgress"
                                  hide-details="auto"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="4">
                            <img
                              alt="laser.png"
                              class="card-style"
                              src="@/assets/laser.png"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-flex
                        md="4"
                        sm="12"
                        pt-2
                        style="padding: 0px 10px 0px 10px"
                      >
                        <div class="dbt-label">
                          ชื่อ (ภาษาไทย ไม่ต้องระบุคำนำหน้านาม)
                        </div>
                        <v-text-field
                          outlined
                          prefix="*"
                          class="red--text"
                          v-model="login5feild.fname"
                          placeholder="ชื่อ (ภาษาไทย ไม่ต้องระบุคำนำหน้านาม)"
                          :readonly="isProgress"
                          hide-details="auto"
                        />
                      </v-flex>
                      <v-flex
                        md="4"
                        offset-md="4"
                        sm="12"
                        pt-2
                        style="padding: 0px 10px 0px 10px"
                      >
                        <div class="dbt-label">ชื่อสกุล (ภาษาไทย)</div>
                        <v-text-field
                          outlined
                          prefix="*"
                          class="red--text"
                          v-model="login5feild.lname"
                          placeholder="ชื่อสกุล (ภาษาไทย)"
                          :readonly="isProgress"
                          hide-details="auto"
                        />
                      </v-flex>
                    </v-row>
                    <v-row style="display: -webkit-inline-box">
                      <v-flex md="12" sm="12">
                        <v-radio-group
                          v-model="dobType"
                          hide-details="auto"
                          row
                          v-if="window.width > 765"
                          :readonly="isProgress"
                        >
                          <v-radio
                            label="มีวัน/เดือน/ปีเกิด"
                            value="1"
                            @click="handelDobType"
                            :readonly="isProgress"
                          />
                          <v-radio
                            label="มีเฉพาะวันและปีเกิด"
                            value="2"
                            @click="handelDobType"
                            :readonly="isProgress"
                          />
                          <v-radio
                            label="มีเฉพาะเดือนและปีเกิด"
                            value="3"
                            @click="handelDobType"
                            :readonly="isProgress"
                          />
                          <v-radio
                            label="มีเฉพาะปีเกิด"
                            value="4"
                            @click="handelDobType"
                            :readonly="isProgress"
                          />
                        </v-radio-group>
                        <v-radio-group
                          v-model="dobType"
                          hide-details="auto"
                          column
                          v-else
                        >
                          <v-radio
                            label="มีวัน/เดือน/ปีเกิด"
                            value="1"
                            @click="handelDobType"
                            :readonly="isProgress"
                          />
                          <v-radio
                            label="มีเฉพาะวันและปีเกิด"
                            value="2"
                            @click="handelDobType"
                            :readonly="isProgress"
                          />
                          <v-radio
                            label="มีเฉพาะเดือนและปีเกิด"
                            value="3"
                            @click="handelDobType"
                            :readonly="isProgress"
                          />
                          <v-radio
                            label="มีเฉพาะปีเกิด"
                            value="4"
                            @click="handelDobType"
                            :readonly="isProgress"
                          />
                        </v-radio-group>
                      </v-flex>
                    </v-row>
                    <v-row>
                      <v-flex
                        md="12"
                        sm="4"
                        pt-2
                        style="padding: 0px 10px 0px 10px"
                      >
                        <v-select
                          outlined
                          label="ปี"
                          prefix="*"
                          class="red--text"
                          :items="listYear"
                          v-model="dobYear"
                          :readonly="isProgress"
                          hide-details="auto"
                        ></v-select>
                      </v-flex>
                      <v-flex
                        md="12"
                        sm="4"
                        pt-2
                        style="padding: 0px 10px 0px 10px"
                        v-if="dobType === '1' || dobType === '3'"
                      >
                        <v-select
                          outlined
                          label="เดือน"
                          prefix="*"
                          class="red--text"
                          :items="listMonth"
                          item-text="text"
                          item-value="key"
                          @change="handleListDay"
                          v-model="dobMonth"
                          :readonly="isProgress"
                          hide-details="auto"
                        ></v-select>
                      </v-flex>
                      <v-flex
                        md="12"
                        sm="4"
                        pt-2
                        style="padding: 0px 10px 0px 10px"
                        v-if="dobType === '1' || dobType === '2'"
                      >
                        <v-select
                          outlined
                          label="วัน"
                          prefix="*"
                          class="red--text"
                          :items="listDay"
                          v-model="dobDay"
                          :readonly="isProgress"
                          hide-details="auto"
                        ></v-select>
                      </v-flex>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <br />
              <hr />
              <div class="dbt-btn-row">
                <v-btn
                  v-if="window.width > 765"
                  class="btn dbt-btn-blue"
                  @click="verify5Field"
                  :disabled="!isSubmit"
                >
                  ตรวจสอบ
                </v-btn>
                <v-btn
                  block
                  v-else
                  class="btn dbt-btn-blue"
                  @click="verify5Field"
                  :disabled="!isSubmit"
                >
                  ตรวจสอบ
                </v-btn>
              </div>
            </div>
            <v-alert v-if="flagVerify == 1" dense text type="success">
              <strong>ผ่านตรวจสอบข้อมูลเรียบร้อย</strong>
            </v-alert>
            <v-alert v-if="flagVerify == 2" dense text type="error">
              <strong>ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบ</strong>
            </v-alert>
            <v-progress-linear
              v-if="isProgress"
              indeterminate
              color="cyan"
            ></v-progress-linear>
          </v-card>
        </v-col>
      </v-dialog>
    </v-row>
    <Dialog :alertDialog="alertDialog" :waitDialog="waitDialog" />
  </div>
</template>

<script>
import { postLogin5Feild } from "@/api/auth";
import Dialog from "@/components/ui/dialog/Dialog";

export default {
  name: "login5Feild",
  data() {
    return {
      waitDialog: false,
      alertDialog: {
        val: false,
        msg: "",
        type: "",
      },
      dialog: false,
      login5feild: {
        pid: null,
        fname: null,
        lname: null,
        dob: 0,
        laser: null,
      },
      dobType: "1",
      dobYear: null,
      dobMonth: null,
      dobDay: null,
      listYear: [],
      dataResult: null,
      listMonth: [
        {
          key: 1,
          text: "มกราคม",
        },
        {
          key: 2,
          text: "กุมภาพันธ์",
        },
        {
          key: 3,
          text: "มีนาคม",
        },
        {
          key: 4,
          text: "เมษายน",
        },
        {
          key: 5,
          text: "พฤษภาคม",
        },
        {
          key: 6,
          text: "มิถุนายน",
        },
        {
          key: 7,
          text: "กรกฎาคม",
        },
        {
          key: 8,
          text: "สิงหาคม",
        },
        {
          key: 9,
          text: "กันยายน",
        },
        {
          key: 10,
          text: "ตุลาคม",
        },
        {
          key: 11,
          text: "พฤศจิกายน",
        },
        {
          key: 12,
          text: "ธันวาคม",
        },
      ],
      listDay: [],
      isSubmit: false,
      isProgress: false,
      flagVerify: 0,
      window: {
        width: 0,
        height: 0,
      },
      callback: null,
    };
  },
  watch: {
    dobType: function () {
      if (this.dobType !== "4") this.isSubmit = false;
      this.dobMonth = null;
      this.dobDay = null;
    },
    dobYear: function () {
      this.checkData5Feild();
      this.dobMonth = null;
      this.dobDay = null;
      this.listDay = [];
    },
    dobMonth: function () {
      this.checkData5Feild();
      this.dobDay = null;
    },
    dobDay: function () {
      this.checkData5Feild();
    },
    "login5feild.pid": function () {
      this.checkData5Feild();
    },
    "login5feild.laser": function () {
      this.checkData5Feild();
    },
    "login5feild.fname": function () {
      this.checkData5Feild();
    },
    "login5feild.lname": function () {
      this.checkData5Feild();
    },
  },
  components: {
    Dialog,
  },
  mounted() {
    const d = new Date();
    const n = d.getFullYear() + 543;
    for (let index = 0; index < 150; index++) {
      this.listYear.push({
        text: n - index,
      });
    }

    this.handleResize();
  },
  methods: {
    checkThaiVowel(key) {
      if (!key) key = "";
      if (
        [
          "ๆ",
          "ำ",
          "ะ",
          "ํ",
          "๊",
          "ฯ",
          "้",
          "็",
          "่",
          "๋",
          "า",
          ".",
          "ิ",
          "ฺ",
          "ื",
          "์",
          "ุ",
          "ู",
          "ึ",
          "ๅ",
        ].includes(key.substring(0, 1))
      ) {
        return this.checkThaiVowel(key.substring(1));
      }

      return key;
    },
    uppercase() {
      this.login5feild.laser = this.login5feild.laser.toUpperCase();
    },
    handelDobType() {
      if (this.dobType === "2") {
        this.listDay = [];
        for (let index = 0; index < 31; index++) {
          this.listDay.push({
            text: index + 1,
          });
        }
        this.dobDay = null;
      } else {
        this.listDay = [];
        this.dobDay = null;
      }
    },
    handleListDay() {
      if ([1, 3, 5, 7, 8, 10, 12].includes(this.dobMonth)) {
        this.listDay = [];
        for (let index = 0; index < 31; index++) {
          this.listDay.push({
            text: index + 1,
          });
        }
        this.dobDay = null;
      } else if ([4, 6, 9, 11].includes(this.dobMonth)) {
        this.listDay = [];
        for (let index = 0; index < 30; index++) {
          this.listDay.push({
            text: index + 1,
          });
        }
        this.dobDay = null;
      } else {
        let day = this.isLeapYear(Number(this.dobYear) - 543) ? 29 : 28;
        this.listDay = [];
        for (let index = 0; index < day; index++) {
          this.listDay.push({
            text: index + 1,
          });
        }
        this.dobDay = null;
      }
    },
    isLeapYear(year) {
      return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    checkData5Feild() {
      this.login5feild.fname = this.checkThaiVowel(this.login5feild.fname);
      this.login5feild.lname = this.checkThaiVowel(this.login5feild.lname);
      if (!this.login5feild.pid) return (this.isSubmit = false);
      if (String(this.login5feild.pid).length != 17)
        return (this.isSubmit = false);
      if (!this.login5feild.laser) return (this.isSubmit = false);
      if (String(this.login5feild.laser).length != 14)
        return (this.isSubmit = false);
      if (!this.login5feild.fname) return (this.isSubmit = false);
      if (!this.login5feild.lname) return (this.isSubmit = false);
      if (!this.dobYear) return (this.isSubmit = false);
      if (this.dobType === "4") return (this.isSubmit = true);
      if (this.dobType !== "3")
        if (!this.dobDay) return (this.isSubmit = false);
      if (this.dobType !== "2")
        if (!this.dobMonth) return (this.isSubmit = false);
      return (this.isSubmit = true);
    },
    async verify5Field() {
      this.waitDialog = true;
      let dob = String(this.dobYear);
      if (this.dobType === "1") {
        dob += `${String(this.dobMonth).padStart(2, "0")}${String(
          this.dobDay
        ).padStart(2, "0")}`;
      } else if (this.dobType === "2") {
        dob += `00${String(this.dobDay).padStart(2, "0")}`;
      } else if (this.dobType === "3") {
        dob += `${String(this.dobMonth).padStart(2, "0")}00`;
      } else {
        dob += `0000`;
      }

      const body = {
        pid: this.login5feild.pid.replaceAll("-", ""),
        laser: this.login5feild.laser.replaceAll("-", ""),
        fname: this.login5feild.fname,
        lname: this.login5feild.lname,
        dob: dob,
      };

      this.flagVerify = 0;
      this.isProgress = true;
      this.isSubmit = false;
      let result = await postLogin5Feild(body);
      this.isProgress = false;
      this.isSubmit = true;

      if (result.error) {
        this.waitDialog = false;
        return (this.flagVerify = 2);
      }
      if (result.data) this.flagVerify = 1;

      setTimeout((_) => {
        this.waitDialog = false;
        this.dialog = false;
        this.callback({ result });
      }, 2000);
    },
    async show() {
      return new Promise((resolve, reject) => {
        this.callback = resolve;
        this.dialog = true;
      });
    },
    deleteData() {
      this.login5feild = {
        pid: null,
        fname: null,
        lname: null,
        dob: 0,
        laser: null,
      }

      this.flagVerify = 0;
      this.dobType = "1";
      this.dobYear = null;
      this.dobMonth = null;
      this.dobDay = null;
    }
  },
};
</script>
<style scoped src="@/assets/styles/dbt-style.css"></style>
<style>
.v-dialog.shadow-none {
  margin: 0px !important;
  box-shadow: none !important;
}
</style>
<style scoped>
.card-style {
  width: 100%;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 999998;
}
.red-dot {
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 1.5s;
  z-index: 999999;
}
.red-dot-top-left {
  position: fixed;
  top: 0;
  left: 0;
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 1.5s;
  z-index: 999999;
}
.red-dot-top-right {
  position: fixed;
  top: 0;
  left: calc(100% - 50px);
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 1.5s;
  z-index: 999999;
}
.red-dot-bottom-left {
  position: fixed;
  top: calc(100% - 50px);
  left: 0;
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 1.5s;
  z-index: 999999;
}
.red-dot-bottom-right {
  position: fixed;
  top: calc(100% - 50px);
  left: calc(100% - 50px);
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 1.5s;
  z-index: 999999;
}
</style>
