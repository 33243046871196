<template>
  <div>
    <Display @returnBook="dataBook" v-if="isShow" />
    <DetailBook :dataPara="dataRegis" @returnBack="back" v-if="!isShow" />
  </div>
</template>

<script>
import Display from "@/components/ui/detail/Display.vue";
import DetailBook from "@/components/ui/detail/DetailBook.vue";
export default {
  components: {
    Display,
    DetailBook,
  },
  name: "Detail",
  data() {
    return {
      isShow: true,
      dataRegis: null,
    };
  },
  methods: {
    async dataBook(val) {
      this.dataRegis = val;
      this.isShow = false;
    },
    async back(val) {
      this.isShow = val;
    },
  },
};
</script>

<style scoped src="@/assets/styles/dbt-style.css"></style>
