import axios from 'axios'
import withResolve from '@cdglib/js-resolve-style'

const base = '/api/v1/cancel'
const cancelRegister = async (path) => {
  const putApi = () => axios.put(`${base}/${path}`)
  const [error, result] = await withResolve(putApi)()
  if (error) return { error }
  return { error, result: result.data }
}

export { cancelRegister }
