const keyFilter = (event, type, lang) => {
  const allowedArrowsAndKeys = [8, 9, 37, 39, 46, 32];
  const supportRules = [
    "nameEnG",
    "nameEnOnly",
    "nameEn",
    "nameTh",
    "number",
    "numberWithHyphen"
  ]; //เพิ่ม rule ตรงนี้
  const stopEvent = () => {
    if (event) event.preventDefault();
    if (event) event.stopPropagation();

    return null;
  }
  
  //เอา rule มาใส่ RegExp ตรงนี้
  const regexTh = () => {
    if (["nameEnG"].includes(String(type))) return /[a-zA-Z]/; // return /[A-z]/; //ภาษาอังกฤษ ตัวพิมพ์เล็กหรือพิมพ์ใหญ่เท่านั้น
    if (["nameEnOnly"].includes(String(type))) return /[A-z,--.,'-']/; //ภาษาอังกฤษ ตัวพิมพ์เล็กหรือพิมพ์ใหญ่เท่านั้น
    if (["nameEn"].includes(String(type))) return /[A-z,0-9,--.,'-']/; //ภาษาอังกฤษ ตัวพิมพ์เล็กหรือพิมพ์ใหญ่เท่านั้น
    if (["nameTh"].includes(String(type))) return /[ก-์,0-9]/; //ภาษาไทย เท่านั้น **ถ้าจะรวมเอาเลขไทยด้วย ให้ใส่เป็น ก-๙
    if (type === "number") return /[0-9]/; //ตัวเลข 0-9 เท่านั้น
    if (type === "numberWithHyphen") return /[0-9-]/;

    return null;
  }
  const regex = () => {
    if (lang === "th") return regexTh();

    return regexTh();
  }

  if (event.key == null) return console.log("event, type required");
  if (!supportRules.includes(String(type))) return null;
  if (allowedArrowsAndKeys.includes(event.keyCode)) return null;
  if (!regex().exec(event.key)) stopEvent();

  return null;
}

export default keyFilter;
