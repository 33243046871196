import axios from 'axios'
import withResolve from '@cdglib/js-resolve-style'

const getmoveAPI = async (pid) => {
  const getApi = () => axios.get(`/api/v1/move/${pid}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

export { getmoveAPI }