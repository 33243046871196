import axios from 'axios'
import withResolve from '@cdglib/js-resolve-style'

const getHouseAPI = async (path) => {
  const getApi = () => axios.get(`/api/v1/house/${path}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getAADescAPI = async (ccaattmm) => {
  const getApi = () => axios.get(`/api/v1/house/aa/${ccaattmm}0000`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getttAPI = async (path) => {
  const getApi = () => axios.get(`/api/v1/house/tt/${path}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getaaAPI = async (path) => {
  const getApi = () => axios.get(`/api/v1/house/aa/${path}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getccAPI = async (path) => {
  const getApi = () => axios.get(`/api/v1/house/cc/${path}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const gettrokAPI = async (path) => {
  const getApi = () => axios.get(`/api/v1/house/trok/${path}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getsoiAPI = async (path) => {
  const getApi = () => axios.get(`/api/v1/house/soi/${path}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getthanonAPI = async (path) => {
  const getApi = () => axios.get(`/api/v1/house/thanon/${path}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

export { getHouseAPI, getAADescAPI, getttAPI, getaaAPI, getccAPI, gettrokAPI, getsoiAPI, getthanonAPI }
