const occupt = () => {
  return [
    {"code":1,"thaiDescription":"ไม่ระบุอาชีพ","englishDescription":"NO SPECIFY"},
    {"code":2,"thaiDescription":"นักเรียน/นักศึกษา","englishDescription":"STUDENT / PUPIL"},
    {"code":3,"thaiDescription":"ครู/อาจารย์","englishDescription":"TEACHER / PROFESSOR"},
    {"code":4,"thaiDescription":"ข้าราชการ","englishDescription":"GOVERNMENT OFFICER"},
    {"code":5,"thaiDescription":"พนักงานรัฐวิสาหกิจ","englishDescription":"EMPLOYEE"},
    {"code":6,"thaiDescription":"พนักงานบริษัท","englishDescription":"COMPANY EMPLOYEE"},
    {"code":7,"thaiDescription":"ลูกจ้างประจำ","englishDescription":"FULL TIME EMPLOYEE"},
    {"code":8,"thaiDescription":"ลูกจ้างชั่วคราว","englishDescription":"PART TIME EMPLOYEE"},
    {"code":9,"thaiDescription":"รับจ้าง","englishDescription":"GENERAL EMPLOYEE"},
    {"code":10,"thaiDescription":"ธุรกิจ","englishDescription":"BUSINESS"},
    {"code":11,"thaiDescription":"ค้าขาย","englishDescription":"COMMERCE"},
    {"code":12,"thaiDescription":"อาชีพอิสระ","englishDescription":"FREELANCE"},
    {"code":13,"thaiDescription":"ไม่ได้ทำงาน","englishDescription":"UNEMPLOYED"},
    {"code":14,"thaiDescription":"กรรมกร","englishDescription":"CRAFTSMAN"},
    {"code":15,"thaiDescription":"กสิกรรม/เกษตรกรรม","englishDescription":"AGGRICULTURIST"},
    {"code":16,"thaiDescription":"กำนัน","englishDescription":"KAMNAN"},
    {"code":17,"thaiDescription":"ข้าราชการบำนาญ","englishDescription":"RETIRED GOVERNMENT OFFICER"},
    {"code":18,"thaiDescription":"คนขับรถที่ใช้สัตว์ลากเทียม","englishDescription":"DRIVER"},
    {"code":19,"thaiDescription":"คนขับรถรับจ้าง","englishDescription":"TAXI DRIVER"},
    {"code":20,"thaiDescription":"คนขับเรือ","englishDescription":"BOAT DRIVER"},
    {"code":21,"thaiDescription":"คนขายดอกไม้","englishDescription":"FLORIST"},
    {"code":22,"thaiDescription":"คนขายยา","englishDescription":"DRUGGIST"},
    {"code":23,"thaiDescription":"คนทำความสะอาด","englishDescription":"HOUSE-MAID"},
    {"code":24,"thaiDescription":"คนปรุงอาหาร","englishDescription":"COOKER / CHIEF"},
    {"code":25,"thaiDescription":"คนรับจำนำ","englishDescription":"PAWN BROKER"},
    {"code":26,"thaiDescription":"คนรับใช้","englishDescription":"SERVANT"},
    {"code":27,"thaiDescription":"คนเลี้ยงเด็ก","englishDescription":"BABYSITTER"},
    {"code":28,"thaiDescription":"ค้าปลีก","englishDescription":"DISTRIBUTOR"},
    {"code":29,"thaiDescription":"ค้าส่ง","englishDescription":"WHOLESALER"},
    {"code":30,"thaiDescription":"จักษุแพทย์","englishDescription":"OCCULIST"},
    {"code":31,"thaiDescription":"จิตรกร/ช่างเขียน","englishDescription":"ARTIST"},
    {"code":32,"thaiDescription":"เจ้าของร้าน","englishDescription":"SHOPKEEPER"},
    {"code":33,"thaiDescription":"เจ้าหน้าที่รักษาความปลอดภัย","englishDescription":"SECURITY OFFICER"},
    {"code":34,"thaiDescription":"ช่างกล","englishDescription":"MECHANICAL ENGINEER"},
    {"code":35,"thaiDescription":"ช่างกลึง","englishDescription":"TURNER"},
    {"code":36,"thaiDescription":"ช่างก่อสร้าง","englishDescription":"BUILDER"},
    {"code":37,"thaiDescription":"ช่างแก้นาฬิกา","englishDescription":"WATCH REPAIRER"},
    {"code":38,"thaiDescription":"ช่างแกะ","englishDescription":"CARVER"},
    {"code":39,"thaiDescription":"ช่างแกะสลัก","englishDescription":"SCULPTOR"},
    {"code":40,"thaiDescription":"ช่างเครื่อง","englishDescription":"MACHINIST"},
    {"code":41,"thaiDescription":"ช่างเคาะพ่นสีรถยนต์","englishDescription":"CAR'S SERVICEMAN"},
    {"code":42,"thaiDescription":"ช่างเงิน","englishDescription":"SILVERSMITH"},
    {"code":43,"thaiDescription":"ช่างชุบโลหะ","englishDescription":"SERVCICE MAN"},
    {"code":44,"thaiDescription":"ช่างเชื่อมโลหะ","englishDescription":"SERVICE MAN"},
    {"code":45,"thaiDescription":"ช่างซ่อมเครื่องยนต์","englishDescription":"CAR 'S SERVICEMAN"},
    {"code":46,"thaiDescription":"ช่างซ่อมนาฬิกา","englishDescription":"WATCH REPAIRER"},
    {"code":47,"thaiDescription":"ช่างซ่อมวิทยุ โทรทัศน์","englishDescription":"TV & RADIO 'S SERVICEMAN"},
    {"code":48,"thaiDescription":"ช่างต่อเรือ","englishDescription":"BOATBUILDER"},
    {"code":49,"thaiDescription":"ช่างตัดผม","englishDescription":"BARBER",},
    {"code":50,"thaiDescription":"ช่างตัดเย็บเสื้อผ้า","englishDescription":"TAILOR"},
    {"code":51,"thaiDescription":"ช่างตัดเสื้อ","englishDescription":"TAILOR"},
    {"code":52,"thaiDescription":"ช่างถ่ายภาพ","englishDescription":"PHOTOGRAPHER",},
    {"code":53,"thaiDescription":"ช่างทอ ปั่น จักสาน","englishDescription":"CARMENT BUSINESS"},
    {"code":54,"thaiDescription":"ช่างทอง","englishDescription":"GOLDSMITH"},
    {"code":55,"thaiDescription":"ช่างทาสี","englishDescription":"PAINTER"},
    {"code":56,"thaiDescription":"ช่างทำผม","englishDescription":"HAIRDRESSER"},
    {"code":57,"thaiDescription":"ช่างทำรองเท้า","englishDescription":"COBBLER"},
    {"code":58,"thaiDescription":"ช่างเทคนิควิศวกรรม","englishDescription":"ENGENERAL TECHNICIAN"},
    {"code":59,"thaiDescription":"ช่างแท่น","englishDescription":"PRESSMAN",},
    {"code":60,"thaiDescription":"ช่างบัดกรี","englishDescription":"TINSMITH"},
    {"code":61,"thaiDescription":"ช่างปั้น","englishDescription":"SCULPTOR,MODELER"},
    {"code":62,"thaiDescription":"ช่างปูน","englishDescription":"SERVICE MAN"},
    {"code":63,"thaiDescription":"ช่างฝีมือ","englishDescription":"MASTER CRAFTSMAN"},
    {"code":64,"thaiDescription":"ช่างพิมพ์","englishDescription":"PRINTER"},
    {"code":65,"thaiDescription":"ช่างเพชรพลอย","englishDescription":"JEWELSMITH"},
    {"code":66,"thaiDescription":"ช่างฟิต","englishDescription":"MECHANIC"},
    {"code":67,"thaiDescription":"ช่างเฟอร์นิเจอร์","englishDescription":"FURNITURE MAKER"},
    {"code":68,"thaiDescription":"ช่างไฟฟ้า","englishDescription":"ELECTRICIAN"},
    {"code":69,"thaiDescription":"ช่างไม้","englishDescription":"CARPENTER"},
    {"code":70,"thaiDescription":"ช่างเย็บ","englishDescription":"SEAMSTRESS"},
    {"code":71,"thaiDescription":"ช่างเย็บหนัง","englishDescription":"LETHER SAWER"},
    {"code":72,"thaiDescription":"ช่างเรียง","englishDescription":"TYPE COMPOSITOR"},
    {"code":73,"thaiDescription":"ช่างล้างอัดรูป","englishDescription":"PHOTO DEVELOPER"},
    {"code":74,"thaiDescription":"ช่างโลหะ","englishDescription":"SERVICE MAN"},
    {"code":75,"thaiDescription":"ช่างวาดภาพ/ออกแบบ","englishDescription":"DRAWER / DRAFTSMAN"},
    {"code":76,"thaiDescription":"ช่างศิลป์","englishDescription":"ARTIST"},
    {"code":77,"thaiDescription":"ช่างเสริมสวย","englishDescription":"HAIR DRESSMAKER"},
    {"code":78,"thaiDescription":"ช่างแสง","englishDescription":"ARMORER"},
    {"code":79,"thaiDescription":"ช่างหล่อ","englishDescription":"FOUNDER"},
    {"code":80,"thaiDescription":"ช่างหลอมโลหะ","englishDescription":"SERVICE MAN"},
    {"code":81,"thaiDescription":"ช่างเหล็ก","englishDescription":"BLACKSMITH"},
    {"code":82,"thaiDescription":"ช่างอากาศ","englishDescription":"AIRCRAFTSMAN"},
    {"code":83,"thaiDescription":"ช่างอีเลคโทรนิคส์","englishDescription":"ELECTRONIC SERVICEMAN"},
    {"code":84,"thaiDescription":"ตัวแทนจำหน่าย","englishDescription":"AGENT"},
    {"code":85,"thaiDescription":"ตำรวจ","englishDescription":"POLICE"},
    {"code":86,"thaiDescription":"ทนายความ","englishDescription":"ATTORNEY / LAWYER"},
    {"code":87,"thaiDescription":"ทหารบก","englishDescription":"SOLDIER"},
    {"code":88,"thaiDescription":"ทหารเรือ","englishDescription":"SAILOR"},
    {"code":89,"thaiDescription":"ทหารอากาศ","englishDescription":"AIRMAN "},
    {"code":90,"thaiDescription":"ทันตแพทย์","englishDescription":"DENTIST"},
    {"code":91,"thaiDescription":"ทำนา","englishDescription":"RICE FARMER"},
    {"code":92,"thaiDescription":"ทำฟาร์ม","englishDescription":"FARMER"},
    {"code":93,"thaiDescription":"ทำสวน","englishDescription":"GARDENER"},
    {"code":94,"thaiDescription":"นักกฎหมาย","englishDescription":"LAWYER"},
    {"code":95,"thaiDescription":"นักการ","englishDescription":"OFFICE BOY"},
    {"code":96,"thaiDescription":"นักการทูต","englishDescription":"DIPLOMAT"},
    {"code":97,"thaiDescription":"นักการพนัน","englishDescription":"GAMBLER"},
    {"code":98,"thaiDescription":"นักการเมือง","englishDescription":"POLITICIAN"},
    {"code":99,"thaiDescription":"นักกิน","englishDescription":"GOURMET"},
    {"code":100,"thaiDescription":"นักกีฬา","englishDescription":"SPORTSMAN"},
    {"code":101,"thaiDescription":"นักข่าว","englishDescription":"NEWSMAN / REPORTER"},
    {"code":102,"thaiDescription":"นักเขียน","englishDescription":"WRITER"},
    {"code":103,"thaiDescription":"นักเขียนนวนิยาย","englishDescription":"NOVELIST"},
    {"code":104,"thaiDescription":"นักเคมี","englishDescription":"CHEMIST"},
    {"code":105,"thaiDescription":"นักชีววิทยา","englishDescription":"BIOLOGIST"},
    {"code":106,"thaiDescription":"นักดนตรี","englishDescription":"MUSICIAN"},
    {"code":107,"thaiDescription":"นักเต้นรำ","englishDescription":"DANCER"},
    {"code":108,"thaiDescription":"นักแต่งเพลง","englishDescription":"SONGWRITER"},
    {"code":109,"thaiDescription":"นักท่องเที่ยว","englishDescription":"TOURIST"},
    {"code":110,"thaiDescription":"นักเทศน์","englishDescription":"PREACHER"},
    {"code":111,"thaiDescription":"นักธรรม","englishDescription":"THEOLOGIAN"},
    {"code":112,"thaiDescription":"นักนิติศาสตร์","englishDescription":"BARRISTER"},
    {"code":113,"thaiDescription":"นักบวช","englishDescription":"PRIEST"},
    {"code":114,"thaiDescription":"นักบิน","englishDescription":"AVIATOR / PILOT"},
    {"code":115,"thaiDescription":"นักบุญ","englishDescription":"SAINT"},
    {"code":116,"thaiDescription":"นักโบราณคดี","englishDescription":"ARCHEOLOGIST"},
    {"code":117,"thaiDescription":"นักประพันธ์","englishDescription":"AUTHOR"},
    {"code":118,"thaiDescription":"นักประวัติศาสตร์","englishDescription":"HISTORIAN"},
    {"code":119,"thaiDescription":"นักปราชญ์","englishDescription":"MAN OF LEARNING"},
    {"code":120,"thaiDescription":"นักแปล","englishDescription":"TRANSLATOR"},
    {"code":121,"thaiDescription":"นักผจญภัย","englishDescription":"ADVENTURER"},
    {"code":122,"thaiDescription":"นักพรต","englishDescription":"RECLUSE"},
    {"code":123,"thaiDescription":"นักพฤกษศาสตร์","englishDescription":"BOTANIST"},
    {"code":124,"thaiDescription":"นักมวย","englishDescription":"BOXER"},
    {"code":125,"thaiDescription":"นักแม่นปืน","englishDescription":"SHARPSHOOTER"},
    {"code":126,"thaiDescription":"นักรบ","englishDescription":"WARRIOR"},
    {"code":127,"thaiDescription":"นักร้อง","englishDescription":"SINGER"},
    {"code":128,"thaiDescription":"นักวิจัย","englishDescription":"RESEARCHER"},
    {"code":129,"thaiDescription":"นักวิจารณ์","englishDescription":"COMMENTATOR"},
    {"code":130,"thaiDescription":"นักวิทยาศาสตร์","englishDescription":"SCIENTIST"},
    {"code":131,"thaiDescription":"นักสถิติ","englishDescription":"STATISTICIAN"},
    {"code":132,"thaiDescription":"นักสังคมวิทยา","englishDescription":"SOCIAL SCIENCES WORKER"},
    {"code":133,"thaiDescription":"นักสังคมสงเคราะห์","englishDescription":"SOCIAL WORKER"},
    {"code":134,"thaiDescription":"นักสำรวจ","englishDescription":"EXPLORER"},
    {"code":135,"thaiDescription":"นักสืบ","englishDescription":"DETECTIVE"},
    {"code":136,"thaiDescription":"นักแสดงชาย/หญิง","englishDescription":"ACTOR/ACTRESS"},
    {"code":137,"thaiDescription":"นักหนังสือพิมพ์","englishDescription":"JOURNALIST"},
    {"code":138,"thaiDescription":"นางแบบ","englishDescription":"MODEL"},
    {"code":139,"thaiDescription":"นายหน้า","englishDescription":"BROKER"},
    {"code":140,"thaiDescription":"บรรณาธิการ","englishDescription":"EDITOR"},
    {"code":141,"thaiDescription":"ปฎิมากร","englishDescription":"SCULPTOR"},
    {"code":142,"thaiDescription":"ประมง","englishDescription":"FISHERMAN"},
    {"code":143,"thaiDescription":"ผู้ช่วยผู้ใหญ่บ้าน","englishDescription":"ASSISTANT 'S HEADMAN OF A VILLAGE"},
    {"code":144,"thaiDescription":"ผู้ตรวจ","englishDescription":"SUPERVISOR"},
    {"code":145,"thaiDescription":"ผู้ตรวจสอบบัญชี","englishDescription":"AUDITOR"},
    {"code":146,"thaiDescription":"ผู้บริหารรัฐวิสาหกิจ","englishDescription":"MANAGER"},
    {"code":147,"thaiDescription":"ผู้ปฏิบัติงานทางเทคนิคการแพทย์","englishDescription":"MEDICAL TECHNICIAN"},
    {"code":148,"thaiDescription":"ผู้พิพากษา","englishDescription":"JUDGE"},
    {"code":149,"thaiDescription":"ผู้รักษาความปลอดภัย","englishDescription":"GUARD"},
    {"code":150,"thaiDescription":"ผู้รับเหมา","englishDescription":"BUILDING CONTRACTOR"},
    {"code":151,"thaiDescription":"ผู้สอนศาสนา","englishDescription":"PREACHER"},
    {"code":152,"thaiDescription":"ผู้สื่อข่าว","englishDescription":"REPORTER"},
    {"code":153,"thaiDescription":"ผู้ใหญ่บ้าน","englishDescription":"HEADMAN OF A VILLAGE"},
    {"code":154,"thaiDescription":"พนักงานขับรถ","englishDescription":"CHAUFFEUR"},
    {"code":155,"thaiDescription":"พนักงานขับรถเมล์","englishDescription":"BUS DRIVER"},
    {"code":156,"thaiDescription":"พนักงานขาย","englishDescription":"SALEPERSON"},
    {"code":157,"thaiDescription":"พนักงานโฆษณา","englishDescription":"COMMERCIAL BUSINESS"},
    {"code":158,"thaiDescription":"พนักงานต้อนรับ","englishDescription":"RECEPTIONIST"},
    {"code":159,"thaiDescription":"พนักงานทำบัญชี","englishDescription":"BOOK-KEEPER"},
    {"code":160,"thaiDescription":"พนักงานที่ทำงานช่วยเหลือด้านการแพทย์","englishDescription":"MEDICAL ASSISTANT"},
    {"code":161,"thaiDescription":"พนักงานประจำสถานบริการ","englishDescription":"SERVICE PERSON"},
    {"code":162,"thaiDescription":"พนักงานประชาสัมพันธ์","englishDescription":"PUBLIC RELATIONS OFFICER"},
    {"code":163,"thaiDescription":"พนักงานรับจ่ายเงิน","englishDescription":"CASHIER"},
    {"code":164,"thaiDescription":"พนักงานโรงแรม","englishDescription":"HOTEL OFFICER"},
    {"code":165,"thaiDescription":"พนักงานเสริฟ","englishDescription":"WAITOR"},
    {"code":166,"thaiDescription":"พยาบาล","englishDescription":"NURSE"},
    {"code":167,"thaiDescription":"แพทย์","englishDescription":"DOCTOR / PHYSICIAN"},
    {"code":168,"thaiDescription":"ภารโรง","englishDescription":"OFFICE BOY"},
    {"code":169,"thaiDescription":"เภสัชกร","englishDescription":"PHAMACIST"},
    {"code":170,"thaiDescription":"ล่าม","englishDescription":"INTERPRETER"},
    {"code":171,"thaiDescription":"ล่าสัตว์","englishDescription":"ANIMAL HUNTER"},
    {"code":172,"thaiDescription":"เลขานุการ","englishDescription":"SECRETARY"},
    {"code":173,"thaiDescription":"วิศวกร","englishDescription":"ENGINEER"},
    {"code":174,"thaiDescription":"ศัลยแพทย์","englishDescription":"SURGEON"},
    {"code":175,"thaiDescription":"เศรษฐกร","englishDescription":"ECONOMIST"},
    {"code":176,"thaiDescription":"สถาปนิก","englishDescription":"ARCHITECT"},
    {"code":177,"thaiDescription":"สมุห์บัญชี","englishDescription":"ACCOUNTANT"},
    {"code":178,"thaiDescription":"สัตวแพทย์","englishDescription":"VETERINARIAN"},
    {"code":179,"thaiDescription":"สารวัตรกำนัน","englishDescription":"KAMNAN POLICE"},
    {"code":180,"thaiDescription":"เสมียน","englishDescription":"CLERK"},
    {"code":181,"thaiDescription":"หมอดู","englishDescription":"FORTUNE-TELLER"},
    {"code":182,"thaiDescription":"หมอนวด","englishDescription":"MASSEUR"},
    {"code":183,"thaiDescription":"หมอผี","englishDescription":"BLACK MAGICIAN"},
    {"code":184,"thaiDescription":"หมอยา","englishDescription":"PHYSICIAN"},
    {"code":185,"thaiDescription":"หัวหน้าคนงาน","englishDescription":"FOREMAN"},
    {"code":186,"thaiDescription":"หาบเร่","englishDescription":"HAWKER"},
    {"code":187,"thaiDescription":"อนุศาสนาจารย์","englishDescription":"CHAPLAIN"},
    {"code":188,"thaiDescription":"อัยการ","englishDescription":"STATE ATTORNEY"},
  ]
}

export default {
    occupt,
  }