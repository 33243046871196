import Vue from 'vue'
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode'

const convertTextToBase64Barcode = (text) => {
	let canvas = document.createElement('canvas');
	JsBarcode(canvas, text, { format: 'CODE128', width: 3, fontOptions: "bold"});
	return canvas.toDataURL('image/png');
}

const getImage = (url) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.src = url;
    });
  };

const register = async (register, border, qrBar, pid, name, paymentText, paymentText1) => {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext("2d");
    let pic = await getImage(convertTextToBase64Barcode(qrBar))
    let qrurl
    QRCode.toDataURL(qrBar, function (err, url) {
        qrurl = url
    })
    let picqr = await getImage(qrurl)
    canvas.width = 2480 / 1;
    canvas.height = 3508 / 1;
    ctx.strokeRect(0, 0, 2480 / 1, 3508 / 1);
    ctx.font = "bold 100px CordiaUPC";
    ctx.textAlign = "center";
    ctx.fillText(border.Bptext, canvas.width/2, 200);
    ctx.font = "100px CordiaUPC";
    ctx.textAlign = "center";
    ctx.fillText('___________________________________________________________________', canvas.width/2, 260);
    ctx.textAlign = "center";
    ctx.drawImage(picqr, canvas.width/3 + 35, 400, 750, 750);
    ctx.textAlign = "center";
    ctx.font = "bold 100px CordiaUPC";
    ctx.fillText(qrBar, canvas.width/2, 1230);
    ctx.textAlign = "center";
    ctx.drawImage(pic, canvas.width/4, 1350, canvas.width/2, 770);
    ctx.font = "bold 70px CordiaUPC";
    ctx.textAlign = "left";
    ctx.fillText("ข้อมูลลงทะเบียน", 140, 2300);
    ctx.font = "bold 65px CordiaUPC";
    ctx.textAlign = "left";
    ctx.fillText("ชื่อ-สกุล", 140, 2500);
    ctx.font = "65px CordiaUPC";
    ctx.textAlign = "left";
    ctx.fillText(name, 400, 2500);
    ctx.font = "bold 65px CordiaUPC";
    ctx.textAlign = "left";
    ctx.fillText("วันที่รับหนังสือเดินทาง", 140, 2700);
    ctx.font = "65px CordiaUPC";
    ctx.textAlign = "left";
    ctx.fillText('วันที่ ' + Vue.dateFormat(register.Registerdate).toStringType1ThaiFull(), 800, 2700);
    ctx.font = "bold 65px CordiaUPC";
    ctx.textAlign = "left";
    ctx.fillText("สนง.ออกหนังสือผ่านแดน", 140, 2900);
    ctx.font = "65px CordiaUPC";
    ctx.textAlign = "left";
    ctx.fillText(border.Border, 900, 2900);
    ctx.font = "bold 68px CordiaUPC";
    ctx.textAlign = "center";
    ctx.fillText(`***เตรียมค่าใช้จ่ายสำหรับออกหนังสือผ่านแดน จำนวน ${border.Cost} บาท***`, canvas.width/2, 3150);
    if(paymentText){
        ctx.font = "bold 65x CordiaUPC";
        ctx.textAlign = "center";
        ctx.fillText(paymentText, canvas.width/2, 3250);
    }
    if(paymentText1){
        ctx.font = "bold 65px CordiaUPC";
        ctx.textAlign = "center";
        ctx.fillText(paymentText1, canvas.width/2, 3350);
    }
    ctx.font = "bold 65px CordiaUPC";
    ctx.textAlign = "center";
    ctx.fillText("***รับหนังสือผ่านเเดนในวันที่กำหนดเท่านั้น***", canvas.width/2, paymentText != "" ? 3450 : 3250);
    ctx.fill();
    let image = canvas.toDataURL("image/png", 1.0)
    var link = document.createElement('a');
    link.download = pid;
    link.href = image;
    link.click();

    /* const layout = {
        content: [
            {
                text: border.Bptext,
                alignment: 'center',
                bold: true,
                fontSize: 20,
            },
            {
                text:
                    "_________________________________________________________________________________________________________________________________________",
                absolutePosition: { x: 30, y: 40 }
            },
            { 
                qr: qrBar,
                // qr: '00000012564',
                fit: '170',
                absolutePosition: { x: 215, y: 100 },
            },
            {
                text: qrBar,
                // text: '00000012564',
                fontSize: 25,
                absolutePosition: { x: 250, y: 280 },
                bold: true,
            },
            {
                image: convertTextToBase64Barcode(qrBar),
                absolutePosition: { x: 125, y: 350 },
            },
            {
                text: 'ข้อมูลลงทะเบียน',
                fontSize: 16,
                absolutePosition: { x: 40, y: 530 },
                bold: true,
            },
            {
                text: 'ชื่อ-สกุล ',
                fontSize: 16,
                bold: true,
                absolutePosition: { x: 40, y: 570 }
            },
            {
                text: register.Fullname,
                fontSize: 16,
                absolutePosition: { x: 90, y: 569 }
            },
            {
                text: 'วันที่รับหนังสือเดินทาง',
                fontSize: 16,
                bold: true,
                absolutePosition: { x: 40, y: 610 }
            },
            {
                text: 'วันที่ ' + Vue.dateFormat(register.Registerdate).toStringType1ThaiFull(),
                fontSize: 16,
                absolutePosition: { x: 160, y: 609 }
            },
            {
                text: 'สำนักงานหนังออกสือผ่านเเดน ',
                fontSize: 16,
                bold: true,
                absolutePosition: { x: 40, y: 650 }
            },
            {
                text: border.Border,
                fontSize: 16,
                absolutePosition: { x: 200, y: 649 }
            },
            {
                text: `เตรียมเงินสำหรับชำระค่าธรรมเนียมที่ด่านออกหนังสือผ่านเเดน จำนวน ${border.Cost} บาท`,
                fontSize: 16,
                absolutePosition: { x: 40, y: 750 },
                alignment: 'center',
                bold: true
            },
            {
                text: `***รับหนังสือผ่านเเดนในวันที่กำหนดเท่านั้น***`,
                fontSize: 16,
                absolutePosition: { x: 40, y: 790 },
                alignment: 'center',
                bold: true
            },
          ]
    } */
    /*Vue.printDoc(await Vue.pdfMake.createPdfToDataUrl(layout, {
        margin: [15, 15, 15, 15],
    }))*/
    // Vue.pdfMake.createPdf(layout).download()
}

export default register