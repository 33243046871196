import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'
import follower from './modules/follower'
import followerlist from './modules/followerlist'
import register from './modules/register'
import data from './modules/data'
import pop from './modules/pop'
import page from './modules/page'
import smcard from './modules/smcard'
import birth from './modules/birth'
import savedata from './modules/savedata'
import verify from './modules/verify'
import login from './modules/login'
import monk from './modules/monk'
import holiday from './modules/holiday'
import ispay from './modules/ispay'

pathify.options.mapping = 'simple'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    pathify.plugin
  ],
  modules: {
    follower,
    followerlist,
    pop,
    page,
    data,
    register,
    smcard,
    birth,
    savedata,
    verify,
    login,
    monk,
    holiday,
    ispay
  }
})
