import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTheMask from 'vue-the-mask'
import axios from 'axios'
import VueAxios from 'vue-axios'
import format from '@cdglib/js-format/vue-plugin'
import pdfmakeCustom from '@cdglib/js-pdfmake-custom'
import calculatorAge from '@cdglib/js-calculator-age/vue-plugin'
import 'remixicon/fonts/remixicon.css'

Vue.use(pdfmakeCustom)
Vue.use(VueTheMask)
Vue.use(format)
Vue.use(calculatorAge)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
