<template>
  <div class="dbt-content">
    <v-row>
      <v-col md="10" sm="12" offset-md="1">
        <v-row>
          <v-col sm="12">
            <v-row>
              <v-col v-if="flagPage && keepDetail.status !== 2 && !keepDetail.flagExpire" cols="12">
                <v-stepper v-model="e1" style="font-weight: bold;" alt-labels vertical>
                  <v-stepper-header>
                    <v-stepper-step step="1" color="green">รับเรื่องแล้ว</v-stepper-step>
                    <v-divider color="green"></v-divider>
                    <v-stepper-step step="2" color="green">เข้ารับบริการ</v-stepper-step>
                    <v-divider color="green"></v-divider>
                    <v-stepper-step step="3" color="green">รับหนังสือ</v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card elevation="2" class="dbt-content-card" v-if="keepDetail.status === 0" outlined>
                        <v-card-title class="primary white--text" subheading v-if="!showCode">
                          ข้อมูลลงทะเบียน
                        </v-card-title>
                          <v-tabs
                            v-model="tab"
                            background-color="#1F4680"
                            centered
                            dark
                            icons-and-text
                            show-arrows
                            v-if="showCode"
                          >
                            <v-tabs-slider></v-tabs-slider>
                              <v-tab href="#tab-1">
                                QRCode
                                <v-icon>ri-qr-code-line</v-icon>
                              </v-tab>
                              <v-tab href="#tab-2">
                                Barcode
                              <v-icon>ri-barcode-line</v-icon>
                              </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab" v-if="showCode">
                              <v-tab-item :value="`tab-1`">
                                <v-card flat>
                                  <v-card-text>
                                    <center>
                                      <VueQrcode
                                        :value="qrBar"
                                        v-bind:scale="qrScale"
                                      />
                                    </center>
                                  <div class="dbt-label" style="text-align: center; color: black">{{ qrBar }}</div>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                            <v-tab-item :value="`tab-2`">
                              <v-card flat>
                                <v-card-text>
                                  <center>
                                    <VueBarcode :value="qrBar">
                                      Show this if the rendering fails.
                                    </VueBarcode>
                                  </center>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs-items>
                        <div class="context">
                        <div class="dbt-label" v-if="showCode">ข้อมูลลงทะเบียน</div>
                          <v-row>
                            <v-col cols="12">
                              <v-row>
                                <v-flex
                                  md="12"
                                  sm="12"
                                  style="padding: 30px 10px 0px 10px"
                                >
                                  <div class="dbt-label"><v-icon class="pr-1" style="color: #1F4680;">mdi-account-box</v-icon>{{ flagPage ? `${monkname}` :  `${fullname}` }}</div>
                                </v-flex>
                              </v-row>
                              <v-row>
                                <v-flex
                                  md="12"
                                  sm="12"
                                  style="padding: 20px 10px 0px 10px"
                                >
                                  <div class="dbt-label"><v-icon class="pr-1" style="color: #1F4680;">mdi-calendar-month</v-icon>{{ flagPage ? fulldate(keepDetail.registerDate) : fulldate(register.Registerdate) }}</div>
                                </v-flex>
                              </v-row>
                              <v-row>
                                <v-flex
                                  md="12"
                                  sm="12"
                                  style="padding: 20px 10px 0px 10px"
                                >
                                  <div class="dbt-label"><v-icon class="pr-1" style="color: #1F4680;">mdi-map-marker-radius</v-icon>{{ flagPage ? keepDetail.station : border.Border }}</div>
                                </v-flex>
                              </v-row>
                              <v-row>
                                <v-flex
                                  md="12"
                                  sm="12"
                                  style="text-align: center; padding: 20px 0px 0px 0px"
                                  pb-1
                                  v-if="(flagPage && keepDetail.status !== 2 && !keepDetail.flagExpire) || register"
                                >
                                  <div class="dbt-label" style="color: red">{{ `เตรียมค่าใช้จ่ายสำหรับออกหนังสือผ่านแดน จำนวน ${flagPage ? priceBook : border.Cost} บาท` }}</div> 
                                  <div class="dbt-label" style="color: red">
                                    {{ `${paymentText}` }}
                                  </div>
                                  <div class="dbt-label" style="color: red">{{ `${paymentText1}` }}</div>
                                </v-flex>
                              </v-row>
                              <v-row>
                                <v-flex
                                  md="12"
                                  sm="12"
                                  style="text-align: center; padding: 5px 0px 0px 0px"
                                  pb-2
                                  v-if="(flagPage && keepDetail.status !== 2 && !keepDetail.flagExpire) || register"
                                >
                                  <div style="color: red;">{{ `***รับหนังสือผ่านแดนในวันที่กำหนดเท่านั้น***` }}</div> 
                                </v-flex>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                        <v-card-actions class="justify-end">
                          <v-container>
                            <v-row dense>
                              <v-col></v-col>
                              <v-col></v-col>
                              <v-col><v-btn large v-if="register || (flagPage && keepDetail.status === 0)" class="btn dbt-btn-blue" style="width: inherit;" @click="print"> <v-icon class="mr-1">mdi-download</v-icon>ดาวน์โหลด</v-btn></v-col>
                              <v-col><v-btn large v-if="flagPage && keepDetail.status === 0" class="error" style="width: inherit; font-weight:bold;" @click.prevent="yesNoSweet('warning', 'ต้องการยกเลิกรายการนี้หรือไม่?')"><v-icon left> mdi-table-cancel</v-icon>ยกเลิก</v-btn></v-col>
                              <v-col><v-btn large v-if="flagPage" class="btn primary--text" style="width: inherit;" @click="$router.push({ name: 'Verify', params: { returnDetail: keepDetail, flagBack: true } }).catch(() => {})"><i class="ri-arrow-left-line"></i>กลับ</v-btn></v-col>
                            </v-row>
                          </v-container>
                        </v-card-actions>
                      </v-card>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <v-card elevation="2" class="dbt-content-card" v-if="keepDetail.status === 1" outlined>
                        <v-card-title class="primary white--text" subheading v-if="!showCode">
                          ข้อมูลลงทะเบียน
                        </v-card-title>
                          <v-tabs
                            v-model="tab"
                            background-color="#1F4680"
                            centered
                            dark
                            icons-and-text
                            show-arrows
                            v-if="showCode"
                          >
                            <v-tabs-slider></v-tabs-slider>
                              <v-tab href="#tab-1">
                                QRCode
                                <v-icon>ri-qr-code-line</v-icon>
                              </v-tab>
                              <v-tab href="#tab-2">
                                Barcode
                              <v-icon>ri-barcode-line</v-icon>
                              </v-tab>
                            </v-tabs>
                            <v-tabs-items v-model="tab" v-if="showCode">
                            <v-tab-item :value="`tab-1`">
                              <v-card flat>
                                <v-card-text>
                                  <center>
                                    <VueQrcode
                                      :value="qrBar"
                                      v-bind:scale="qrScale"
                                    />
                                  </center>
                                  <div class="dbt-label" style="text-align: center; color: black">{{ qrBar }}</div>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                            <v-tab-item :value="`tab-2`">
                              <v-card flat>
                                <v-card-text>
                                  <center>
                                    <VueBarcode :value="qrBar">
                                      Show this if the rendering fails.
                                    </VueBarcode>
                                  </center>
                                </v-card-text>
                              </v-card>
                            </v-tab-item>
                          </v-tabs-items>
                        <div class="context">
                        <div class="dbt-label" v-if="showCode">ข้อมูลลงทะเบียน</div>
                          <v-row>
                            <v-col cols="12">
                              <v-row>
                                <v-flex
                                  md="12"
                                  sm="12"
                                  style="padding: 30px 10px 0px 10px"
                                >
                                  <div class="dbt-label"><v-icon class="pr-1" style="color: #1F4680;">mdi-account-box</v-icon>{{ flagPage ? `${monkname}` :  `${fullname}` }}</div>
                                </v-flex>
                              </v-row>
                              <v-row>
                                <v-flex
                                  md="12"
                                  sm="12"
                                  style="padding: 20px 10px 0px 10px"
                                >
                                  <div class="dbt-label"><v-icon class="pr-1" style="color: #1F4680;">mdi-calendar-month</v-icon>{{ flagPage ? fulldate(keepDetail.registerDate) : fulldate(register.Registerdate) }}</div>
                                </v-flex>
                              </v-row>
                              <v-row>
                                <v-flex
                                  md="12"
                                  sm="12"
                                  style="padding: 20px 10px 0px 10px"
                                >
                                  <div class="dbt-label"><v-icon class="pr-1" style="color: #1F4680;">mdi-map-marker-radius</v-icon>{{ flagPage ? keepDetail.station : border.Border }}</div>
                                </v-flex>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                        <v-card-actions>
                          <v-container>
                            <v-row dense>
                              <v-col></v-col>
                              <v-col></v-col>
                              <v-col></v-col>
                              <v-col></v-col>
                              <v-col><v-btn large v-if="flagPage" class="btn dbt-btn-default primary--text" style="width: inherit;" @click="$router.push({ name: 'Verify', params: { returnDetail: keepDetail, flagBack: true } }).catch(() => {})"><i class="ri-arrow-left-line"></i>กลับ</v-btn></v-col>
                            </v-row>
                          </v-container>
                        </v-card-actions>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
              <v-col v-else sm="12">
                <v-card elevation="2" class="dbt-content-card">
                  <v-card-title class="primary white--text" subheading v-if="!showCode">
                    ข้อมูลลงทะเบียน
                  </v-card-title>
                    <v-tabs
                      v-model="tab"
                      background-color="#1F4680"
                      centered
                      dark
                      icons-and-text
                      show-arrows
                      v-if="showCode"
                    >
                      <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#tab-1">
                          QRCode
                          <v-icon>ri-qr-code-line</v-icon>
                        </v-tab>
                        <v-tab href="#tab-2">
                          Barcode
                        <v-icon>ri-barcode-line</v-icon>
                        </v-tab>
                      </v-tabs>
                      <v-tabs-items v-model="tab" v-if="showCode">
                        <v-tab-item :value="`tab-1`">
                          <v-card flat>
                            <v-card-text>
                              <center>
                                <VueQrcode
                                  :value="qrBar"
                                  v-bind:scale="qrScale"
                                />
                              </center>
                            <div class="dbt-label" style="text-align: center; color: black">{{ qrBar }}</div>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item :value="`tab-2`">
                        <v-card flat>
                          <v-card-text>
                            <center>
                              <VueBarcode :value="qrBar">
                                Show this if the rendering fails.
                              </VueBarcode>
                            </center>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  <div class="context">
                  <div class="dbt-label" v-if="showCode">ข้อมูลลงทะเบียน</div>
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-flex
                            md="12"
                            sm="12"
                            style="padding: 30px 10px 0px 10px"
                          >
                            <div class="dbt-label"><v-icon class="pr-1" style="color: #1F4680;">mdi-account-box</v-icon>{{ flagPage ? `${monkname}` : `${fullname}` }}</div>
                          </v-flex>
                        </v-row>
                        <v-row>
                          <v-flex
                            md="12"
                            sm="12"
                            style="padding: 20px 10px 0px 10px"
                          >
                            <div class="dbt-label"><v-icon class="pr-1" style="color: #1F4680;">mdi-calendar-month</v-icon>{{ flagPage ? fulldate(keepDetail.registerDate) : fulldate(register.Registerdate) }}</div>
                          </v-flex>
                        </v-row>
                        <v-row>
                          <v-flex
                            md="12"
                            sm="12"
                            style="padding: 20px 10px 0px 10px"
                          >
                            <div class="dbt-label"><v-icon class="pr-1" style="color: #1F4680;">mdi-map-marker-radius</v-icon>{{ flagPage ? keepDetail.station : border.Border }}</div>
                          </v-flex>
                        </v-row>
                        <v-row>
                          <v-flex
                            md="12"
                            sm="12"
                            style="text-align: center; padding: 20px 0px 0px 0px"
                            pb-1
                            v-if="(flagPage && keepDetail.status !== 2 && !keepDetail.flagExpire) || register"
                          >
                            <div class="dbt-label" style="color: red" >{{ `เตรียมค่าใช้จ่ายสำหรับออกหนังสือผ่านแดน จำนวน ${flagPage ? priceBook : border.Cost} บาท` }}</div>
                            <div class="dbt-label" style="color: red">{{ `${paymentText}` }}</div>
                            <div class="dbt-label" style="color: red">{{ `${paymentText1}` }}</div>
                          </v-flex>
                        </v-row>
                        <v-row>
                          <v-flex
                            md="12"
                            sm="12"
                            style="text-align: center; padding: 5px 0px 0px 0px"
                            pb-2
                            v-if="(register && flagPage === false)"
                          >
                            <div style="color: red;">{{ `***รับหนังสือผ่านแดนในวันที่กำหนดเท่านั้น***` }}</div> 
                          </v-flex>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <v-card-actions class="justify-end">
                    <v-container>
                      <v-row dense>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col></v-col>
                        <v-col v-if="flagPage"><v-btn large class="btn dbt-btn-default primary--text" style="width: inherit;" @click="$router.push({ name: 'Verify', params: { returnDetail: keepDetail, flagBack: true } }).catch(() => {})"><i class="ri-arrow-left-line"></i>กลับ</v-btn></v-col>
                        <v-col v-else-if="(register && flagPage === false)"><v-btn large class="btn dbt-btn-blue" style="width: inherit;" @click="print"> <v-icon class="mr-1">mdi-download</v-icon>ดาวน์โหลด</v-btn></v-col>
                      </v-row>
                    </v-container>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="cancelDialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline orange lighten-2 white--text"
          primary-title
        >
          แจ้งเตือน
        </v-card-title>
        <v-card-text>
          <v-layout justify-center align-center pt-4>
            <label style="font-weight: bold">ต้องการยกเลิกรายการนี้หรือไม่?</label>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="cancelDialog = false"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            color="success"
            text
            @click="cancelReq"
          >
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Dialog :alertDialog="alertDialog" :waitDialog="waitDialog" />
  </div>
</template>
<script>
/* eslint-disable */
import VueQrcode from "vue-qrcode";
import VueBarcode from "vue-barcode";
import register from '@/components/doc/register.js'
import  billpayment  from "@/js/billpayment.js";
import { get } from 'vuex-pathify'
import { cancelRegister } from '@/api/cancel'
import { getRegisterByReq, getregisterAPI } from '@/api/register'
import Dialog from '@/components/ui/dialog/Dialog'
import payslip from '@/components/doc/payslip.js'
import Swal from 'sweetalert2'

export default {
  name: "qrcode",
  props: {
    dataDetail: {
      type: Object,
      default: () => { return null },
      required: true,
    },
    flagPage: {
      type: Boolean,
      default: () => { return false },
      required: true,
    },
  },
  components: {
    VueQrcode,
    VueBarcode,
    Dialog,
  },
  computed: {
    border: get('data/data'),
    register: get('register/register'),
    savedata: get('savedata/savedata'),
    ispay: get('ispay/ispay'),
  },
  data() {
    return {
      tab: null,
      qrScale: 6,
      status: 1,
      mrz1: '00000012564',
      qrBar: '',
      cancelDialog: false,
      keepDetail: null,
      reqId: null,
      reqYear: null,
      alertDialog: {
        val: false,
        msg: '',
        type: '',
      },
      waitDialog: false,
      showCode: true,
      priceBook: '',
      monkname: null,
      fullname: null,
      e1: null,
      datapayslip: null,
      billdata: null,
      phone: null,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      paymentText: '',
      paymentText1: ''
    }
  },
  watch: {
    dataDetail: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val) return
        if (val !== 1) {
          this.keepDetail = val
          console.log('keepDetail = ', this.keepDetail)
          if (this.keepDetail.status === 2 || this.keepDetail.flagExpire) {
            console.log('if')
            this.checkprha()
            this.showCode = false
            this.qrBar = val.qrBar
          } else {
            console.log('else')
            this.setqr()
            this.keepDetail.cost = this.keepDetail.bpType === 1 ? 30 : 200
            this.priceBook = this.keepDetail.cost
          }
        } else {
          this.setqr()
        }
      }
    }
  },
  methods: {
    compare(a, b) {
      if (
        Number(`${a.reqYear}${a.reqId.toString().padStart(7, "0")}`) <
        Number(`${b.reqYear}${b.reqId.toString().padStart(7, "0")}`)
      ) {
        return 1;
      }
      if (
        Number(`${a.reqYear}${a.reqId.toString().padStart(7, "0")}`) >
        Number(`${b.reqYear}${b.reqId.toString().padStart(7, "0")}`)
      ) {
        return -1;
      }
      return 0;
    },
    async setqr() {
      this.waitDialog = true
      this.today = this.formatDate(this.picker)
      let Tomorrow = this.checkTomorrow(this.today)
      let data = null
      this.paymentText = ''
      this.paymentText1 = ''
      this.checkprha()
      if (this.flagPage) {
        this.e1 = this.keepDetail.status === 0 ? '1' : '3'
      }
      if (this.flagPage) data = await getregisterAPI(this.keepDetail.pid, this.keepDetail.ccaa, this.keepDetail.borderCode , this.keepDetail.bpType , this.keepDetail.borderCity)
      else data = await getregisterAPI(this.savedata.pid, this.border.ccaa, this.border.Bordercode , this.border.Bptype , this.border.Bordercity)
      if (data.result) {
        const regist = data.result.sort(this.compare);
        let bill = billpayment.filterpayment(regist[0].ccaa.toString().substring(0, 2), regist[0].borderCity)
        this.billdata = bill[0]
        this.datapayslip = regist[0]
        this.qrBar = `${regist[0].reqId.toString().padStart(7, '0')}${regist[0].reqYear}`
        this.reqId = `${regist[0].reqId.toString().padStart(6, '0')}`
        this.reqYear =  `${regist[0].reqYear}`
        this.showCode = true
      }
      // if (this.flagPage === false) {
        const ccaa = this.flagPage ? this.keepDetail.ccaa.toString() : this.border.ccaa.toString()
        console.log('ccaa = ', ccaa)
        if (['2700', '3400', '3425', '4301', '4801', '4901', '5709', '6306', '8501', '9000', '9010', '9100', '9101', '9401', '9600', '9610'].includes(ccaa)) {
          if (this.ispay) {
            this.paymentText ='โดยชำระเงินผ่านระบบ e-Payment ภายในเวลา 18.00 น.',
            this.paymentText1 = 'ของวันก่อนวันนัดรับหนังสือทั้งนี้ สามารถชำระผ่านเงินสด/Scan QR Code ในวันนัดรับหนังสือได้'
          }
        }
        console.log('paymentText = ', this.paymentText)
        console.log('paymentText 1 = ', this.paymentText1)
      // }


      this.waitDialog = false
    },
    async print() {
      if (this.flagPage) {
        const detail = {
          Fullname: `${this.monkname}`,
          Registerdate: this.keepDetail.registerDate,
        }
        const border = {
          Border: this.keepDetail.station,
          Cost: this.keepDetail.cost,
          Bptext: this.keepDetail.bpType === 1 ? 'หนังสือผ่านเเดนชั่วคราว' : 'หนังสือผ่านเเดน(เเบบเล่ม)'
        }
        const paydata = {
          ccaa: this.keepDetail.ccaa.toString(),
          Bordercode: this.keepDetail.borderCode.toString(),
          lrcode: this.datapayslip.lrcode,
          compcode: this.billdata.compcode,
          taxid: this.billdata.taxid,
          Cost: this.keepDetail.cost,
          Bptype: this.keepDetail.bpType,
          ccc: this.billdata.ccc,
          name: this.billdata.name
        }
        const Datedata = {
          Traveldate: this.datapayslip.travelDate,
          Registerdate: this.datapayslip.registerDate
        }
        if (['2700', '3400', '3425', '4301', '4801', '4901', '5709', '6306', '8501', '9000', '9010', '9100', '9101', '9401', '9600', '9610'].includes(this.keepDetail.ccaa.toString())) {
          await payslip(paydata, this.monkname, this.reqId, this.reqYear, Datedata, this.keepDetail.phone)
        }
          await register(detail, border, this.qrBar, this.keepDetail.pid, this.monkname, this.paymentText, this.paymentText1)
        } else {
          this.today = this.formatDate(this.picker)
          let Tomorrow = this.checkTomorrow(this.today)
          if (['2700', '3400', '3425', '4301', '4801', '4901', '5709', '6306', '8501', '9000', '9010', '9100', '9101', '9401', '9600', '9610'].includes(this.border.ccaa.toString())) {
            if ([Number(this.today), Tomorrow].includes(this.savedata.travelDate) && this.ispay === true) {
              await payslip(this.border, this.fullname, this.reqId, this.reqYear, this.register, this.register.Tel)
            }
          }
          await register(this.register, this.border, this.qrBar, this.savedata.pid, this.fullname, this.paymentText, this.paymentText1)
        }
    },
    checkTomorrow(date) {
      let Ddate = date.toString()
      let day = Ddate.substring(8, 6)
      let mounth = Ddate.substring(6, 4)
      let year = Ddate.substring(4, 0)
      const new_date = new Date(year, mounth, day);
      new_date.setDate(new_date.getDate() + 1); 
      return Number(`${new_date.getFullYear()}${new_date.getMonth().toString().padStart(2, '0')}${new_date.getDate().toString().padStart(2, '0')}`)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      let thaiyear = Number(year) + 543
      return `${thaiyear}${month}${day}`
    },

    checkprha() {
      if (this.flagPage) {
        if (this.keepDetail.titleCode === 999) {
          this.monkname = this.keepDetail.title === 'พระ' ? `พระ${this.keepDetail.fname} ${this.keepDetail.lname}` : `${this.keepDetail.title}(${this.keepDetail.fname} ${this.keepDetail.lname})`
        } else if (this.keepDetail.titleCode == 832 && this.keepDetail.titleSex == 1) {
          this.monkname = `สามเณร${this.keepDetail.fname} ${this.keepDetail.lname}`;
        } else {
          this.monkname = `${this.keepDetail.title} ${this.keepDetail.fname} ${this.keepDetail.lname}`
        }
      } else {
        if (this.savedata.titleCode === 999) {
          this.fullname = this.savedata.title === 'พระ' ? `พระ${this.savedata.fname} ${this.savedata.lname}` : `${this.savedata.title}(${this.savedata.fname} ${this.savedata.lname})`
        } else if (this.savedata.titleCode == 832 && this.savedata.titleSex == 1) {
          this.fullname = `สามเณร${this.savedata.fname} ${this.savedata.lname}`;
        } else {
          this.fullname = `${this.savedata.title} ${this.savedata.fname} ${this.savedata.lname}`
        }
      }
    },
    backToVerify() {
      this.$router.push({ name: 'Verify' }).catch(() => {})
    },
    fulldate(day) {
      return this.dateFormat(day).toStringType1ThaiFull()
    },
    callDialog(type = undefined, msg = undefined) {
      this.waitDialog = false
      this.alertDialog.msg = msg
      this.alertDialog.type = `headline white--text ${type} lighten-1`
      this.alertDialog.val = true
    },
    async yesNoSweet(type, msg) {
      return Swal.fire({
        icon: type,
        title: 'แจ้งเตือน',
        text: msg,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then((result) => {
        if (result.isConfirmed) this.cancelReq()
      })
    },
    sweetAlert(type, msg) {
      this.waitDialog = false
      return Swal.fire({
        icon: type,
        title: 'แจ้งเตือน',
        allowOutsideClick: false,
        text: msg,
        confirmButtonText: 'ตกลง',
      })
    },
    async cancelReq() {
      try {
        this.waitDialog = true
        this.cancelDialog = false
        const cancelReg = await cancelRegister(`${this.keepDetail.reqId}/${this.keepDetail.reqYear}`)
        if (cancelReg.error) return this.sweetAlert('warning', cancelReg.error)
        const temp = {
          station: this.keepDetail.station,
          qrBar: this.keepDetail.qrBar,
        }
        const newData = await getRegisterByReq(`${this.keepDetail.reqId}/${this.keepDetail.reqYear}`)
        if (newData.error) return this.sweetAlert('warning', newData.error)
        this.keepDetail = Object.assign({}, newData.result, temp)
        this.showCode = false
        this.waitDialog = false
        this.sweetAlert('success', 'ยกเลิกรายการเรียบร้อย')
      } catch (err) {
        return this.sweetAlert('error', err.message)
      }
    }
  }
}
</script>
<style scoped src="@/assets/styles/dbt-style.css"></style>
