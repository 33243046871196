import axios from 'axios'
import withResolve from '@cdglib/js-resolve-style'

const getsmcardAPI = async (pid) => {
  const getApi = () => axios.get(`/api/v1/smcard/${pid}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

export { getsmcardAPI }