import axios from 'axios'
import withResolve from '@cdglib/js-resolve-style'

const getLogin = async () => {
  const getApi = () => axios.get('/api/v1/auth/info/')
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, data: result.data }
}

const postLogin = async () => {
  const postApi = () => axios.post('/api/v1/auth/info/')
  const [error, result] = await withResolve(postApi)()
  if (error) return { error }
  return { error, data: result.data }
}

const postLogin5Feild = async (payload) => {
  const postApi = () => axios.post('/api/v1/auth/bps/login5field/',payload)
  const [error, result] = await withResolve(postApi)()
  if (error) return { error }
  return { error, data: result.data }
}

const delLogin5Feild = async (payload) => {
  const delApi = () => axios.delete('/api/v1/auth/bps/logout5field/',payload)
  const [error, result] = await withResolve(delApi)()
  if (error) return { error }
  return { error, data: result.data }
}

export {
    getLogin,
    postLogin,
    postLogin5Feild,
    delLogin5Feild
}
