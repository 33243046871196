const Myanmar = () => {
    return [
      {
        text: 'อ.แม่สาย',
        value: 570906101,
      },
      {
        text: 'อ.แม่สอด',
        value: 630601001,
      },
      {
        text: 'อ.แม่สอด(สะพานมิตรภาพไทย-เมียนมา2)',
        value: 630606072,
      },
      {
        text: 'อ.เมืองกาญจนบุรี(บ้านพุน้ำร้อน)',
        value: 710113121,
      },
      {
        text: 'อ.เมืองประจวบคีรีขันธ์(ด่านสิงขร)',
        value: 770103061,
      },
      {
        text: 'อ.เมืองระนอง',
        value: 850101002,
      },
      {
        text: 'อ.เมืองระนอง(ศูนย์บริการประชาชน)',
        value: 850104013,
      },
    ]
  }

  const Malaysia = () => {
    return [
      {
        value: 900001001,
        text: 'ที่ทำการปกครองจังหวัดสงขลา',
      },
      {
        value: 900302021,
        text: 'ที่ว่าการอำเภอจะนะ',
      },
      {
        value: 900401011,
        text: 'ที่ว่าการอำเภอนาทวี',
      },
      {
        value: 900501011,
        text: 'ที่ว่าการอำเภอเทพา',
      },
      {
        value: 900601011,
        text: 'ที่ว่าการอำเภอสะบ้าย้อย',
      },
      {
        value: 901001011,
        text: 'อ.สะเดา',
      },
      {
        value: 910001001,
        text: 'ที่ทำการปกครองจังหวัดสตูล',
      },
      {
        value: 910102021,
        text: 'ที่ว่าการอำเภอเมืองสตูล',
      },
      {
        value: 910201011,
        text: 'ที่ว่าการอำเภอควนโดน',
      },
      {
        value: 910302101,
        text: 'ที่ว่าการอำเภอควนกาหลง',
      },
      {
        value: 910401021,
        text: 'ที่ว่าการอำเภอท่าแพ',
      },
      {
        value: 910502171,
        text: 'ที่ว่าการอำเภอละงู',
      },
      {
        value: 910601081,
        text: 'ที่ว่าการอำเภอทุ่งหว้า',
      },
      {
        value: 910701011,
        text: 'ที่ว่าการอำเภอมะนัง',
      },
      {
        value: 940101001,
        text: 'ที่ว่าการอำเภอเมืองปัตตานี',
      },
      {
        value: 950101011,
        text: 'ที่ว่าการอำเภอเมืองยะลา',
      },
      {
        value: 950201011,
        text: 'อำเภอเบตง',
      },
      {
        value: 950302021,
        text: 'อ.บันนังสตา',
      },
      {
        value: 950401011,
        text: 'ที่ว่าการอำเภอธารโต',
      },
      {
        value: 950501001,
        text: 'ที่ว่าการอำเภอยะหา',
      },
      {
        value: 950601011,
        text: 'ที่ว่าการอำเภอรามัน',
      },
      {
        value: 950701051,
        text: 'ที่ว่าการอำเภอกาบัง',
      },
      {
        value: 950803031,
        text: 'ที่ว่าการอำเภอกรงปินัง',
      },
      {
        value: 960007001,
        text: 'ที่ทำการปกครองจังหวัดนราธิวาส',
      },
      {
        value: 960101001,
        text: 'ที่ว่าการอำเภอเมืองนราธิวาส',
      },
      {
        value: 960201001,
        text: 'อ.ตากใบ',
      },
      {
        value: 960301021,
        text: 'ที่ว่าการอำเภอบาเจาะ',
      },
      {
        value: 960401011,
        text: 'ที่ว่าการอำเภอยี่งอ',
      },
      {
        value: 960501001,
        text: 'ที่ว่าการอำเภอระแงะ',
      },
      {
        value: 960607011,
        text: 'ที่ว่าการอำเภอรือเสาะ',
      },
      {
        value: 960701011,
        text: 'ที่ว่าการอำเภอศรีสาคร',
      },
      {
        value: 960804021,
        text: 'ที่ว่าการอำเภอแว้ง',
      },
      {
        value: 960904041,
        text: 'ที่ว่าการอำเภอสุคิริน',
      },
      {
        value: 961001051,
        text: 'อ.สุไหงโก-ลก',
      },
      {
        value: 961101011,
        text: 'ที่ว่าการอำเภอสุไหงปาดี',
      },
      {
        value: 961203031,
        text: 'ที่ว่าการอำเภอจะแนะ',
      },
      {
        value: 961301011,
        text: 'ที่ว่าการอำเภอเจาะไอร้อง',
      },
    ]
  }

  const Laos = () => {
    return [
      {
        value: 340001001,
        text: 'ที่ทำการปกครองจังหวัดอุบลราชธานี',
      },
      {
        value: 342502131,
        text: 'อ.สิรินธร(ช่องเม็ก)',
      },
      {
        value: 343001161,
        text: 'ที่ว่าการอำเภอนาตาล',
      },
      {
        value: 380101011,
        text: 'ที่ว่าการอำเภอเมืองบึงกาฬ',
      },
      {
        value: 420301021,
        text: 'ที่ว่าการอำเภอเชียงคาน',
      },
      {
        value: 420401011,
        text: 'ที่ว่าการอำเภอปากชม',
      },
      {
        value: 420803041,
        text: 'อ.ท่าลี่(อาคารศุลกากร)',
      },
      {
        value: 430101011,
        text: 'อ.เมืองหนองคาย(ที่ว่าการอำเภอเมืองหลังเดิม)',
      },
      {
        value: 480101001,
        text: 'อ.เมืองนครพนม(สะพานมิตรภาพไทย-ลาว 3)',
      },
      {
        value: 480112052,
        text: 'อ.เมืองนครพนม(ท่าเทียบเรือ)',
      },
      {
        value: 490101011,
        text: 'ทปค.จ.มุกดาหาร(สะพานมิตรภาพไทย-ลาว 2)',
      },
      {
        value: 490101002,
        text: 'ทปค.จ.มุกดาหาร(ท่าเทียบเรือ)',
      },
      {
        value: 530602021,
        text: 'อ.บ้านโคก',
      },
      {
        value: 550001001,
        text: 'ที่ทำการปกครองจังหวัดน่าน',
      },
      {
        value: 551501011,
        text: 'ที่ว่าการอำเภอเฉลิมพระเกียรติ',
      },
      {
        value: 560801121,
        text: 'อ.ภูซาง',
      },
      {
        value: 570301011,
        text: 'อ.เชียงของ',
      },
      {
        value: 570301012,
        text: 'อ.เชียงของ(สะพานมิตรภาพไทย-ลาว 4)',
      },
      {
        value: 570802021,
        text: 'อ.เชียงแสน',
      },
      {
        value: 570801012,
        text: 'อ.เชียงแสน(สามเหลี่ยมทองคำ)',
      },
    ]
  }

  const Cambodia = () => {
    return [
      {
        value: 220000001,
        text: 'ที่ทำการปกครองจังหวัดจันทบุรี',
      },
      {
        value: 220401011,
        text: 'ที่ว่าการอำเภอโป่งน้ำร้อน',
      },
      {
        value: 220702001,
        text: 'ที่ว่าการอำเภอสอยดาว',
      },
      {
        value: 230000001,
        text: 'ที่ทำการปกครองจังหวัดตราด',
      },
      {
        value: 230201021,
        text: 'ที่ว่าการอำเภอคลองใหญ่',
      },
      {
        value: 230401031,
        text: 'ที่ว่าการอำเภอบ่อไร่',
      },
      {
        value: 270000001,
        text: 'ที่ทำการปกครองจังหวัดสระแก้ว',
      },
      {
        value: 270000002,
        text: 'จังหวัดสระแก้ว(ตลาดโรงเกลือ)',
      },
      {
        value: 270201051,
        text: 'ที่ว่าการอำเภอคลองหาด',
      },
      {
        value: 270608021,
        text: 'อ.อรัญประเทศ',
      },
      {
        value: 320000001,
        text: 'ที่ทำการปกครองจังหวัดสุรินทร์',
      },
      {
        value: 320601001,
        text: 'ที่ว่าการอำเภอกาบเชิง',
      },
      {
        value: 330000001,
        text: 'ที่ทำการปกครองจังหวัดศรีสะเกษ',
      },
      {
        value: 331701001,
        text: 'ที่ว่าการอำเภอภูสิงห์',
      },
    ] 
  }

  const maplistplace = (code) => {
    switch (code) {
      case 2200001: return "ที่ทำการปกครองจังหวัดจันทบุรี"
      case 2204001: return "ที่ว่าการอำเภอโป่งน้ำร้อน"
      case 2207001: return "ที่ว่าการอำเภอสอยดาว"
      case 2300001: return "ที่ทำการปกครองจังหวัดตราด"
      case 2302001: return "ที่ว่าการอำเภอคลองใหญ่"
      case 2304001: return "ที่ว่าการอำเภอบ่อไร่"
      case 2700001: return "ที่ทำการปกครองจังหวัดสระแก้ว"
      case 2700002: return "สนง.ออกหนังสือผ่านแดนจังหวัดสระแก้ว(ตลาดโรงเกลือ)"
      case 2702001: return "ที่ว่าการอำเภอคลองหาด"
      case 2706001: return "สนง.ออกหนังสือผ่านแดน อ.อรัญประเทศ"
      case 3200001: return "ที่ทำการปกครองจังหวัดสุรินทร์"
      case 3206001: return "ที่ว่าการอำเภอกาบเชิง"
      case 3300001: return "ที่ทำการปกครองจังหวัดศรีสะเกษ"
      case 3317001: return "ที่ว่าการอำเภอภูสิงห์"
      case 3400001: return "ที่ทำการปกครองจังหวัดอุบลราชธานี"
      case 3425001: return "สนง.ออกหนังสือผ่านแดน อ.สิรินธร(ช่องเม็ก)"
      case 3430001: return "ที่ว่าการอำเภอนาตาล"
      case 3801001: return "ที่ว่าการอำเภอเมืองบึงกาฬ"
      case 4203001: return "ที่ว่าการอำเภอเชียงคาน"
      case 4204001: return "ที่ว่าการอำเภอปากชม"
      case 4208001: return "สนง.ออกหนังสือผ่านแดน อ.ท่าลี่(อาคารศุลกากร)"
      case 4301001: return "สนง.ออกหนังสือผ่านแดน อ.เมืองหนองคาย(ที่ว่าการอำเภอเมืองหลังเดิม)"
      case 4801001: return "สนง.ออกหนังสือผ่านแดน อ.เมืองนครพนม(สะพานมิตรภาพไทย-ลาว 3)"
      case 4801002: return "สนง.ออกหนังสือผ่านแดน อ.เมืองนครพนม(ท่าเทียบเรือ)"
      case 4901001: return "สนง.ออกหนังสือผ่านแดน ทปค.จ.มุกดาหาร(สะพานมิตรภาพไทย-ลาว 2)"
      case 4901002: return "สนง.ออกหนังสือผ่านแดน ทปค.จ.มุกดาหาร(ท่าเทียบเรือ)"
      case 5306001: return "สนง.ออกหนังสือผ่านแดน อ.บ้านโคก"
      case 5500001: return "ที่ทำการปกครองจังหวัดน่าน"
      case 5515001: return "ที่ว่าการอำเภอเฉลิมพระเกียรติ"
      case 5608001: return "สนง.ออกหนังสือผ่านแดน อ.ภูซาง"
      case 5703001: return "สนง.ออกหนังสือผ่านแดน อ.เชียงของ"
      case 5703002: return "สนง.ออกหนังสือผ่านแดน อ.เชียงของ(สะพานมิตรภาพไทย-ลาว 4)"
      case 5708001: return "สนง.ออกหนังสือผ่านแดน อ.เชียงแสน"
      case 5708002: return "สนง.ออกหนังสือผ่านแดน อ.เชียงแสน(สามเหลี่ยมทองคำ)"
      case 5709001: return "สนง.ออกหนังสือผ่านแดน อ.แม่สาย"
      case 6306001: return "สนง.ออกหนังสือผ่านแดน อ.แม่สอด"
      case 6306002: return "สนง.ออกหนังสือผ่านแดน อ.แม่สอด(สะพานมิตรภาพไทย-เมียนมา2)"
      case 7101001: return "สนง.ออกหนังสือผ่านแดน อ.เมืองกาญจนบุรี(บ้านพุน้ำร้อน)"
      case 7701001: return "สนง.ออกหนังสือผ่านแดน อ.เมืองประจวบคีรีขันธ์(ด่านสิงขร)"
      case 8501002: return "สนง.ออกหนังสือผ่านแดน อ.เมืองระนอง"
      case 8501003: return "สนง.ออกหนังสือผ่านแดน อ.เมืองระนอง(ศูนย์บริการประชาชน)"
      case 9000001: return "ที่ทำการปกครองจังหวัดสงขลา"
      case 9003001: return "ที่ว่าการอำเภอจะนะ"
      case 9004001: return "ที่ว่าการอำเภอนาทวี"
      case 9005001: return "ที่ว่าการอำเภอเทพา"
      case 9006001: return "ที่ว่าการอำเภอสะบ้าย้อย"
      case 9010001: return "สนง.ออกหนังสือผ่านแดน อ.สะเดา"
      case 9100001: return "ที่ทำการปกครองจังหวัดสตูล"
      case 9101001: return "ที่ว่าการอำเภอเมืองสตูล"
      case 9102001: return "ที่ว่าการอำเภอควนโดน"
      case 9103001: return "ที่ว่าการอำเภอควนกาหลง"
      case 9104001: return "ที่ว่าการอำเภอท่าแพ"
      case 9105001: return "ที่ว่าการอำเภอละงู"
      case 9106001: return "ที่ว่าการอำเภอทุ่งหว้า"
      case 9107001: return "ที่ว่าการอำเภอมะนัง"
      case 9401001: return "ที่ว่าการอำเภอเมืองปัตตานี"
      case 9501001: return "ที่ว่าการอำเภอเมืองยะลา"
      case 9502001: return "สนง.ออกหนังสือผ่านแดน อำเภอเบตง"
      case 9503001: return "สนง.ออกหนังสือผ่านแดน อ.บันนังสตา"
      case 9504001: return "ที่ว่าการอำเภอธารโต"
      case 9505001: return "ที่ว่าการอำเภอยะหา"
      case 9506001: return "ที่ว่าการอำเภอรามัน"
      case 9507001: return "ที่ว่าการอำเภอกาบัง"
      case 9508001: return "ที่ว่าการอำเภอกรงปินัง"
      case 9600001: return "ที่ทำการปกครองจังหวัดนราธิวาส"
      case 9601001: return "ที่ว่าการอำเภอเมืองนราธิวาส"
      case 9602001: return "สนง.ออกหนังสือผ่านแดน อ.ตากใบ"
      case 9603001: return "ที่ว่าการอำเภอบาเจาะ"
      case 9604001: return "ที่ว่าการอำเภอยี่งอ"
      case 9605001: return "ที่ว่าการอำเภอระแงะ"
      case 9606001: return "ที่ว่าการอำเภอรือเสาะ"
      case 9607001: return "ที่ว่าการอำเภอศรีสาคร"
      case 9608001: return "ที่้ว่าการอำเภอแว้ง"
      case 9609001: return "ที่ว่าการอำเภอสุคิริน"
      case 9610001: return "สนง.ออกหนังสือผ่านแดน อ.สุไหงโก-ลก"
      case 9611001: return "ที่ว่าการอำเภอสุไหงปาดี"
      case 9612001: return "ที่ว่าการอำเภอจะแนะ"
      case 9613001: return "ที่ว่าการอำเภอเจาะไอร้อง"
    }
  }

  export default {
    Myanmar,
    Malaysia,
    Laos,
    Cambodia,
    maplistplace
  }
  
