<template>
  <div class="dbt-menu-group">
    <div
      v-if="hoverSt && tooltip"
      class="tooltipOverflow"
      :class="{ 'animate__fadeIn animate__animated': hoverSt }"
      :style="overflowPos"
    >
      <span class="tooltiptext">
        {{ tooltipOverflowText }}
      </span>
    </div>

    <v-card :elevation="cardShadow" class="dbt-custom-card">
      <div class="dbt-scroll-btn" @click="scrollingTo(-40)" v-if="btnNext">
        <i class="fas fa-angle-left"></i>
      </div>
      <div
        ref="menuCard"
        class="menu-card animate__animated"
        :class="{
          'expanded ': expanded,
        }"
        @wheel="scrollHorizontal"
      >
        <template v-for="(n, idx) in menulist">
          <a
            class="dbt-bullet tooltip"
            :class="{ active: idx == selected }"
            :ref="'items'"
            v-bind:key="'items-' + idx"
            @click="handdleClick(n, idx)"
            @mouseover="hovering(idx)"
            @mouseleave="leaving"
          >
            <div class="dbt-bullet-ctrl">
              <div
                class="dbt-bullet-icon"
                :class="{
                  'animate__bounceIn animate__animated ': idx == selected,
                }"
              >
                <template v-if="n.customIcon">
                  <span v-html="n.customIcon"></span>
                </template>
                <template v-else-if="n.iconClass">
                  <i :class="n.iconClass"></i>
                </template>
                <template v-else>
                  <span style="visibility: hidden">x</span>
                </template>
              </div>
            </div>
            <div>{{ n.text }}</div>
          </a>
        </template>
      </div>
      <div v-if="expandBtnDisplay" class="dbt-btn-expand" @click="expanding">
        <i v-if="expanded" class="fas fa-angle-double-up"></i>
        <i v-else class="fas fa-angle-double-down"></i>
      </div>
      <div v-if="btnNext" class="dbt-scroll-btn" @click="scrollingTo(40)">
        <i class="fas fa-angle-right"></i>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: 0,
      scroll: 0,
      expandBtnDisplay: false,
      expanded: false,
      overflowPos: {},
      hover: {},
      hoverSt: false,
      itemsPosition: [],
      tmp: 0,
      focus: 0,
    };
  },
  props: {
    tooltip: { required: false },
    btnNext: {
      type: Boolean,
      required: false,
      default: true,
    },
    btnExpand: {
      type: Boolean,
      required: false,
      default: false,
    },
    gobalFunc: {
      type: Function,
      required: false,
    },
    menulist: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },
  created() {
    this.handleWheel();
  },
  mounted() {
    // let obj = this;
    let elmW = this.$refs["menuCard"].clientWidth;
    let childW = this.iconsWidth();
    let sumW = 0;
    if (this.btnExpand) {
      this.expandBtnDisplay = childW > elmW + 1;
    }

    this.itemsPosition = this.$refs.items.map(function (n) {
      sumW += n.clientWidth;
      let retObj = {
        w: sumW,
        width: n.clientWidth,
        posY: Math.round(n.getBoundingClientRect().top),
        posX: Math.round(n.getBoundingClientRect().left),
      };
      return retObj;
    });
    // obj.focus = this.itemsPosition[0].posX;
  },
  computed: {
    tooltipOverflowText() {
      if (this.hover.tooltip) {
        return this.hover.tooltip;
      } else if (this.hover.text) {
        return this.hover.text;
      } else {
        return "";
      }
    },
    cardShadow() {
      if (this.expanded) {
        return 10;
      } else {
        return 2;
      }
    },
  },
  methods: {
    leaving() {
      this.hoverSt = false;
    },
    hovering(idx) {
      let hoverObj = this.$refs.items[idx];
      let leftPos = hoverObj.getBoundingClientRect().left - 15;
      let topPos =
        hoverObj.getBoundingClientRect().top + hoverObj.clientHeight - 10;
      this.hover = this.menulist[idx];
      this.hoverSt = true;
      this.overflowPos = { left: leftPos + "px", top: topPos + "px" };
      this.tmp = idx;
    },
    iconsWidth() {
      let child = this.$refs.items;
      let childWidth = 0;
      child.map(function (a) {
        childWidth = childWidth + a.clientWidth;
      });
      return childWidth;
    },
    expanding() {
      this.expanded = !this.expanded;
    },
    handdleClick(obj, idx) {
      this.selected = idx;
      this.$emit("selected", Object.assign({ index: idx }, obj));
      if (obj.url) {
        if (obj.urlNewTab) {
          window.open(obj.url);
        } else {
          window.location.replace(obj.url);
        }
      } else if (obj.func && obj.funcParam) {
        obj.func(obj.funcParam);
      }
    },
    scrollHorizontal(e) {
      let movePerScroll = e.deltaY * 0.4;
      this.scrollingTo(movePerScroll);
    },
    scrollingTo(movePerScroll) {
      let elm = this.$refs["menuCard"];
      let child = this.$refs.items;
      let movedTo = this.scroll + movePerScroll;
      let elmWidth = child[0].clientWidth * child.length - elm.clientWidth;
      elm.scrollTo(movedTo, 0);
      if (movedTo > 0 && movedTo < elmWidth) {
        this.scroll += movePerScroll;
      }
    },
    handleWheel() {
      window.addEventListener("wheel", function (event) {
        if (!event) {
          console.log("can't detect wheel");
        }
      });
    },
  },
};
</script>
<style scoped src="@/assets/styles/dbt-style.css"></style>
