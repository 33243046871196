const Myanmar = () => {
  return [
    { code: 1, description: "เยี่ยมญาติ" },
    { code: 2, description: "การท่องเที่ยว" },
    { code: 3, description: "ราชการ" },
    { code: 4, description: "ธุรกิจ" },
    { code: 5, description: "การกีฬา" },
    { code: 6, description: "เข้าร่วมการสัมมนา การพบปะหารือ หรือการประชุม" },
    { code: 7, description: "วัตถุประสงค์อื่นๆ" }
  ]
}

const Malaysia = () => {
  return [
    { code: 1, description: "เยี่ยมญาติ" },
    { code: 2, description: "การท่องเที่ยว" },
    { code: 3, description: "การกีฬา" },
    { code: 4, description: "หลักสูตรและการฝึกอบรมระยะสั้น" },
    { code: 5, description: "เข้าร่วมการสัมมนา การพบปะหารือ หรือการประชุม" },
    { code: 6, description: "การเข้าร่วมงานแถลงข่าว และ/หรือกิจกรรมที่เกี่ยวข้อง" },
    { code: 7, description: "วัตถุประสงค์อื่นๆ" }
  ]
}

const Laos = () => {
  return [
    { code: 1, description: "เยี่ยมญาติ" },
    { code: 2, description: "การท่องเที่ยว" },
    { code: 3, description: "ราชการ" },
    { code: 4, description: "ธุรกิจ" },
    { code: 5, description: "การกีฬา" },
    { code: 6, description: "การประชุม สัมมนา" },
    { code: 7, description: "สื่อมวลชน" },
    { code: 8, description: "การลงทุน" },
    { code: 9, description: "วัตถุประสงค์อื่นๆ" }
  ]
}

const Cambodia = () => {
  return [
    { code: 1, description: "เยี่ยมญาติ" },
    { code: 2, description: "การท่องเที่ยว" },
    { code: 3, description: "ราชการ" },
    { code: 4, description: "ธุรกิจ" },
    { code: 5, description: "การกีฬา" },
    { code: 6, description: "การทำงานแบบไป - กลับหรือแบบตามฤดูกาล" },
    { code: 7, description: "เข้าร่วมการสัมมนา การพบปะหารือ หรือการประชุม" },
    { code: 8, description: "วัตถุประสงค์อื่นๆ" }
  ] 
}

export default {
  Myanmar,
  Malaysia,
  Laos,
  Cambodia
}