<template>
  <div class="dbt-content">
    <v-row>
      <v-col md="10" sm="12" offset-md="1">
        <v-row>
          <v-col sm="12">
            <v-card elevation="2" class="dbt-content-card">
              <div class="title">
                <i class="ri-user-add-fill"></i>
                ข้อมูลขอหนังสือ
              </div>
              <div class="context">
                <v-row>
                  <v-col cols="12">
                    <div class="context">
                      <v-row>
                        <v-flex 
                          md="12"
                          sm="12"
                          style="padding: 0px 10px 10px 10px"
                          v-if="radiocheck === 1"
                        >
                          <v-checkbox
                            v-model="deliveryTypecheck"
                            label="เป็นผู้พิการ"
                            hide-details
                          ></v-checkbox>
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="6"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">ชื่อสกุล</div>
                          <v-text-field v-model="fullname" readonly/>
                        </v-flex>
                        <v-flex
                          md="6"
                          sm="12"
                          pt-2
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">ส่วนสูง (เซนติเมตร)</div>
                          <v-text-field
                            v-model="height"
                            :maxlength="3"
                            @keydown.native="keyFilter($event, 'number')"
                            @keydown.space="(event) => event.preventDefault()"
                          />
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="6"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">อาชีพ</div>
                            <v-combobox
                              prefix="*"
                              class="red--text"
                              v-model="occur"
                              :items="occurlist"
                              item-text="text"
                              item-value="value"
                              attach
                              return-object
                            />
                        </v-flex>
                        <v-flex
                          md="6"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                          >
                        <div class="dbt-label1">วัตถุประสงค์</div>
                          <v-combobox
                            prefix="*"
                            class="red--text"
                            v-model="objectives"
                            :items="objectivelist"
                            item-text="text"
                            item-value="value"
                            attach
                            return-object
                          />
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="12"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                        >
                        <div class="dbt-label1">จังหวัดเกิด</div>
                          <v-combobox
                            prefix="*"
                            class="red--text"
                            v-model="bornplace"
                            :items="bornplacelist"
                            item-text="text"
                            item-value="value"
                            attach
                            return-object
                          />
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="6"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">ตำหนิรูปพรรณ</div>
                            <v-text-field
                              v-model="specul"
                              :maxlength="60"
                              @keydown.native="keyFilter($event, 'nameTh')"
                              @keydown.space="(event) => event.preventDefault()"
                            />
                          </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="6"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">เบอร์โทรศัพท์</div>
                            <v-text-field
                              prefix="*"
                              class="red--text"
                              v-model="tel"
                              :maxlength="10"
                              @keydown.native="keyFilter($event, 'number')"
                              @keydown.space="(event) => event.preventDefault()"
                            />
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="6"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">อีเมล (ถ้ามี)</div>
                            <v-text-field
                              v-model="email"
                              :rules="emailRules"
                              @keydown.space="(event) => event.preventDefault()"
                            />
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="6"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">วันที่รับหนังสือผ่านเเดน</div>
                          <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                prefix="*"
                                class="red--text"
                                v-model="dateformat"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              :allowed-dates="allowedDates"
                              scrollable
                              locale="th"
                              :min="picker"
                              @update:picker-date="pickerUpdate($event)"
                              @change="checkdate"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                              >
                                ยกเลิก
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                              >
                                ตกลง
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div
                style="
                  flex-direction: column-reverse;
                  float: right;
                  position: fixed;
                  right: 20px;
                  bottom: 6%;
                  margin: 0px;
                  padding: 0px;
                "
              >
                <div
                  v-if="regisfirst.follower > 0"
                  style="padding-bottom: 5px; padding-left: 5.5px"
                >
                  <v-btn
                    fab
                    x-large
                    class="dbt-btn-blue"
                    :disabled="disBTN"
                    @click.prevent="follower"
                    ><v-icon> mdi-account-group</v-icon></v-btn
                  >
                </div>
                <v-flex
                  pb-2
                  style="padding: 0px 0px 0px 0px"
                  v-if="regisfirst.follower > 0"
                >
                  <div class="dbt-label">เพิ่มผู้ติดตาม</div></v-flex
                >
                <div style="padding-bottom: 10px; padding-left: 5.5px">
                  <v-btn
                    fab
                    x-large
                    class="dbt-btn-green"
                    :disabled="disBTN"
                    @click.prevent="qrcode"
                    ><v-icon>mdi-chevron-double-right</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-card-actions class="grey lighten-3"/>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { sync, get } from "vuex-pathify";
import keyFilter from "@/js/keyFilter.js";
import occupt from "@/js/occupt.js";
import objective from "@/js/objective.js";
import province from "@/js/bornprovince.js";
import Swal from "sweetalert2";
import moment from 'moment'

export default {
  name: "Registerdetail",
  data() {
    return {
      placeborn: null,
      bornplace: null,
      datetravel: null,
      dateregister: null,
      height: '',
      fnamee: '',
      fullname: null,
      mnamee: '',
      occur: null,
      occurr: null,
      objtive: null,
      objectives: null,
      specul: '',
      speculeng: '',
      registerdate: null,
      traveldate: null,
      tel: '',
      email: '',
      keyFilter: keyFilter,
      date: null,
      menu: false,
      menu1: false,
      modal: false,
      modal1: false,
      dateformat: null,
      deliveryType: 0,
      deliveryTypecheck: false,
      occurlist: [],
      objectivelist: [],
      bornplacelist: [],
      availableDates: [],
      regisselect: true,
      radiocheck: null,
      weekday: ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"],
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      emailRules: [
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v),
      ],
    };
  },
  props: {
    pagecheck: {
      type: Number,
      default: () => {
        return null;
      },
      required: true,
    },
  },
  mounted() {
    this.listdata();
  },
  methods: {
    allowedDates(val) {
      return this.availableDates.includes(val);
    },
    pickerUpdate(val) {
      let totalDay = moment(val, "YYYY-MM").daysInMonth()
      let year = moment(val, "YYYY-MM").year()
      let availableDates = []
      let availableDates1 = []
      let monthNow = moment().format('M')
      let monthSelected = moment(val).format('M')
      let day = monthNow === monthSelected ? moment().format('D') : 1
      for (let i = day; i <= totalDay ; i++) {
        for (let k = 0; k < this.holiday.length; k++) {
          if (this.formatDatek(this.holiday[k].date) === moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
            availableDates1.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
          }
        }
      }
      if (this.regisfirst.Bordercity === 48) {
        if (this.regisfirst.BorderValue !== 850101002) {
          for (let i = day; i <= totalDay ; i++) {
            availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) 
          }
        } else {
          if (availableDates1.length === 0) {
            for (let i = day; i <= totalDay ; i++) {
              availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) 
            }
          } else if (availableDates1.length === 1) {
            for (let i = day; i <= totalDay ; i++) {
              availableDates1.forEach(day => {
                if (day !== moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                  availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                }
              })
            }
          } else {
            for (let k = 0; k < availableDates1.length; k++) {
              for (let i = day; i <= totalDay ; i++) {
                if (availableDates1[k] === moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                  availableDates1.splice(k, 1)
                } else {
                  availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                }
              }
            }
          }
        }
      } else if (this.regisfirst.Bordercity === 50) {
        if ([960201001, 961001051].includes(this.regisfirst.BorderValue)) {
           if (availableDates1.length === 0) {
            for (let i = day; i <= totalDay ; i++) {
              let date = moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
              if (moment(date).day() !== 0) {
                availableDates.push(date) 
              }
            }
          } else if (availableDates1.length === 1) {
            for (let i = day; i <= totalDay ; i++) {
              let date = moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
              if (moment(date).day() !== 0) {
                availableDates1.forEach(day => {
                  if (day !== moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                    availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                  }
                })
              }
            }
          } else {
            for (let k = 0; k < availableDates1.length; k++) {
              for (let i = day; i <= totalDay ; i++) {
                if (availableDates1[k] === moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                  availableDates1.splice(k, 1)
                } else {
                  let date = moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
                  if (moment(date).day() !== 0) {
                    availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                  }
                }
              }
            }
          }
        } else {
          if (availableDates1.length === 0) {
            for (let i = day; i <= totalDay ; i++) {
              availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) 
            }
          } else if (availableDates1.length === 1) {
            for (let i = day; i <= totalDay ; i++) {
              availableDates1.forEach(day => {
                if (day !== moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                  availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                }
              })
            }
          } else {
            for (let k = 0; k < availableDates1.length; k++) {
              for (let i = day; i <= totalDay ; i++) {
                if (availableDates1[k] === moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                  availableDates1.splice(k, 1)
                } else {
                  availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                }
              }
            }
          }
        }
      } else if (this.regisfirst.Bordercity === 56) {
        if ([340001001, 550001001].includes(this.regisfirst.BorderValue)) {
          if (availableDates1.length === 0) {
            for (let i = day; i <= totalDay ; i++) {
              availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) 
            }
          } else if (availableDates1.length === 1) {
            for (let i = day; i <= totalDay ; i++) {
              availableDates1.forEach(day => {
                if (day !== moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                  availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                }
              })
            }
          } else {
            for (let k = 0; k < availableDates1.length; k++) {
              for (let i = day; i <= totalDay ; i++) {
                if (availableDates1[k] === moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                  availableDates1.splice(k, 1)
                } else {
                  availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                }
              }
            }
          }
        } 
        // else if ([490101002].includes(this.regisfirst.BorderValue)) {
        //    for (let i = day; i <= totalDay ; i++) {
        //       let date = moment().month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
        //       if (moment(date).day() !== 0 && moment(date).day() !== 6) {
        //         availableDates.push(date) 
        //       }
        //     }
        // }
         else {
          for (let i = day; i <= totalDay ; i++) {
            availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) 
          }
        }
      } else if (this.regisfirst.Bordercity === 57) {
        if ([270000002].includes(this.regisfirst.BorderValue)) {
          if (availableDates1.length === 0) {
            for (let i = day; i <= totalDay ; i++) {
              let date = moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
              if (moment(date).day() !== 0) {
                availableDates.push(date) 
              }
            }
          } else if (availableDates1.length === 1) {
            for (let i = day; i <= totalDay ; i++) {
              let date = moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
              if (moment(date).day() !== 0) {
                availableDates1.forEach(day => {
                  if (day !== moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                    availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                  }
                })
              }
            }
          } else {
            for (let k = 0; k < availableDates1.length; k++) {
              for (let i = day; i <= totalDay ; i++) {
                if (availableDates1[k] === moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                  availableDates1.splice(k, 1)
                } else {
                  let date = moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
                  if (moment(date).day() !== 0) {
                    availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                  }
                }
              }
            }
          }
        } else {
          if (availableDates1.length === 0) {
            for (let i = day; i <= totalDay ; i++) {
              let date = moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
              if (moment(date).day() !== 0 && moment(date).day() !== 6) {
                availableDates.push(date) 
              }
            }
          } else if (availableDates1.length === 1) {
            for (let i = day; i <= totalDay ; i++) {
              let date = moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
              if (moment(date).day() !== 0 && moment(date).day() !== 6) {
                availableDates1.forEach(day => {
                  if (day !== moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                    availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                  }
                })
              }
            }
          } else {
            for (let k = 0; k < availableDates1.length; k++) {
              for (let i = day; i <= totalDay ; i++) {
                if (availableDates1[k] === moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")) {
                  availableDates1.splice(k, 1)
                } else {
                  let date = moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
                  if (moment(date).day() !== 0 && moment(date).day() !== 6) {
                    availableDates.push(moment().year(year).month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD"))
                  }
                }
              }
            }
          }
        }
      }
      this.availableDates = availableDates;
      this.allowedDates();
    },
    setEdit() {
      this.radiocheck = null
      this.fullname = this.registerEdit.Fullname
      this.height = this.registerEdit.Height
      this.occur = this.registerEdit.Occupation
      this.objectives= this.registerEdit.Objective
      this.bornplace = this.registerEdit.BornPlace
      this.specul = this.registerEdit.Spepecul
      this.speculeng = this.registerEdit.Spepeculeng
      this.fnamee = this.registerEdit.FanameEng
      this.mnamee = this.registerEdit.ManameEng
      this.dateformat = this.formatDateEdit(`${this.registerEdit.Registerdate}`)
      this.email = this.registerEdit.Email
      this.tel = this.registerEdit.Tel
      if ([630601001, 630606072, 570906101, 570301011, 430101011, 490101011, 342502131, 270000001, 270608021, 901001011, 950101011, 961001051].includes(this.registerEdit.BorderValue) && (this.registerEdit.Bptype === 2)) {
        if (this.registerEdit.Bordercity === 50) {
          if (['6306', '5709', '5703', '4301', '4901', '3425', '2700', '2706', '9010', '9501', '9610'].includes(this.registerEdit.ccaah)) {
            this.radiocheck = 1
            this.deliveryType = 0
            if (this.registerEdit.deliveryType === 2) {
              this.deliveryTypecheck = true
            }
          }
        } else {
          this.radiocheck = 1
          this.deliveryType = 0
          if (this.registerEdit.deliveryType === 2) {
            this.deliveryTypecheck = true
          }
        }
      }
      this.regisselect = false;
    },
    listdata() {
      let ob;
      let oc;
      let pv;
      this.occurlist = [];
      this.objectivelist = [];
      this.bornplacelist = [];
      oc = occupt.occupt();
      pv = province.province();
      for (let k = 0; k < oc.length; k++) {
        this.occurlist.push({
          text: oc[k].thaiDescription,
          value: oc[k].code,
        });
      }
      if (this.regisfirst.Bordercity === 48) {
        ob = objective.Myanmar();
      } else if (this.regisfirst.Bordercity === 50) {
        ob = objective.Malaysia();
      } else if (this.regisfirst.Bordercity === 56) {
        ob = objective.Laos();
      } else {
        ob = objective.Cambodia();
      }
      for (let i = 0; i < ob.length; i++) {
        this.objectivelist.push({
          text: ob[i].description,
          value: ob[i].code,
        });
      }
      for (let j = 0; j < pv.length; j++) {
        this.bornplacelist.push({
          text: pv[j].thai,
          value: pv[j].code,
        });
      }
      this.occurr = oc;
      if (this.pagecheck === 0) {
        if (this.regisfirst.request !== null) {
          this.radiocheck = null
          if ([999, 832].includes(this.popfirst.title.code) || [5, 6].includes(this.popfirst.title.titleSex)) { 
            this.fullname = this.monkT.name
          } else {
            this.fullname = this.formatFullname(this.popfirst.title.description, this.popfirst.firstName, this.popfirst.middleName, this.popfirst.lastName);
          }
          this.height = this.regisfirst.request.height
          this.occur = this.regisfirst.request.occupation
          this.objectives = this.regisfirst.request.objective
          this.bornplace = this.regisfirst.request.bornPlace
          this.specul = this.regisfirst.request.spePecul
          this.speculeng = this.regisfirst.request.spePeculEng
          this.fnamee = this.regisfirst.request.fanameEng
          this.mnamee = this.regisfirst.request.manameEng
          // this.dateformat = this.formatDateEdit(`${this.regisfirst.request.registerDate}`)
          this.email = this.regisfirst.request.email === ' ' || this.regisfirst.request.email === null ? '' : this.regisfirst.request.email
          this.tel = this.regisfirst.request.phone === ' ' || this.regisfirst.request.phone === null ? '' : this.regisfirst.request.phone
          if ([630601001, 630606072, 570906101, 570301011, 430101011, 490101011, 342502131, 270000001, 270608021, 901001011, 950101011, 961001051].includes(this.regisfirst.BorderValue) && (this.regisfirst.Bptype === 2)) {
            if (this.regisfirst.request.Bordercity === 50) {
              if (['6306', '5709', '5703', '4301', '4901', '3425', '2700', '2706', '9010', '9501', '9610'].includes(this.regisfirst.ccaattmm.toString().substring(0, 4))) {
                this.radiocheck = 1
                this.deliveryType = 0
                if (this.regisfirst.request.deliveryType === 2) {
                  this.deliveryTypecheck = true
                }
              }
            } else {
              this.radiocheck = 1
              this.deliveryType = 0
              if (this.regisfirst.request.deliveryType === 2) {
                this.deliveryTypecheck = true
              }
            }
          }
        } else {
          this.placeborn = pv;
          this.occur = this.occurlist[0].text;
          if (this.birth !== null) {
            this.bornplace = {
              value: 1,
              text: this.splitaddress(this.birth.place.addressFormat),
            };
          }
          this.getdata();
        }
      } else {
        this.setEdit();
      }
    },
    getdata() {
      this.radiocheck = null;
      if ([999, 832].includes(this.popfirst.title.code) || [5, 6].includes(this.popfirst.title.titleSex)) { 
        this.fullname = this.monkT.name
      } else {
        this.fullname = this.formatFullname(this.popfirst.title.description,this.popfirst.firstName, this.popfirst.middleName, this.popfirst.lastName);
      }
      if (this.regisfirst.Bordercity === 48) {
        this.objectives = { value: 2, text: "การท่องเที่ยว" }
      } else if (this.regisfirst.Bordercity === 50) {
        this.objectives = { value: 1, text: "เยี่ยมญาติ" };
      } else if (this.regisfirst.Bordercity === 56) {
        this.objectives = { value: 2, text: "การท่องเที่ยว" };
      } else {
        this.objectives = { value: 2, text: "การท่องเที่ยว" };
      }
      if ([630601001, 630606072, 570906101, 570301011, 430101011, 490101011, 342502131, 270000001, 270608021, 901001011, 950101011, 961001051].includes(this.regisfirst.BorderValue) && (this.regisfirst.Bptype === 2)) {
        if (this.regisfirst.Bordercity === 50) {
          if (['6306', '5709', '5703', '4301', '4901', '3425', '2700', '2706', '9010', '9501', '9610'].includes(this.regisfirst.ccaattmm.toString().substring(0, 4))) {
            this.radiocheck = 1
            this.deliveryType = 0
          }
        } else {
          this.radiocheck = 1
          this.deliveryType = 0
        }
      }
    },
    checkdate() {
      this.regisselect = false;
    },
    async setdata() {
      let oc
      let oce
      let bp
      let dater
      if (this.pagecheck === 1) {
        dater = this.date === null ? this.registerEdit.Registerdate : this.fullldate(this.date)
        oc = this.occur.text === undefined ? this.registerEdit.Occupation : this.occur.text;
        oce = this.occur.text === undefined ? this.registerEdit.OccupationEng : this.occurr[this.occur.value - 1].englishDescription;
        this.objtive = this.objectives.text === undefined ? this.registerEdit.Objective : this.objectives.text
        bp = this.bornplace.text === undefined ? this.registerEdit.BornPlace : this.bornplace.text
      } else {
        if (this.regisfirst.request !== null) {
          dater = this.date === null ? this.regisfirst.request.registerDate : this.fullldate(this.date)
          bp = this.bornplace.text === undefined ? this.bornplace : this.bornplace.text
          oc = this.occur.text === undefined ? this.regisfirst.request.occupation : this.occur.text
          oce = this.occur.text === undefined ? this.regisfirst.request.occupationEng : this.occurr[this.occur.value - 1].englishDescription
          this.objtive = this.objectives.text === undefined ? this.regisfirst.request.objective : this.objectives.text
        } else {
          dater = this.fullldate(this.date)
          this.objtive = this.objectives.text
          bp = this.bornplace.text
          oc = this.occur.text === undefined ? this.occur : this.occur.text
          oce = this.occur.text === undefined ? this.occurr[0].englishDescription : this.occurr[this.occur.value - 1].englishDescription
        }
      }
      if (this.popfirst.age >= 60 && this.regisfirst.Bptype === 2) {
        this.deliveryType = 1
      }
      if (this.deliveryTypecheck === true && this.regisfirst.Bptype === 2) {
        this.deliveryType = 2
      }
      this.register = {
        ccaah: this.regisfirst.ccaattmm.toString().substring(0, 4),
        Fullname: this.fullname,
        Height: this.height,
        Occupation: oc,
        OccupationEng: oce,
        Objective: this.objtive,
        BornPlace: bp,
        BornPlaceEng: province.mapprovince(bp),
        Bptype: this.regisfirst.Bptype,
        Bordercity: this.regisfirst.Bordercity,
        Spepecul: this.specul,
        Spepeculeng: '',
        FanameEng: this.fnamee,
        ManameEng: this.mnamee,
        Registerdate: dater,
        Traveldate: dater,
        deliveryType: this.deliveryType,
        Tel: this.tel,
        Email: this.email,
        BorderValue: this.regisfirst.BorderValue
      }
      this.page = 0
    },
    splitaddress(address) {
      const Digitaddress = address.split("#");
      return Digitaddress[7];
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      let thaiyear = Number(year) + 543;
      return `${day}/${month}/${thaiyear}`;
    },
    formatDateEdit (date1) {
      if (!date1) return null
      let thaiyear= Number(date1.substring(0, 4))
      let month =  Number(date1.substring(4, 6))
      let day =  Number(date1.substring(6, 8))
      let daycheck = day.toString()
      let monthcheck = month.toString()
      let monthshow = monthcheck.length === 1 ? `0${month}` : month
      let dayshow = daycheck.length === 1 ? `0${day}` : `${day}`
      return `${dayshow}/${monthshow}/${thaiyear}`
    },
    formatDatek (date1) {
      if (!date1) return null
      let thaiyear = Number(date1.toString().substring(0, 4))
      let engyear = thaiyear - 543 
      let month =  Number(date1.toString().substring(4, 6))
      let day =  Number(date1.toString().substring(6, 8))
      let daycheck = day.toString()
      let monthcheck = month.toString()
      let monthshow = monthcheck.length === 1 ? `0${month}` : month
      let dayshow = daycheck.length === 1 ? `0${day}` : `${day}`
      return `${engyear}-${monthshow}-${dayshow}`
    },
    follower() {
      this.setdata();
      if (this.register.Registerdate > this.register.Traveldate) {
        Swal.fire({
          icon: "warning",
          text: "วันที่นัดทำรายการที่ด่านต้องน้อยกว่าวันที่เดินทาง",
          allowOutsideClick: false,
          confirmButtonText: "ตกลง",
        });
      } else {
        this.$router
          .push({ name: "follower", params: { pagecheck: 0 } })
          .catch(() => {});
      }
    },
    fullldate(date) {
      const [year, month, day] = date.split("-");
      const thaiyear = Number(year) + 543;
      return `${thaiyear}${month}${day}`;
    },
    formatFullname(title, firstName, middleName, lastName) {
      let t = `${title} ` || "";
      let f = `${firstName} ` || "";
      let m = `${middleName} ` || "";
      let l = `${lastName} ` || "";
      return `${t}${f}${m}${l}`;
    },
    qrcode() {
      this.setdata();
      if (this.register.Registerdate > this.register.Traveldate) {
        Swal.fire({
          icon: "warning",
          text: "วันที่นัดทำรายการที่ด่านต้องน้อยกว่าวันที่เดินทาง",
          allowOutsideClick: false,
          confirmButtonText: "ตกลง",
        });
      } else {
        this.$router.push("/register/followerlist").catch(() => {});
      }
    }
  },
  computed: {
    regisfirst: get("data/data"),
    register: sync("register/register"),
    registerEdit: get("register/register"),
    page: sync("page/page"),
    popfirst: get("pop/pop"),
    birth: get("birth/birth"),
    monkT: get("monk/monk"),
    holiday: get("holiday/holiday"),
    disBTN() {
      if (!this.occur) return true;
      if (!this.objectives) return true;
      if (!this.bornplace) return true;
      if (!this.dateformat) return true;
      if (!this.tel || this.tel.toString().length < 9) return true;
      if (/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email) === false && this.email) return true;
      return false;
    },
  },
  watch: {
    date(val) {
      this.dateformat = this.formatDate(val);
    },
  },
};
</script>

<style scoped src="@/assets/styles/dbt-style.css"></style>