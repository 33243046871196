import axios from 'axios'
import withResolve from '@cdglib/js-resolve-style'

const putPayment = async (body) => {
  const putApi = () => axios.put(`/api/v1/register/check/pay/`, body)
  const [error, result] = await withResolve(putApi)()
  if (error) return { error }
  return { error, result: result.data }
}

export { putPayment }