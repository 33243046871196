import axios from 'axios'
import withResolve from '@cdglib/js-resolve-style'

const base = '/api/v1/register'

const getregisterAPI = async (pid, ccaa, borderCode, bpType, borderCity) => {
  const getApi = () => axios.get(`${base}/?pid=${pid}&ccaa=${ccaa}&borderCode=${borderCode}&bpType=${bpType}&borderCity=${borderCity}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getregisterstatusAPI = async (pid, ccaa, borderCode, bpType, status) => {
  const getApi = () => axios.get(`${base}/?pid=${pid}&ccaa=${ccaa}&borderCode=${borderCode}&bpType=${bpType}&status=${status}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getFollowAPI = async (reqId, reqYear) => {
  const getApi = () => axios.get(`/api/v1/register/follower/${reqId}/${reqYear}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getRegisterByPid = async (pid) => {
  const getApi = () => axios.get(`${base}/?pid=${pid}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getRegisterByReq = async (path) => {
  const getApi = () => axios.get(`${base}/${path}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const postregisterAPI = async (data) => {
  const getApi = () => axios.post(`${base}/`, data)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

export {
  getregisterAPI,
  getRegisterByPid,
  getRegisterByReq,
  postregisterAPI,
  getregisterstatusAPI,
  getFollowAPI,
}
