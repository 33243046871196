  const paymentCambodia = () => {
    return [
      { cc:22, compcode: 707164, taxid: "0994000272618", ccc:'026', name:'ที่ทำการปกครองจังหวัดจันทบุรี' },
      { cc:23, compcode: 707165, taxid: "0994000818246", ccc:'027', name:'ที่ทำการปกครองจังหวัดตราด' },
      { cc:27, compcode: 705358, taxid: "0994000283628", ccc:'031', name:'ที่ทำการปกครองจังหวัดสระแก้ว' },
      { cc:32, compcode: 705360, taxid: "0994000325215", ccc:'034', name:'ที่ทำการปกครองจังหวัดสุรินทร์' },
      { cc:33, compcode: 705361, taxid: "0994000332548", ccc:'035', name:'ที่ทำการปกครองจังหวัดศรีษะเกษ' },
    ]
  }

  const paymentLaos = () => {
    return [
      { cc:34, compcode: 705362, taxid: "0994000336209", ccc:'036', name:'ที่ทำการปกครองจังหวัดอุบลราชธานี' },
      { cc:38, compcode: 707174, taxid: "0994000794371", ccc:'104', name:'ที่ทำการปกครองจังหวัดบึงกาฬ' },
      { cc:39, compcode: 703593, taxid: "0994000477155", ccc:'040', name:'ที่ทำการปกครองจังหวัดหนองบัวลำภู' },
      { cc:42, compcode: 708521, taxid: "0994000376219", ccc:'043', name:'ที่ทำการปกครองจังหวัดเลย' },
      { cc:43, compcode: 705368, taxid: "0994000369964", ccc:'044', name:'ที่ทำการปกครองจังหวัดหนองคาย' },
      { cc:48, compcode: 707168, taxid: "0994000389329", ccc:'049', name:'ที่ทำการปกครองจังหวัดนครพนม' },
      { cc:49, compcode: 705373, taxid: "0994000421346", ccc:'050', name:'ที่ทำการปกครองจังหวัดมุกดาหาร' },
      { cc:53, compcode: 707894, taxid: "0994000480768", ccc:'054', name:'ที่ทำการปกครองจังหวัดอุตรดิตถ์' },
      { cc:55, compcode: 705379, taxid: "0994000451733", ccc:'056', name:'ที่ทำการปกครองจังหวัดน่าน' },
      { cc:56, compcode: 708519, taxid: "0994000468890", ccc:'057', name:'ที่ทำการปกครองจังหวัดพะเยา' },
      { cc:57, compcode: 705381, taxid: "0994000448473", ccc:'058', name:'ที่ทำการปกครองจังหวัดเชียงราย' },

    ]
  }

  const paymentMyanmar = () => {
    return [
      { cc:57, compcode: 705381, taxid: "0994000448473", ccc:'058', name:'ที่ทำการปกครองจังหวัดเชียงราย' },
      { cc:63, compcode: 705386, taxid: "0994000486944", ccc:'063', name:'ที่ทำการปกครองจังหวัดตาก' },
      { cc:71, compcode: 705390, taxid: "0994000528892", ccc:'069', name:'ที่ทำการปกครองจังหวัดกาญจนบุรี' },
      { cc:77, compcode: 705395, taxid: "0994000547129", ccc:'075', name:'ที่ทำการปกครองจังหวัดประจวบคีรีขันธ์' },
      { cc:85, compcode: 705401, taxid: "0994000569351", ccc:'081', name:'ที่ทำการปกครองจังหวัดระนอง' },

    ]
  }

  const paymentMalaysia = () => {
    return [
      { cc:90, compcode: 705403, taxid: "0994000579608", ccc:'083', name:'ที่ทำการปกครองจังหวัดสงขลา' },
      { cc:91, compcode: 705404, taxid: "0994000593821", ccc:'084', name:'ที่ทำการปกครองจังหวัดสตูล' },
      { cc:94, compcode: 708520, taxid: "0994000596316", ccc:'085', name:'ที่ทำการปกครองจังหวัดปัตตานี' },
      { cc:95, compcode: 705407, taxid: "0994000604181", ccc:'088', name:'ที่ทำการปกครองจังหวัดยะลา' },
      { cc:96, compcode: 707173, taxid: "0994000609639", ccc:'089', name:'ที่ทำการปกครองจังหวัดนราธิวาส' },
    ]
  }

  const filterpayment = (ccode, countryvalue) => {
    let data
    if (countryvalue === 48) {
      const listMyanmar = paymentMyanmar()
      data = listMyanmar.filter(data => data.cc === Number(ccode));
    } else if (countryvalue === 50) {
      const listMalaysia = paymentMalaysia()
      data = listMalaysia.filter(data => data.cc === Number(ccode));
    } else if (countryvalue === 56) {
      const listLaos = paymentLaos()
      data = listLaos.filter(data => data.cc === Number(ccode));
    } else if (countryvalue === 57) {
      const listCambodia = paymentCambodia()
      data = listCambodia.filter(data => data.cc === Number(ccode));
    }
    return data
  }


  export default {
    paymentCambodia,
    paymentLaos,
    paymentMyanmar,
    paymentMalaysia,
    filterpayment
  }