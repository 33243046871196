<template>
  <div class="dbt-content">
    <v-row>
      <v-col md="10" sm="12" offset-md="1">
        <v-row>
          <v-col sm="12">
            <v-card elevation="2" class="dbt-content-card">
              <div class="title">
                <i class="ri-draft-fill"></i>
                ดาวน์โหลดคู่มือ
              </div>
              <div class="context">
                <v-row>
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex
                        v-for="i in listManual"
                        :key="i.key"
                        xs12
                        sm6
                        md4
                        style="padding: 8px"
                      >
                        <v-card>
                          <v-container fluid grid-list-md>
                            <v-layout justify-center align-center wrap>
                              <v-flex xs12 pl-2>
                                <v-icon color="green" medium>
                                  ri-draft-fill
                                </v-icon>
                              </v-flex>
                              <v-flex xs12 pl-2>
                                <div class="dbt-label">
                                  {{ `${i.manualName}` }}
                                </div>
                              </v-flex>
                              <v-flex xs12 subheading>
                                <center>
                                  <v-btn
                                    class="btn dbt-btn-blue"
                                    @click="openManual(i.manualUrl)"
                                    ><v-icon left>ri-search-line</v-icon>
                                    ดาวน์โหลดคู่มือ
                                  </v-btn>
                                </center>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Manual",
  data() {
    return {
      listManual: [
        {
          manualName: "คู่มือความตกลงรวม4ด้านประเทศ",
          manualUrl: require("@/components/doc/manualv_1.pdf"),
        },
      ],
    };
  },
  methods: {
    openManual(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped src="@/assets/styles/dbt-style.css"></style>
