import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=05eaa650&scoped=true"
import script from "./Detail.vue?vue&type=script&lang=js"
export * from "./Detail.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/dbt-style.css?vue&type=style&index=0&id=05eaa650&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05eaa650",
  null
  
)

export default component.exports