<template>
  <div class="dbt-content">
    <v-row>
      <v-col md="10" sm="12" offset-md="1">
        <v-row>
          <v-col sm="12">
            <v-card elevation="2" class="dbt-content-card">
              <div class="title">
                <i class="ri-account-circle-fill"></i>
                ตรวจสอบข้อมูลการขอหนังสือ
              </div>
              <div class="context">
                <v-row>
                  <v-col cols="12">
                    <div class="context">
                      <v-row>
                        <v-flex
                          md="12"
                          sm="12"
                          pt-3
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">ชื่อ-สกุลผู้ขอ</div>
                          <div class="dbt-text">{{register.Fullname}}</div>
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="12"
                          sm="12"
                          pt-3
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">ประเภทหนังสือ</div>
                          <div class="dbt-text">{{regisfirst.Bptext}}</div>
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="12"
                          sm="12"
                          pt-3
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">สำนักงานออกหนังสือผ่านเเดน</div>
                          <div class="dbt-text">{{regisfirst.Border}}</div>
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="12"
                          sm="12"
                          pt-3
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">วัตถุประสงค์</div>
                          <div class="dbt-text">{{register.Objective}}</div>
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          md="12"
                          sm="12"
                          pt-3
                          style="padding: 0px 10px 0px 10px"
                          v-if="transport===1" 
                        >
                          <div class="dbt-label1">รับหนังสือผ่านเเดน</div>
                          <v-radio-group v-model="rdCase" @change="tranfer()" style="color: #000000 ;font-weight: bold">
                            <!-- <v-radio label="สนง. หนังสือผ่านเเดน" value="1"></v-radio> -->
                            <!-- <v-radio :label="textadd" value="2"></v-radio> -->
                            <v-radio value="1">
                              <template v-slot:label>
                                <div> {{ 'สนง. หนังสือผ่านเเดน' }} </div>
                              </template>
                            </v-radio>
                            <v-radio value="2">
                              <template v-slot:label>
                                <div> {{ textadd }} <strong style="color: red;"> {{ '(มีค่าบริการการจัดส่ง โปรดรอการติดต่อจากเจ้าหน้าที่)' }}</strong></div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </v-flex>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
                <v-col cols="12" v-if="followerselect > 0">
                  <v-flex
                    md="12"
                    sm="12"
                    style="padding: 0px 10px 0px 10px"
                  >
                    <div class="dbt-label1" style="text-align: center;">รายการผู้ติดตาม</div>
                  </v-flex>                 
                </v-col>
                <v-row>
                  <v-col cols="12" v-if="followerselect > 0" v-resize="onResize">
                    <v-flex v-if="isMobile === false" class="context" style="padding-top: 0px">
                      <v-data-table
                        :items="body"
                        :headers="headers"
                        class="elevation-1"
                        hide-default-footer
                        light
                        disable-sort
                      >
                        <template slot="headers" slot-scope="props">
                          <tr>
                            <th
                               v-for="(header) in props.headers"
                              :key="header.text"
                              :rowspan="header.rowspan"
                              :colspan="header.colspan"
                              :width="header.width"
                              :style="{ border: '1px solid #fff !important' }"
                              :class="['border-header-style', 'font-table-th', 'background-color', 'primary lighten-1', 'white--text']">
                              {{ header.text }}
                            </th>
                          </tr>
                        </template>
                        <template v-slot:items="props">
                          <tr :style="{ background: getBG(props.index + 1 +(pagination.page - 1) * pagination.rowsPerPage)}">
                            <td class="text-xs-center" width="5%">
                              {{ props.item.no }}
                            </td>
                            <td class="text-xs-center">
                              {{ props.item.follower }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-flex>
                    <v-flex v-else class="context" style="padding-top: 0px">
                      <v-layout wrap>
                          <v-flex
                            xs12
                            sm6
                            md4
                            v-for="i in body"
                            :key="`${i.no}`"
                            style="padding: 8px"
                          >
                          <v-card style="padding: 5px">
                            <v-layout><label>ลำดับที่ {{ i.no }}</label></v-layout>
                            <v-layout>{{ i.follower }}</v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-col>
                </v-row>
              </div>
              <div
                style="
                  flex-direction: column-reverse;
                  float: right;
                  position: fixed;
                  right: 20px;
                  bottom: 6%;
                  margin: 0px;
                  padding: 0px;
                "
              >
                <div style="padding-left: 8px; padding-bottom: 75px">
                  <v-speed-dial
                    v-model="fab"
                    :direction="direction"
                    :open-on-hover="hover"
                    :transition="transition"
                    fixed
                  >
                    <template v-slot:activator>
                      <v-btn v-model="fab" color="#1F4680" dark fab>
                        <v-icon v-if="fab"> mdi-close </v-icon>
                        <v-icon v-else> mdi-plus </v-icon>
                      </v-btn>
                    </template>
                    <v-btn fab dark color="#1F4680" @click.prevent="goedit">
                      <v-icon>mdi-account-edit</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      dark
                      color="#1F4680"
                      v-if="followerselect > 0"
                      @click.prevent="goeditfollower"
                    >
                      <v-icon>mdi-account-multiple</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </div>
                <div style="padding-bottom: 10px">
                  <v-btn
                    class="dbt-btn-green"
                    fab
                    x-large
                    @click.prevent="qrcode"
                  >
                    <v-icon>mdi-floppy</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-card-actions class="grey lighten-3"/>
            </v-card>   
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { get, sync } from "vuex-pathify";
import { postregisterAPI } from "@/api/register.js";

export default {
  name: "Followerlist",
  data() {
    return {
      dialogConfirm: false,
      showTable: false, // TODO: set true for dev
      filterTable: "",
      qrcodedata: null,
      selected: [],
      body: [],
      name: [],
      savedata: null,
      followerselect: null,
      isFollow: [],
      Followis: [],
      isMobile:  false,
      headers: this.headerTable(),
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      today: null,
      transport: null,
      rdCase: '1',
      dialog: false,
      delivery: false,
      deliveryAddresss: '',
      pagination: {
        sortBy: "sequence",
        page: 1,
      },
      rowsPerPageItem: [
        { text: "5 รายการ", value: 5 },
        { text: "10 รายการ", value: 10 },
        { text: "25 รายการ", value: 25 },
        { text: "ทั้งหมด", value: -1 },
      ],
      direction: "top",
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      transition: 'slide-x',
      textadd: null,
    }
  },
  async mounted() {
    this.setfollower();
  },
  methods: {
   onResize() {
    if (window.innerWidth < 760)
      this.isMobile = true;
    else
      this.isMobile = false;
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      let thaiyear = Number(year) + 543
      return `${thaiyear}${month}${day}`
    },
    calage(dob) {
      let Ddate = dob.toString();
      let fdate = this.today.toString();
      let yeardob = `${Ddate.substring(0, 4)}`;
      let yeartoday = `${fdate.substring(0, 4)}`;
      let agecal = yeartoday - yeardob;
      return agecal;
    },
    setfollower() {
      this.textadd = null
      this.name = []
      this.Followis = []
      this.isFollow = []
      this.today = this.formatDate(this.picker)
      if (this.page === 1) {
        this.body = [];
        for (let h = 0; h < this.follower.follername.length; h++) {
          if (this.follower.Checkbox[h] === true) {
            this.name.push(this.follower.follername[h]);
          }
        }
        for (let h = 0; h < this.name.length; h++) {
          this.body.push({ 
            no: h + 1,
            follower: this.name[h],
          });
        }
        for (let p = 0; p < this.follower.select.length; p++) {
          if (this.follower.Checkbox[p] === true) {
            this.isFollow.push({
              title: this.follower.select[p].title,
              pid: this.follower.select[p].pid,
              fname: this.follower.select[p].fname,
              lname: this.follower.select[p].lname,
              ename: this.follower.Engname[p],
              sex: this.follower.select[p].sex,
              height: this.checkHight(this.follower.Height[p]),
              dob: this.follower.select[p].dateOfBirth,
              age: this.calage(this.follower.select[p].dateOfBirth),
              bornPlace: this.follower.BornPlace[p].text,
              faname: this.follower.Fanamethai[p],
              fanameEng: this.follower.FanameEng[p],
              maname: this.follower.Manamethai[p],
              manameEng: this.follower.ManameEng[p],
              photoName: this.follower.bp1Number[p],
              followerNo: this.isFollow.length+1
            })
          }
        }
        this.followerselect = this.isFollow.length;
      } else {
        this.followerselect = 0;
        this.isFollow = null;
      }
      this.qrcodedata = {
        status: 1,
      };
      if ([1, 2].includes(this.register.deliveryType)) {
        if (this.regisfirst.ccaattmm.toString().substring(0, 6) === this.regisfirst.ccaattmmplace.toString().substring(0, 6)) {
          this.textadd = this.regisfirst.mm === "" ? `จัดส่งที่บ้านเลขที่ ${this.regisfirst.houseNo}  ต.${this.regisfirst.ttDescription}` : `จัดส่งที่บ้านเลขที่ ${this.regisfirst.houseNo}  ม.${this.regisfirst.mm} ต.${this.regisfirst.ttDescription}`
          this.transport = 1
        }
      }
    },
    tranfer() {
      this.deliveryAddresss = this.rdCase === '1' ? '' : this.regisfirst.addr1 + this.regisfirst.addr2
    },
    goeditfollower() {
      this.$router
        .push({ name: "follower", params: { pagecheck: 1 } })
        .catch(() => {});
    },
    setdata() {
      let emonkname
      let titlemonk
      if (this.popfirst.title.code === 999 || [5, 6].includes(this.popfirst.title.titleSex)) {
        emonkname = this.monkT.descriptionenglish === "" ? `PHRA#${this.smcardfirst.englishName.firstName}#${this.smcardfirst.englishName.middleName}#${this.smcardfirst.englishName.lastName}` : `${this.monkT.descriptionenglish}#${this.smcardfirst.englishName.firstName}#${this.smcardfirst.englishName.middleName}#${this.smcardfirst.englishName.lastName}`
        titlemonk = this.monkT.descriptionthai
      } else if (this.popfirst.title.code === 832 && this.popfirst.title.titleSex === 1) {
        emonkname = `${this.monkT.descriptionenglish}#${this.smcardfirst.englishName.firstName}#${this.smcardfirst.englishName.middleName}#${this.smcardfirst.englishName.lastName}`
        titlemonk = this.monkT.descriptionthai
      } else {
        emonkname = `${this.smcardfirst.englishName.title}#${this.smcardfirst.englishName.firstName}#${this.smcardfirst.englishName.middleName}#${this.smcardfirst.englishName.lastName}`
        titlemonk =  this.popfirst.title.description
      }
      this.savedata = {
        borderCity: Number(this.regisfirst.Bordercity),
        ccaa: Number(this.regisfirst.ccaa),
        borderCode: Number(this.regisfirst.Bordercode),
        bpType: this.regisfirst.Bptype,
        pid: this.popfirst.pid,
        title: titlemonk,
        fname: this.popfirst.firstName + " " + this.popfirst.middleName,
        lname: this.popfirst.lastName,
        ename: emonkname,
        sex: this.popfirst.sex.code,
        height: Number(this.checkHight(this.register.Height)),
        dob: this.popfirst.dateOfBirth,
        addr1: this.regisfirst.addr1,
        addr2: this.regisfirst.addr2,
        addr1Eng: this.regisfirst.addr1Eng,
        addr2Eng: this.regisfirst.addr2Eng,
        lrcode: Number(this.regisfirst.lrcode),
        bornPlace: this.register.BornPlace,
        bornPlaceEng: this.register.BornPlaceEng,
        objective: this.register.Objective,
        spePecul: this.register.Spepecul,
        spePeculEng: this.register.Spepeculeng,
        occupation: this.register.Occupation,
        occupationEng: this.register.OccupationEng,
        oldRequest: this.regisfirst.oldRequest,
        faname: this.popfirst.fatherName,
        fanameEng: this.register.FanameEng,	
        maname: this.popfirst.motherName,	
        manameEng: this.register.ManameEng,
        email: this.register.Email,
        phone: this.register.Tel,
        deliveryType: (this.rdCase === '1') ? 0 : this.register.deliveryType,
        deliveryAddress: this.deliveryAddresss,
        timeLtambon: this.regisfirst.TimeLtambon,
        photoName: this.smcardfirst.bp1Number,
        titleCode: this.popfirst.title.code,
        titleSex: this.popfirst.title.titleSex,
        follower: this.followerselect,
        travelDate: Number(this.register.Traveldate),
        registerDate: Number(this.register.Registerdate),
        listFollow: this.isFollow
      };
    },
    goedit() {
      this.$router
        .push({ name: "registerdetail", params: { pagecheck: 1 } })
        .catch(() => {});
    },
    qrcode() {
      this.setdata()
      Swal.fire({
        title: 'บันทึกข้อมูล',
        html: this.rdCase === '2' ? '<p>ต้องการบันทึกข้อมูลหรือไม่<br/><strong style="color: red;">(มีค่าบริการการจัดส่ง โปรดรอการติดต่อจากเจ้าหน้าที่)</strong></p>' : '<p>ต้องการบันทึกข้อมูลหรือไม่</p>',
        icon: "warning",
        iconColor: "#DCA000",
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.savedata.timeLtambon > 1500) {
            Swal.fire({
              icon: "error",
              text: `ไม่สามารถลงทะเบียนได้ จำนวนอาศัยในพื้นที่ไม่ถูกต้อง [${this.savedata.timeLtambon}]`,
              allowOutsideClick: false,
              confirmButtonText: "ตกลง",
            })
          } else {
            this.save = this.savedata;
            this.registerdata(this.savedata);
          }
        } else {
          Swal.close()
        }
      })
    },
    async registerdata(data) {
      const postregister = await postregisterAPI(data);
      if (postregister.result) {
        this.ispay = postregister.result.isPay
        this.$router
          .push({
            name: "qrcode",
            params: { dataDetail: this.qrcodedata, flagPage: false },
          })
          .catch(() => {});
        Swal.fire({
          title: "บันทึกข้อมูล!",
          text: "บันทึกข้อมูลเรียบร้อยเเล้ว",
          allowOutsideClick: false,
          icon: "success",
          confirmButtonText: "ตกลง",
        });
      } else {
        if ([400, 401, 403, 404, 409, 500].includes(postregister.error.response.status)) {
          Swal.fire({
            icon: "error",
            allowOutsideClick: false,
            text: `ไม่สามารถลงทะเบียนได้ [เนื่องจาก ${postregister.error.response.data.info}]`,
            confirmButtonText: "ตกลง",
          });
        } else {
          Swal.fire({
            icon: "error",
            allowOutsideClick: false,
            text: `ไม่สามารถลงทะเบียนได้ [${postregister.error}]`,
            confirmButtonText: "ตกลง",
          });
        }
      }
    },
    checkHight(hight) {
      return hight === "" ? 0 : hight;
    },
    getBG(no) {
      if (no % 2 === 0) return "#F5F5F5";
      else return "#ffffff";
    },
    headerTable() {
      return [
        {
          key: 1,
          text: "ลำดับที่",
          value: "no",
          rowSpan: 1,
          colSpan: 1,
          sortable: true,
        },
        {
          key: 2,
          text: "ชื่อ-สกุลผู้ติดตาม",
          value: "follower",
          rowSpan: 1,
          colSpan: 1,
          sortable: true,
        },
      ];
    },
  },
  computed: {
    regisfirst: get("data/data"),
    register: get("register/register"),
    follower: get("follower/follower"),
    page: get("page/page"),
    popfirst: get("pop/pop"),
    smcardfirst: get("smcard/smcard"),
    birth: get("birth/birth"),
    save: sync("savedata/savedata"),
    ispay: sync("ispay/ispay"),
    monkT: get("monk/monk"),
  },
};
</script>
<style scoped src="@/assets/styles/dbt-style.css">
</style>