<template>
  <div class="dbt-content">
    <v-row>
      <v-col md="10" sm="12" offset-md="1">
        <v-row>
          <v-col sm="12">
            <v-card elevation="2" class="dbt-content-card">
              <div class="title">
                <i class="ri-user-add-fill"></i>
                ข้อมูลขอหนังสือ
              </div>
              <div class="context">
                <v-row>
                  <v-col cols="12">
                    <div class="context">
                      <v-row>
                        <v-flex
                          md="12"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">ประเทศ</div>
                          <v-combobox
                            :disabled="chckeddis"
                            v-model="Country"
                            :items="Countryitem"
                            attach
                            return-object
                            @change="provinceplace()"
                          />
                        </v-flex>
                        <v-flex
                          md="12"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">ประเภทหนังสือ</div>
                          <v-combobox
                            :disabled="chckeddis1"
                            v-model="Bptype"
                            :items="itemBptype"
                            attach
                            return-object
                            @change="Bptypecost()"
                          />
                        </v-flex>
                      </v-row>
                      <v-row>
                        <v-flex
                          v-if="Country && Bptype"
                          md="12"
                          sm="12"
                          style="padding: 0px 10px 0px 10px"
                        >
                          <div class="dbt-label1">สำนักงานออกหนังสือผ่านแดน</div>
                          <v-combobox
                            v-model="Border"
                            :items="Bordername"
                            item-text="text"
                            item-value="value"
                            attach
                            return-object
                          />
                        </v-flex>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div
                style="
                  flex-direction: column-reverse;
                  float: right;
                  position: fixed;
                  right: 20px;
                  bottom: 6%;
                  margin: 0px;
                  padding: 0px;
                "
              >
                <v-btn
                  class="dbt-btn-green"
                  x-large
                  fab
                  :disabled="disBTN"
                  @click.prevent="save"
                  ><v-icon>mdi-chevron-double-right</v-icon></v-btn
                >
              </div>
              <v-card-actions class="grey lighten-3"/>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Dialog :alertDialog="alertDialog" :waitDialog="waitDialog" />
  </div>
</template>

<script>
import borderlist from "@/js/borderlist.js";
import  billpayment  from "@/js/billpayment.js";
import { getpicAPI } from "@/api/imgcard.js";
import { getpopAPI, getfatherAPI, getmotherAPI } from "@/api/pop.js";
import { getsmcardAPI } from "@/api/smcard.js";
import { getbirthAPI } from "@/api/birth.js";
import { getregisterstatusAPI, getFollowAPI } from "@/api/register";
import { getrcodeAPI, gettitlemonk, getholiday } from "@/api/tabdb.js";
import {
  getHouseAPI,
  getttAPI,
  getaaAPI,
  getccAPI,
  gettrokAPI,
  getsoiAPI,
  getthanonAPI,
} from "@/api/house.js";
import { getmoveAPI } from "@/api/move.js";
import Swal from "sweetalert2";
import { sync } from "vuex-pathify";
import Dialog from "@/components/ui/dialog/Dialog";

export default {
  name: "mainregister",
  data() {
    return {
      waitDialog: false,
      alertDialog: {
        val: false,
        msg: "",
        type: "",
      },
      Bptype: null,
      Bptypeitem: [
        { text: "หนังสือผ่านเเดนชั่วคราว", value: 1 },
        { text: "หนังสือผ่านเเดน(เเบบเล่ม)", value: 2 },
      ],
      Bptypeitem1: [{ text: "หนังสือผ่านเเดน(เเบบเล่ม)", value: 2 }],
      itemBptype: [],
      Country: null,
      Countryitem: [
        { text: "เมียนมา", value: 48 },
        { text: "มาเลเซีย", value: 50 },
        { text: "ลาว", value: 56 },
        { text: "กัมพูชา", value: 57 },
      ],
      Border: null,
      Bordername: [],
      Bordermobile: null,
      Bordernamemobile: [],
      Bordermobile1: null,
      Bordernamemobile1: [],
      Follow: [],
      cost: null,
      aaDescription: "",
      aaDescriptionEng: "",
      ccDescription: "",
      ccDescriptionEng: "",
      ttDescription: "",
      ttDescriptionEng: "",
      trok: "",
      trokEng: "",
      soi: "",
      soiEng: "",
      thanon: "",
      thanonEng: "",
      houseNo: '',
      lrcode: null,
      follower: null,
      mm: null,
      age: null,
      timeLtambon: 0,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      today: null,
      testpid: null,
      chkbook: null,
      type: null,
      picture: null,
      chckeddis: false,
      chckeddis1: true,
      poptest: null,
      housetest: null,
      movetest: null,
      followregis: null,
      aaccaattmm: null,
      isBirth: false,
      monkname: null,
      dayplace: null,
      thaiday: null
    };
  },
  components: {
    Dialog,
  },
  async mounted() {
    console.log("loginR", this.login);
    await this.getpic(this.login.userInfo.pid);
  },
  computed: {
    regisfirst: sync("data/data"),
    popfirst: sync("pop/pop"),
    smcardfirst: sync("smcard/smcard"),
    followerlist: sync("followerlist/followerlist"),
    birth: sync("birth/birth"),
    login: sync("login/login"),
    monkT: sync("monk/monk"),
    holidayAll: sync("holiday/holiday"),
    disBTN() {
      if (!this.Country) return true;
      if (!this.Bptype) return true;
      if (!this.Border) return true;
      return false;
    },
  },
  methods: {
    async getdaytoday() {
      let holidaynextyear = null
      this.holidayAll = []
      this.thaiday = this.formatDate(this.picker)
      let holiday = await getholiday(this.thaiday.substring(0, 4))
      if (holiday.result) {
        if (this.thaiday.substring(4, 6) === '12') {
          holidaynextyear = await getholiday(Number(this.thaiday.substring(0, 4)) + 1)
          this.holidayAll = holidaynextyear.result ? holiday.result.concat(holidaynextyear.result) : holiday.result
        } else {
          this.holidayAll = holiday.result
        }
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      let thaiyear = Number(year) + 543;
      return `${thaiyear}${month}${day}`;
    },
    async funtest() {
      this.poptest = {
        pid: 1639800163665,
        age: 26,
        dateOfBirth: 25371125,
        firstName: "สุนิสา",
        hid: 63060389152,
        lastName: "ปวงคำใจ",
        middleName: "",
        nationality: {
          code: 99,
          description: "ไทย",
        },
        popStatus: {
          code: 0,
          description: "บุคคลนี้มีภูมิลำเนาอยู่ในบ้านนี้",
        },
        sex: {
          code: 2,
          description: "หญิง",
        },
        title: {
          code: 4,
          description: "น.ส.",
          titleSex: 2,
          titleMonkDescription: "",
        },
        blood: {
          code: 1,
          description: "เอ",
        },
        fatherName: "สุนทร",
        fid: 0,
        fatherNationality: {
          code: 99,
          description: "ไทย",
        },
        motherName: "มะลิ",
        mid: 0,
        motherNationality: {
          code: 98,
          description: "ไม่ได้สัญชาติไทย",
        },
      };
      this.housetest = {
        ccaattmm: {
          code: 63060702,
          ccDescription: "ตาก",
          aaDescription: "แม่สอด",
          ttDescription: "แม่ปะ",
        },
        houseNo: "1027",
        rcode: {
          code: "6306",
          description: {
            thai: "อำเภอแม่สอด",
            english: "Mae Sot District",
          },
          branch: null,
        },
        soi: {
          code: "0000",
          description: "",
        },
        thanon: {
          code: "0000",
          description: "",
        },
        trok: {
          code: "0000",
          description: "",
        },
        terminateDate: 0,
      };
      this.movetest = [
        /* {
        "pid": 1639800163665,
        "dateMi": 25570314,
        "oldDateMi": 25371129,
        "rcodeIn": {
            "code": "6306",
            "description": "อำเภอแม่สอด"
        }
      } */
        {
          pid: 1639800163665,
          dateMi: 25510827,
          oldDateMi: 25241211,
          rcodeIn: {
            code: "9098",
            description: "อำเภอแม่สอด",
          },
        },
      ];
    },
    async getpic(pid) {
      // 3730100699187
      // 1103700118497
      // 1103400066777 หนองคาย
      this.followerlist = []
      this.Follow = []
      this.monkT = []
      this.follower = 0
      let ccCode = ["90", "91", "94", "95", "96"];
      this.waitDialog = true;
      let pop = await getpopAPI(pid);
      if (pop.result) {
        console.log(pop.result)
        if ([0, 15].includes(pop.result.popStatus.code)) {
          this.popfirst = pop.result;
          this.age = pop.result.age;
          if (this.age >= 12) {
            let pic = await getpicAPI(pop.result.pid);
            if (pic.result) {
              this.picture = pic.result;
              let smcard = await getsmcardAPI(pop.result.pid);
              if (smcard.result) {
                await this.getdaytoday()
                this.smcardfirst = smcard.result;
                if (pop.result.title.code === 999) {
                  let monk = await gettitlemonk(pop.result.pid)
                  if (monk.error) {
                    if ([5, 6].includes(pop.result.title.titleSex)) {
                      this.monkname = `พระ${pop.result.firstName} ${pop.result.lastName}`;
                    }
                    this.monkT = {
                      descriptionthai: 'พระ',
                      descriptionenglish: 'PHRA',
                      name: this.monkname
                    }
                  } else { 
                    if ([5, 6].includes(pop.result.title.titleSex)) {
                      this.monkname = `${monk.result.description.thai}(${pop.result.firstName} ${pop.result.lastName})`;
                    }
                    this.monkT = {
                      descriptionthai: monk.result.description.thai,
                      descriptionenglish: monk.result.description.english,
                      name: this.monkname
                    }
                  }
                } else if ([5, 6].includes(pop.result.title.titleSex)) {
                  let monkdata = await gettitlemonk(pop.result.pid)
                  if (monkdata.error) {
                    if ([5, 6].includes(pop.result.title.titleSex)) { 
                      this.monkname = `พระ${pop.result.firstName} ${pop.result.lastName}`;
                    }
                    this.monkT = {
                      descriptionthai: 'พระ',
                      descriptionenglish: 'PHRA',
                      name: this.monkname
                    }
                  } else {
                    if ([5, 6].includes(pop.result.title.titleSex)) { 
                      this.monkname = `${monkdata.result.description.thai}(${pop.result.firstName} ${pop.result.lastName})`;
                    }
                    this.monkT = {
                      descriptionthai: monkdata.result.description.thai,
                      descriptionenglish: monkdata.result.description.english,
                      name: this.monkname
                    }
                  }
                } else if (pop.result.title.code == 832 && pop.result.title.titleSex == 1) {
                  this.monkname = `สามเณร${pop.result.firstName} ${pop.result.lastName}`;
                  this.monkT = {
                    descriptionthai: 'สามเณร',
                    descriptionenglish: 'SAMMANEN',
                    name: this.monkname
                  }
                }
                if (pop.result.sex.code === 1) {
                  let father = await getfatherAPI(this.popfirst.pid);
                  if (father.result) {
                    const fat = father.result.filter(word => word.age < 12);
                    if (fat.length > 0) {
                      for (let i = 0; i < fat.length; i++) {
                        let fathersmcard = await getsmcardAPI(fat[i].pid);
                        if (fathersmcard.result) {
                          if (fathersmcard.result.personalID === fat[i].pid) {
                            this.Follow.push(fat[i]);
                          }
                        }
                      }
                      this.followerlist = this.Follow;
                      this.follower = this.Follow.length;
                    }
                  } else {
                    this.follower = 0;
                  }
                } else {
                  let mother = await getmotherAPI(this.popfirst.pid);
                  if (mother.result) {
                    const mat = mother.result.filter(word => word.age < 12);
                    if (mat.length > 0) {
                      for (let i = 0; i < mat.length; i++) {         
                        let mothersmcard = await getsmcardAPI(mat[i].pid);
                        if (mothersmcard.result) {
                          if (mothersmcard.result.personalID === mat[i].pid) {
                            this.Follow.push(mat[i]);
                          }
                        }
                      }
                      this.followerlist = this.Follow;
                      this.follower = this.Follow.length;
                    }
                  } else {
                    this.follower = 0;
                  }
                }
                let birthregis = await getbirthAPI(this.popfirst.pid);
                if (birthregis.result) {
                  this.birth = birthregis.result;
                  if (ccCode.includes(this.birth.place.rcode.substring(0, 2))) {
                    this.isBirth = true;
                  } else {
                    this.isBirth = false;
                  }
                } else {
                  this.birth = null;
                }
                let house = await getHouseAPI(pop.result.hid);
                if (house.result) {
                  this.aaccaattmm = house.result.ccaattmm.code;
                  this.houseNo = house.result.houseNo;
                  this.lrcode = house.result.rcode.code;
                  this.mm = this.moo(house.result.ccaattmm.code);
                  let tt = await getttAPI(house.result.ccaattmm.code);
                  this.ttDescription = tt.result ? tt.result[0].description.thai : house.result.ccaattmm.ttDescription
                  this.ttDescriptionEng = tt.result ? tt.result[0].description.english : ""
                  let aa = await getaaAPI(house.result.ccaattmm.code);
                  this.aaDescription = aa.result ? aa.result[0].description.thai : house.result.ccaattmm.aaDescription
                  this.aaDescriptionEng = aa.result ? aa.result[0].description.english : ""
                  let cc = await getccAPI(house.result.ccaattmm.code);
                  this.ccDescription = cc.result ? cc.result[0].description.thai : house.result.ccaattmm.ccDescription
                  this.ccDescriptionEng = cc.result ? cc.result[0].description.english : ""
                  let trok = await gettrokAPI(house.result.rcode.code + house.result.trok.code);
                  this.trok = trok.result ? trok.result.description.thai : house.result.trok.description
                  this.trokEng = trok.result ? trok.result.description.english : ""
                  let soi = await getsoiAPI(house.result.rcode.code + house.result.soi.code);
                  this.soi = soi.result ? soi.result.description.thai : house.result.soi.description
                  this.soiEng = soi.result ? soi.result.description.english : ""
                  let thanon = await getthanonAPI(house.result.rcode.code + house.result.thanon.code);
                  this.thanon = thanon.result ? thanon.result.description.thai : house.result.thanon.description
                  this.thanonEng = thanon.result ? thanon.result.description.english : ""
                }
                this.waitDialog = false;
              } else {
                this.waitDialog = false
                this.chckeddis = true
                this.chckeddis1 = true
                return this.sweetAlert('warning', "ไม่พบข้อมูลบัตร")
              }
            } else {
              this.waitDialog = false
              this.chckeddis = true
              this.chckeddis1 = true
              return this.sweetAlert('warning', "ไม่พบข้อมูลภาพใบหน้า")
            }
          } else {
            this.waitDialog = false
            return this.sweetAlert('warning', "ไม่สามารถขอได้เนื่องจากอายุไม่ถึง 12 ปี")
          }
        } else {
          this.waitDialog = false
          this.chckeddis = true
          this.chckeddis1 = true
          return this.sweetAlert('warning', `ไม่สามารถดำเนินการได้ [${pop.result.popStatus.code}] กรุณาติดต่อสำนักทะเบียน`)
        }
      } else {
        this.waitDialog = false
        this.chckeddis = true
        this.chckeddis1 = true
        return this.sweetAlert('warning', "ไม่พบข้อมูล")
      }
    },
    async alert() {
      Swal.fire({
        title: "Enter your pid",
        input: "text",
        confirmButtonColor: "#3085d6",
        allowOutsideClick: false,
        cancelButtonColor: "#d33",
        confirmButtonText: "บันทึก",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          this.getpic(result.value);
        }
      });
    },
    async savedata(reqYear, reqId, requestold, foll) {
      let oldreq;
      let ccCode = ["90", "91", "94", "95", "96"];
      if (reqYear === null && reqId === null) {
        oldreq = null;
      } else {
        oldreq = { reqYear, reqId };
      }
      let Borderstr = this.Border.value.toString();
      if (this.Bptype.value === 1) { 
        this.timeLtambon = this.age * 12;
        this.chkbook = true
      } else {
        if (this.Country.value === 50) {
          if (ccCode.includes(Borderstr.toString().substring(0, 2))) {
            if (!ccCode.includes(this.lrcode.toString().substring(0, 2))) {
              this.waitDialog = false;
              return this.sweetAlert('warning', "บ้านไม่อยู่ในพื้นที่ที่สามารถออกหนังสือได้")
            }
          }
        } else {
          if (Borderstr.toString().substring(2, 4) === "00") {
            if (this.lrcode.toString().substring(0, 2) !== Borderstr.toString().substring(0, 2)) {
              this.waitDialog = false;
              return this.sweetAlert('warning', "บ้านไม่อยู่ในพื้นที่ที่สามารถออกหนังสือได้")
            }
          } else if (this.lrcode.toString() !== Borderstr.toString().substring(0, 4)) {
            if (this.lrcode.toString().substring(0, 2) === Borderstr.toString().substring(0, 2)) {
              let resultRcode = await getrcodeAPI(this.lrcode);
              if (resultRcode.result.upToDistrictRcode.toString() !== Borderstr.toString().substring(0, 4)) {
                this.waitDialog = false;
                return this.sweetAlert('warning', "บ้านไม่อยู่ในพื้นที่ที่สามารถออกหนังสือได้")
              }
            } else {
              this.waitDialog = false;
              return this.sweetAlert('warning', "บ้านไม่อยู่ในพื้นที่ที่สามารถออกหนังสือได้")
            }
          }
        }
        if (this.Country.value === 50) {
          await this.checkLtambon(this.popfirst.pid, this.popfirst, Borderstr.substring(0, 4));
        } else {
          await this.checkLtambon(this.popfirst.pid, this.popfirst, Borderstr.substring(0, 4));
        }
      }
      if (this.chkbook === true) {
        let bill = billpayment.filterpayment(Borderstr.substring(0, 2), this.Country.value)
        this.regisfirst = {
          ccaattmmplace: Borderstr.substring(0, 8),
          ccaa: Borderstr.substring(0, 4),
          Bordercode: Borderstr.substring(9, 8),
          Border: this.Border.text,
          BorderValue: this.Border.value,
          Bptype: this.Bptype.value,
          Bptext: this.Bptype.text,
          Bordercity: this.Country.value,
          Cost: this.cost,
          ccaattmm: this.aaccaattmm,
          TimeLtambon: this.timeLtambon,
          houseNo: this.houseNo,
          mm: this.mm,
          ttDescription: this.ttDescription,
          addr1: this.TotalAddress(
            this.houseNo,
            this.mm,
            this.trok,
            this.soi,
            this.thanon
          ),
          addr2: this.addStringccaattmm(
            this.ttDescription,
            this.aaDescription,
            this.ccDescription
          ),
          addr1Eng: this.TotalAddress(
            this.houseNo,
            this.mm,
            this.trokEng,
            this.soiEng,
            this.thanonEng
          ),
          addr2Eng: this.addStringccaattmm(
            this.ttDescriptionEng,
            this.aaDescriptionEng,
            this.ccDescriptionEng
          ),
          follower: this.follower,
          lrcode: this.lrcode,
          oldRequest: oldreq,
          request: requestold,
          followerregis: foll,
          compcode: bill[0].compcode,
          taxid: bill[0].taxid,
          ccc: bill[0].ccc,
          name: bill[0].name
        };
        this.waitDialog = false;
        this.$router.push({ name: "registerdetail", params: { pagecheck: 0 } }).catch(() => {});
      } else {
        this.waitDialog = false;
      }
    },
    async save() {
      this.waitDialog = true;
      let todaycheck
      this.followregis = null
      let Borderstr = this.Border.value.toString();
      let status = await getregisterstatusAPI(
        this.popfirst.pid,
        Borderstr.substring(0, 4),
        Borderstr.substring(9, 8),
        this.Bptype.value,
        0
      );
      if (status.result) {
        const regisr = status.result.sort((a, b) => b.reqId - a.reqId);
        let Follow = await getFollowAPI(regisr[0].reqId, regisr[0].reqYear);
        if (Follow.result) {
          this.followregis = Follow.result;
        }
        todaycheck = this.formatDate(this.picker);
        if (Number(todaycheck) > regisr[0].travelDate) {
          this.savedata(regisr[0].reqYear, regisr[0].reqId, regisr[0], this.followregis);
        } else {
          Swal.fire({
            title: "ต้องการขอหนังสือใหม่ ใช่หรือไม่",
            text: `ระบบจะทำการยกเลิก รายการวันที่เดินทาง ${this.formatDateslash(regisr[0].travelDate)} ให้อัตโนมัติ โดยรายการจะถูกยกเลิกอัตโนมัติและไม่สามารถขอรับเงินคืนได้ หากท่านได้ชำระเงินผ่านระบบ e-Payment แล้ว`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
          }).then((result) => {
            if (result.isConfirmed) {
              this.savedata(regisr[0].reqYear, regisr[0].reqId, regisr[0], this.followregis);
            } else {
              this.waitDialog = false;
            }
          });
        }
      } else {
        this.savedata(null, null, null, null);
      }
    },
    async Bptypecost() {
      this.Border = null;
      this.cost = this.Bptype.value === 1 ? 30 : 200
    },
    formatDateslash(date) {
      if (!date) return null;
      date = `${date}`;
      return `${Number(date.substring(6, 8))}/${Number(
        date.substring(4, 6)
      )}/${Number(date.substring(0, 4))}`;
    },
    async checkLtambon(pid, pop, ccaa) {
      let ccCode = ["90", "91", "94", "95", "96"];
      let caldate = 0;
      let mov;
      this.timeLtambon = 0;
      this.chkbook = false;
      this.today = this.formatDate(this.picker);
      let move = await getmoveAPI(pid);
      if (move.result) {
        mov = move.result.sort((a, b) => Number(`${a.addDate}${a.dateMi}`) - Number(`${b.addDate}${b.dateMi}`));
        console.log(mov)
        if (this.Country.value === 50) {
          if (mov.length === 1) {
            if ((mov[0].dateMi === 0 && mov[0].oldDateMi === 0) || Number(mov[0].rcodeIn.code) === 0 || Number(mov[0].rcodeOut.code) === 0 || mov[0].addDate === 0) {
              return this.sweetAlert('warning', "รายการย้ายไม่สมบูรณ์ กรุณาทำรายการที่สำนักงานออกหนังสือผ่านแดน")
            } else {
              if (ccCode.includes(mov[0].rcodeIn.code.toString().substring(0, 2))) {
                caldate += await this.caldateday(this.today, mov[0].dateMi.toString());
              }
              if (ccCode.includes(mov[0].rcodeOut.code.toString().substring(0, 2))) {
                caldate += await this.caldateday(mov[0].dateMi.toString(), mov[0].oldDateMi.toString());
                caldate += await this.caldateday(mov[0].oldDateMi.toString(), pop.dateOfBirth);
              }
            }
          } else {
            for (let k = mov.length - 1; k >= 0; k--) {
              if (mov[k].dateMi === 0 || mov[k].oldDateMi === 0 || Number(mov[k].rcodeIn.code) === 0 || Number(mov[k].rcodeOut.code) === 0 || mov[k].addDate === 0) {
                return this.sweetAlert('warning', "รายการย้ายไม่สมบูรณ์ กรุณาทำรายการที่สำนักงานออกหนังสือผ่านแดน")
              } else {
                if (k === mov.length - 1) {
                  if (ccCode.includes(mov[k].rcodeIn.code.toString().substring(0, 2))) {
                    caldate += await this.caldateday(this.today, mov[k].dateMi.toString());
                  } else {
                    break
                  }
                  if (ccCode.includes(mov[k].rcodeOut.code.toString().substring(0, 2))) {
                    caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                  } else {
                    break
                  }
                } else if (k === 0) {
                  if (ccCode.includes(mov[k].rcodeIn.code.toString().substring(0, 2))) {
                    caldate += await this.caldateday(mov[k + 1].oldDateMi.toString(), mov[k].dateMi.toString());
                  } else {
                    break;
                  }
                  if (ccCode.includes(mov[k].rcodeOut.code.toString().substring(0, 2))) {
                    caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                    caldate += await this.caldateday(mov[k].oldDateMi.toString(), pop.dateOfBirth);
                  } else {
                    break;
                  }
                } else {
                  if (ccCode.includes(mov[k].rcodeIn.code.toString().substring(0, 2))) {
                    caldate += await this.caldateday(mov[k + 1].oldDateMi.toString(), mov[k].dateMi.toString());
                  } else {
                    break;
                  }
                  if (ccCode.includes(mov[k].rcodeOut.code.toString().substring(0, 2))) {
                    caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                  } else {
                    break;
                  }
                }
              }
            }
          }
          console.log(caldate)
          if (caldate < 365) {
            if (this.birth === null) {
              this.chkbook = false
              return this.sweetAlert('warning', "ระยะเวลาวันที่พักอาศัยไม่ครบตามที่กำหนดและไม่ได้พบข้อมูลรายการเกิด")
            } else {
              if (this.isBirth === true) {
                if (pop.dateMoveIn != 0) {
                  let dateMiYear = this.calculatorAge(pop.dateMoveIn).year;
                  this.timeLtambon = dateMiYear * 12
                  this.chkbook = true
                } else {
                  this.timeLtambon = this.pop.age * 12
                  this.chkbook = true
                }
              } else {
                this.chkbook = false
                return this.sweetAlert('warning', "ระยะเวลาวันที่พักอาศัยไม่ครบตามที่กำหนดและไม่ได้เกิดในพื้นที")
              }
            }
          } else {
            if (Math.floor(caldate / 365) > this.age) {
              if (Math.floor((caldate - (mov.length+2)) / 365) > this.age) {
                this.chkbook = false
                return this.sweetAlert('warning', "ระยะเวลาที่พักอาศัยไม่ถูกต้อง กรุณาทำรายการที่สำนักงานออกหนังสือผ่านแดน")
              }
            }
            this.chkbook = true
            this.timeLtambon = Math.floor(caldate / 30);
          }
        } else {
          if (mov.length === 1) {
            if ((mov[0].dateMi === 0 && mov[0].oldDateMi === 0) || Number(mov[0].rcodeIn.code) === 0 || Number(mov[0].rcodeOut.code) === 0 || mov[0].addDate === 0) {
              return this.sweetAlert('warning', "รายการย้ายไม่สมบูรณ์ กรุณาทำรายการที่สำนักงานออกหนังสือผ่านแดน")
            } else { 
              if (ccaa.toString().substring(2, 4) === "00") {
                if (mov[0].rcodeIn.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                  caldate += await this.caldateday(this.today, mov[0].dateMi.toString());
                }
                if (mov[0].rcodeOut.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                  caldate += await this.caldateday(mov[0].dateMi.toString(), mov[0].oldDateMi.toString());
                  caldate += await this.caldateday(mov[0].oldDateMi.toString(), pop.dateOfBirth);
                }
              } else {
                if (mov[0].rcodeIn.code.toString() === ccaa.toString()) {
                  caldate += await this.caldateday(this.today, mov[0].dateMi.toString());
                } else if (mov[0].rcodeIn.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                  let resultRcode = await getrcodeAPI(mov[0].rcodeIn.code);
                  if (resultRcode.result.upToDistrictRcode.toString() == ccaa.toString()) {
                    caldate += await this.caldateday(this.today, mov[0].dateMi.toString());
                  }
                }
                if (mov[0].rcodeOut.code.toString() === ccaa.toString()) {
                  caldate += await this.caldateday(mov[0].oldDateMi.toString(), pop.dateOfBirth);
                  caldate += await this.caldateday(mov[0].dateMi.toString(),mov[0].oldDateMi.toString())
                } else {
                  if (mov[0].rcodeOut.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                    let resultRcode = await getrcodeAPI(mov[0].rcodeOut.code);
                    if ((resultRcode.result.upToDistrictRcode.toString() === ccaa.toString())) {
                      caldate += await this.caldateday(mov[0].oldDateMi.toString(), pop.dateOfBirth);
                      caldate += await this.caldateday(mov[0].dateMi.toString(),mov[0].oldDateMi.toString())
                    }
                  }
                }
              }
            }
          } else {
            if (ccaa.toString().substring(2, 4) === "00") {
              for (let k = mov.length - 1; k >= 0; k--) {
                if (mov[k].dateMi === 0 || mov[k].oldDateMi === 0 || Number(mov[k].rcodeIn.code) === 0 || Number(mov[k].rcodeOut.code) === 0 || mov[k].addDate === 0) {
                  this.txterr = "รายการย้ายไม่สมบูรณ์ กรุณาทำรายการที่สำนักงานออกหนังสือผ่านแดน";
                  return
                } else {
                  if (k === mov.length - 1) {
                    if (mov[k].rcodeIn.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                      caldate += await this.caldateday(this.today, mov[k].dateMi.toString());
                    } else {
                      break;
                    }
                    if (mov[k].rcodeOut.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                      caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                    } else {
                      break;
                    } 
                  } else if (k === 0) {
                    if (mov[k].rcodeIn.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                      caldate += await this.caldateday(mov[k + 1].oldDateMi.toString(), mov[k].dateMi.toString());
                    } else {
                      break;
                    }
                    if (mov[k].rcodeOut.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                      caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                      caldate += await this.caldateday(mov[k].oldDateMi.toString(), pop.dateOfBirth);
                    } else {
                      break;
                    }
                  } else {
                    if (mov[k].rcodeIn.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                      caldate += await this.caldateday(mov[k + 1].oldDateMi.toString(), mov[k].dateMi.toString());
                    } else {
                      break;
                    }
                    if (mov[k].rcodeOut.code.substring(0, 2) === ccaa.toString().substring(0, 2)) {
                      caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                    } else {
                      break;
                    }
                  }
                }
              }
            } else {
              for (let k = mov.length - 1; k >= 0; k--) {
                if (mov[k].dateMi === 0 || mov[k].oldDateMi === 0 || Number(mov[k].rcodeIn.code) === 0 || Number(mov[k].rcodeOut.code) === 0 || mov[k].addDate === 0) {
                  this.txterr = "รายการย้ายไม่สมบูรณ์ กรุณาทำรายการที่สำนักงานออกหนังสือผ่านแดน";
                  return
                } else {
                  if (k === mov.length - 1) {
                    if (mov[k].rcodeIn.code.toString() === ccaa.toString()) {
                      caldate += await this.caldateday(this.today, mov[k].dateMi.toString());
                    } else {
                      if (mov[k].rcodeIn.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                        let resultRcode = await getrcodeAPI(mov[k].rcodeIn.code);
                        if (resultRcode.result.upToDistrictRcode.toString() === ccaa.toString()) {
                          caldate += await this.caldateday(this.today, mov[k].dateMi.toString());
                        } else {
                          break;
                        }
                      } else {
                        break;
                      }
                    }
                    if (mov[k].rcodeOut.code.toString() === ccaa.toString()) {
                      caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                    } else {
                      if (mov[k].rcodeOut.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                        let resultRcode = await getrcodeAPI(mov[k].rcodeOut.code);
                        if (resultRcode.result.upToDistrictRcode.toString() === ccaa.toString()) {
                          caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                        } else {
                          break;
                        }
                      } else {
                        break;
                      }
                    }
                  } else if (k === 0) {
                    if (mov[k].rcodeIn.code.toString() === ccaa.toString()) {
                      caldate += await this.caldateday(mov[k + 1].oldDateMi.toString(), mov[k].dateMi.toString());
                    } else {
                      if (mov[k].rcodeIn.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                        let resultRcode = await getrcodeAPI(mov[k].rcodeIn.code);
                        if (resultRcode.result.upToDistrictRcode.toString() === ccaa.toString()) {
                          caldate += await this.caldateday(mov[k + 1].oldDateMi.toString(), mov[k].dateMi.toString());
                        } else {
                          break;
                        }
                      } else {
                        break;
                      }
                    }
                    if (mov[k].rcodeOut.code.toString() === ccaa.toString()) {
                      caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                      caldate += await this.caldateday(mov[k].oldDateMi.toString(), pop.dateOfBirth);
                    } else {
                      if (mov[k].rcodeOut.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                        let resultRcode = await getrcodeAPI(mov[k].rcodeOut.code);
                        if ((resultRcode.result.upToDistrictRcode.toString() === ccaa.toString())) {
                          caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                          caldate += await this.caldateday(mov[k].oldDateMi.toString(), pop.dateOfBirth);
                        } else {
                          break;
                        }
                      } else {
                        break;
                      }  
                    }
                  } else {
                    if (mov[k].rcodeIn.code.toString() === ccaa.toString()) {
                      caldate += await this.caldateday(mov[k + 1].oldDateMi.toString(), mov[k].dateMi.toString());
                    } else {
                      if (mov[k].rcodeIn.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                        let resultRcode = await getrcodeAPI(mov[k].rcodeIn.code);
                        if (resultRcode.result.upToDistrictRcode.toString() === ccaa.toString()) {
                          caldate += await this.caldateday(mov[k + 1].oldDateMi.toString(), mov[k].dateMi.toString());
                        } else {
                          break;
                        }
                      } else {
                        break;
                      }
                    }
                    if (mov[k].rcodeOut.code.toString() === ccaa.toString()) {
                      caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                    } else {
                      if (mov[k].rcodeOut.code.toString().substring(0, 2) === ccaa.toString().substring(0, 2)) {
                        let resultRcode = await getrcodeAPI(mov[k].rcodeOut.code);
                        if (resultRcode.result.upToDistrictRcode.toString() == ccaa.toString()) {
                          caldate += await this.caldateday(mov[k].dateMi.toString(), mov[k].oldDateMi.toString());
                        } else {
                          break;
                        }
                      } else {
                        break;
                      } 
                    }
                  }
                }
              }
            }
          }
          if (caldate < 180) {
            return this.sweetAlert('warning', "ไม่สามารถดำเนินการได้เนื่องจาก อยู่ในพื้นที่น้อยกว่า 180 วัน")
          } else {
            if (Math.floor(caldate / 365) > this.age) {
              if (Math.floor((caldate - (mov.length+2)) / 365) > this.age) {
                this.chkbook = false
                return this.sweetAlert('warning', "ระยะเวลาที่พักอาศัยไม่ถูกต้อง กรุณาทำรายการที่สำนักงานออกหนังสือผ่านแดน")
              }
            }
            
            this.timeLtambon = Math.floor(caldate / 30);
            this.chkbook = true
          }
        }
      } else {
        if (pop.dateMoveIn !== 0) { 
          let dateMiYear = this.calculatorAge(pop.dateMoveIn).year;
          this.timeLtambon = dateMiYear * 12
          this.chkbook = true;
        } else {
          this.timeLtambon = this.age * 12;
          this.chkbook = true;
        }
      }
    },
    async caldateday(date2, date1) {
      date1 = `${date1}`;
      date2 = `${date2}`;
      let dt1 = new Date(
        Number(date1.substring(0, 4)),
        Number(date1.substring(4, 6)),
        Number(date1.substring(6, 8))
      );
      let dt2 = new Date(
        Number(date2.substring(0, 4)),
        Number(date2.substring(4, 6)),
        Number(date2.substring(6, 8))
      );
      let diff = (dt2.getTime() - dt1.getTime()) / (1000 * 3600 * 24);
      return diff;
    },
    moo(value) {
      const muu = value % 100;
      if (muu === 0) {
        return "";
      }
      return muu;
    },
    TotalAddress(hno, muu, trok, soi, thanon) {
      let honum = [null, ''].includes(hno) ? '' : hno
      let moou = [null, ''].includes(muu) ? '' : muu
      let Ttrok = [null, ''].includes(trok) ? '' : trok
      let Ssoi = [null, ''].includes(soi) ? '' : soi
      let Tthanon = [null, ''].includes(thanon) ? '' : thanon
      return `${honum}#${moou}#${Ttrok}#${Ssoi}#${Tthanon}`;
    },
    addStringccaattmm(a, b, c) {
      let aa = [null, ''].includes(a) ? '' : a
      let bb = [null, ''].includes(b) ? '' : b
      let cc = [null, ''].includes(c) ? '' : c
      return `${aa}#${bb}#${cc}`;
    },
    sweetAlert(type, msg) {
      return Swal.fire({
        icon: type,
        title: 'แจ้งเตือน',
        allowOutsideClick: false,
        text: msg,
        confirmButtonText: 'ตกลง',
      })
    },
    async provinceplace() {
      let list
      this.Bptype = null
      this.Border = null
      this.Bordername = []
      if (this.Country.value === 48) {
        list = borderlist.Myanmar();
        this.itemBptype = this.Bptypeitem;
        this.chckeddis1 = false;
      } else if (this.Country.value === 50) {
        list = borderlist.Malaysia();
        this.itemBptype = this.Bptypeitem1;
        this.Bptype = { text: "หนังสือผ่านเเดน(เเบบเล่ม)", value: 2 };
        this.cost = 200;
        this.chckeddis1 = false;
      } else if (this.Country.value === 56) {
        list = borderlist.Laos();
        this.itemBptype = this.Bptypeitem;
        this.chckeddis1 = false;
      } else if (this.Country.value === 57) {
        list = borderlist.Cambodia();
        this.itemBptype = this.Bptypeitem1;
        this.Bptype = { text: "หนังสือผ่านเเดน(เเบบเล่ม)", value: 2 };
        this.cost = 200;
        this.chckeddis1 = false;
      }
      list.forEach((element) => {
        this.Bordername.push(element);
      });
    },
  },
};
</script>

<style scoped src="@/assets/styles/dbt-style.css"></style>
