  import Vue from 'vue'
  import JsBarcode from 'jsbarcode';
  import krungthai from '../../assets/icons/2.jpg'

  const convertTextToBase64Barcode = (text) => {
    let canvas = document.createElement('canvas');
    JsBarcode(canvas, text, { format: 'CODE128', width: 3, fontSize: 40 , font: 'THSarabunNew', displayValue: false});
    return canvas.toDataURL('image/png');
  }

  const calRef2 = (data) => {
    let dataref2 =  data.Bptype === 1 ? `${(data.ccc)}${data.ccaa}670021` : `${(data.ccc)}${data.ccaa}670022`
    const myArray = dataref2.split("");
    const map1 = myArray.map(x => Number(x));
    const total = map1.reduce((acc, curr) => {
      return acc + curr
    }, 0)
    const myArray1 = total.toString().split("");
    const map2 = myArray1.map(x => Number(x));
    const total1 = map2.reduce((acc, curr) => {
      return acc + curr
    }, 0)
    let refdata =  `${dataref2}${total1.toString().padStart(2, '0')}`
    return refdata
  }

  const pay = (date) => {
    let Ddate = date.toString()
    let day = Ddate.substring(8, 6)
    let mounth = Ddate.substring(6, 4)
    let year = Ddate.substring(4, 0)
    const new_date = new Date(year, mounth, day);
    new_date.setDate(new_date.getDate() + 5); 
    let payday = fulldate(Number(`${new_date.getFullYear()}${new_date.getMonth().toString().padStart(2, '0')}${new_date.getDate().toString().padStart(2, '0')}`))
    return payday
  }

  const fulldate = (day) => {
    return Vue.dateFormat(day).toStringType1ThaiFull()
  }

  const payslip = async (billdata, fullname, reqId, reqYear, register, phone) => {
    const layout = {
      content: [
        {
          style: "content",
          table: {
            widths: ["100%"],
            body: [
            [
              {
                stack: [
                  {
                    text: "ใบเเจ้งการชำระเงิน",
                    fontSize: 16,
                    alignment: "right",
                    bold: true,
                    margin: [0, 10, 20, 0]
                  },
                  {
                    text: "สำหรับลูกค้า",
                    fontSize: 12,
                    alignment: "right",
                    bold: true,
                    margin: [0, 0, 40, 10]
                  }
                ]
              },
            ],
            [
              {
                style: "content",
                table: {
                  widths: ["40%", "10%", "50%"],
                  body: [
                    [
                      {
                        colSpan: 2,
                        stack: [
                          {
                            text: "สำนักกิจการความมั่นคงภายใน",
                            fontSize: 12,
                            alignment: "left",
                            bold: true,
                          },
                          {
                            text: "กรมการปกครอง",
                            fontSize: 12,
                            alignment: "left",
                            margin: [0, 5, 0, 0],
                            bold: true,
                          },
                          {
                            columns: [
                              {
                                qr: `|${billdata.taxid}02\r${billdata.ccaa}${billdata.Bordercode.padStart(3, '0')}${reqYear.substring(4, 2)}${reqId}\r${calRef2(billdata)}\r${billdata.Cost}00`,
                                fit: '66',
                                absolutePosition: {x: 195, y: 95 }
                              },
                            ]
                          }
                        ]
                      },
                      {},
                      {
                        stack: [
                          {
                            text: `วันที่ / Date: ${fulldate(register.Registerdate)}`,
                            bold: true,
                          },
                          {
                            style: "content",
                            margin: [0, 5, 0, 5],
                            table: {
                              widths: ["100%"],
                              body: [
                                [
                                  {
                                    stack: [
                                      {
                                        text: `ชื่อลูกค้า (Name) ${fullname}`,
                                        bold: true,
                                      },
                                      {
                                        text: `รหัสลูกค้า (Ref. 1) ${billdata.ccaa}${billdata.Bordercode.padStart(3, '0')}${reqYear.substring(4, 2)}${reqId}`,
                                        bold: true,
                                      },
                                      {
                                        text: `บิลเลขที่ (Ref. 2) ${calRef2(billdata)}`,
                                        bold: true,
                                      },
                                      {
                                        text: `Tel ${phone}`,
                                        bold: true,
                                      },
                                    ],
                                  },
                                ]
                              ]
                            }
                          }
                        ]
                      },
                    ],
                    [
                      {
                        stack: [
                          {
                            text: `เพื่อเข้าบัญชี ${billdata.name}`,
                            fontSize: 12,
                            alignment: "left",
                            bold: true,
                          },
                          {
                            columns: [
                              {
                                image: krungthai
                              },
                              {
                                text: `บมจ.ธนาคารกรุงไทย (Comp Code ${billdata.compcode})`,
                                alignment: "left",
                                absolutePosition: {x: 60, y: 188 }
                              }
                            ]
                          }
                        ]
                      },
                      { 
                        image: convertTextToBase64Barcode(`|${billdata.taxid}02\r${billdata.ccaa}${billdata.Bordercode.padStart(3, '0')}${reqYear.substring(4, 2)}${reqId}\r${calRef2(billdata)}\r${billdata.Cost}00`),
                        alignment: "right",
                        width: 340,
                        height: 43,
                        colSpan: 2,
                      },
                      {},
                    ],
                    [
                      {},
                      {
                        text: `|${billdata.taxid}02 ${billdata.ccaa}${billdata.Bordercode.padStart(3, '0')}${reqYear.substring(4, 2)}${reqId} ${calRef2(billdata)} ${billdata.Cost}00`,
                        fontSize: 12,
                        absolutePosition: {x: 285, y: 205},
                        colSpan: 2,
                      },
                      {}
                    ]
                  ]
                },
                layout: {
                  defaultBorder: false,
                },
              },
            ],
          ]
        }
      },
      {
        style: "content",
        table: {
          widths: ["30%", "70%"],
          body: [
            [
              {
                text: "จำนวนเงิน(บาท)",
                alignment: "center",
                border: [true, false, true, true]
              },
              {
                text: `${billdata.Cost}.00`,
                alignment: "center",
                border: [true, false, true, true]
              },
            ],
            [
              {
                text: "จำนวนเงินที่เป็นตัวอักษร",
                alignment: "center",
                border: [true, true, true, false],
              },
              {
                text: billdata.Bptype === 1 ? 'สามสิบบาทถ้วน' : 'สองร้อยบาทถ้วน',
                alignment: "center",
                border: [true, true, true, false],
              },
            ]
          ]
        }
      },
      {
        style: "content",
        table: {
          widths: ["100%"],
          body: [
            [
              {
                stack: [
                  {
                    text: `เพื่อความสะดวกของท่าน กรุณานำใบเเจ้งการชำระเงินไปชำระได้ที่ธนาคารที่ระบุ ทุกสาขาทั่วประเทศ ภายในวันที่ ${pay(register.Traveldate)}`,
                    fontSize: 12,
                    alignment: "center",
                    margin: [0, 5, 0, 0]
                  },
                  {
                    text: "กรุณาตัดตามรอยปรุ",
                    fontSize: 10,
                    absolutePosition: {x: 270, y: 283 }
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 0, y1: 7,
                        x2: 547, y2: 7,
                        dash: {length: 1},
                        lineWidth: 1,
                      },
                    ],
                    margin: [0, 0, 0, 30]
                  }
                ],
                border: [true, true, true, false],
              },
            ]
          ]
        }
      },
      {
        style: "content",
        table: {
          widths: ["100%"],
            body: [
            [
              {
                stack: [
                  {
                    text: "ใบเเจ้งการชำระเงิน",
                    fontSize: 16,
                    alignment: "right",
                    bold: true,
                    margin: [0, 10, 20, 0]
                  },
                  {
                    text: "สำหรับธนาคาร",
                    fontSize: 12,
                    alignment: "right",
                    bold: true,
                    margin: [0, 0, 40, 10]
                  }
                ]
              },
            ],
            [
              {
                style: "content",
                table: {
                  widths: ["40%", "10%", "50%"],
                  body: [
                    [
                      {
                        colSpan: 2,
                        stack: [
                          {
                            text: "สำนักกิจการความมั่นคงภายใน",
                            fontSize: 12,
                            alignment: "left",
                            bold: true,
                          },
                          {
                            text: "กรมการปกครอง",
                            fontSize: 12,
                            alignment: "left",
                            margin: [0, 5, 0, 0],
                            bold: true,
                          },
                          {
                            columns: [
                              {
                                qr: `|${billdata.taxid}02\r${billdata.ccaa}${billdata.Bordercode.padStart(3, '0')}${reqYear.substring(4, 2)}${reqId}\r${calRef2(billdata)}\r${billdata.Cost}00`,
                                fit: '66',
                                absolutePosition: {x: 195, y: 397 }
                              },
                            ]
                          }
                        ]
                      },
                      {},
                      {
                        stack: [
                          {
                            text: `วันที่ / Date: ${fulldate(register.Registerdate)}`,
                            bold: true,
                          },
                          {
                            style: "content",
                            margin: [0, 5, 0, 5],
                            table: {
                              widths: ["100%"],
                              body: [
                                [
                                  {
                                    stack: [
                                      {
                                        text: `ชื่อลูกค้า (Name) ${fullname}`,
                                        bold: true,
                                      },
                                      {
                                        text: `รหัสลูกค้า (Ref. 1) ${billdata.ccaa}${billdata.Bordercode.padStart(3, '0')}${reqYear.substring(4, 2)}${reqId}`,
                                        bold: true,
                                      },
                                      {
                                        text: `บิลเลขที่ (Ref. 2) ${calRef2(billdata)}`,
                                        bold: true,
                                      },
                                      {
                                        text: `Tel ${phone}`,
                                        bold: true,
                                      },
                                    ],
                                  },
                                ]
                              ]
                            }
                          }
                        ]
                      },
                    ],
                    [
                      {
                        stack: [
                          {
                            text: `เพื่อเข้าบัญชี ${billdata.name}`,
                            fontSize: 12,
                            alignment: "left",
                            bold: true,
                          },
                          {
                            columns: [
                              {
                                image: krungthai
                              },
                              {
                                text: `บมจ.ธนาคารกรุงไทย (Comp Code ${billdata.compcode})`,
                                alignment: "left",
                                absolutePosition: {x: 60, y: 490 }
                              }
                            ]
                          }
                        ]
                      },
                      {
                        image: convertTextToBase64Barcode(`|${billdata.taxid}02\r${billdata.ccaa}${billdata.Bordercode.padStart(3, '0')}${reqYear.substring(4, 2)}${reqId}\r${calRef2(billdata)}\r${billdata.Cost}00`),
                        alignment: "right",
                        width: 340,
                        height: 43,
                        colSpan: 2,
                      },
                      {},
                    ],
                    [
                      {},
                      {
                        text: `|${billdata.taxid}02 ${billdata.ccaa}${billdata.Bordercode.padStart(3, '0')}${reqYear.substring(4, 2)}${reqId} ${calRef2(billdata)} ${billdata.Cost}00`,
                        fontSize: 12,
                        absolutePosition: {x: 285, y: 508},
                        colSpan: 2,
                      },
                      {}
                    ]
                  ]
                },
                layout: {
                  defaultBorder: false,
                },
              },
            ],
          ]
        }
      },
      {
        style: "content",
        table: {
          widths: ["30%", "70%"],
          body: [
            [
              {
                text: "จำนวนเงิน(บาท)",
                alignment: "center",
                border: [true, false, true, true]
              },
              {
                text: `${billdata.Cost}.00`,
                alignment: "center",
                border: [true, false, true, true]
              },
            ],
            [
              {
                text: "จำนวนเงินที่เป็นตัวอักษร",
                alignment: "center",
                border: [true, true, true, false],
                
              },
              {
                text: billdata.Bptype === 1 ? 'สามสิบบาทถ้วน' : 'สองร้อยบาทถ้วน',
                alignment: "center",
                border: [true, true, true, false],
              },
            ]
          ]
        }
      },
      {
        style: "content",
        table: {
          widths: ["100%"],
          body: [
            [
              {
                stack: [
                  {
                    text: `เพื่อความสะดวกของท่าน กรุณานำใบเเจ้งการชำระเงินไปชำระได้ที่ธนาคารที่ระบุ ทุกสาขาทั่วประเทศ ภายในวันที่ ${pay(register.Traveldate)}`,
                    fontSize: 12,
                    alignment: "center",
                    margin: [0, 5, 0, 0]
                  },
                  {
                    text: "กรุณาตัดตามรอยปรุ",
                    fontSize: 10,
                    absolutePosition: {x: 270, y: 586 }
                  },
                  {
                    canvas: [
                      {
                        type: 'line',
                        x1: 0, y1: 7,
                        x2: 547, y2: 7,
                        dash: {length: 1},
                        lineWidth: 1,
                      },
                    ],
                    margin: [0, 0, 0, 30]
                  }
                ]
              },
            ]
          ]
        }
      },
      ],
      styles: {
          contenttable: {
            alignment: 'center',
            margin: [10, 10, 10, 10]
          }
        }
      }
    Vue.pdfMake.createPdf(layout).download('payslip')
}
export default payslip
