<template>
  <div class="dbt-content">
    <v-row>
      <v-col md="10" sm="12" offset-md="1">
        <v-row>
          <v-col sm="12">
            <v-card elevation="2" class="dbt-content-card">
              <div class="title">
                <i class="ri-user-search-fill"></i>
                <label
                  style="font-size: 4vw; font-size: 18px; padding-left: 4px"
                >
                  ตรวจสอบรายการที่ลงทะเบียน
                </label>
              </div>
              <div class="context">
                <v-row>
                  <v-col cols="12">
                    <div class="context">
                      <v-row>
                        <v-layout xs12 sm12 md12 pt-2 pl-2>
                          <v-flex xs12 sm4 md1 lg1>
                            <v-badge left color="#7DD5FF">
                              <label>รอยืนยัน</label>
                            </v-badge>
                          </v-flex>
                          <v-flex xs12 sm4 md1 lg1>
                            <v-badge left color="#A5D6A7">
                              <label>ยืนยันแล้ว</label>
                            </v-badge>
                          </v-flex>
                          <v-flex xs12 sm4 md1 lg1>
                            <v-badge left color="#FF8A80">
                              <label>ยกเลิก</label>
                            </v-badge>
                          </v-flex>
                          <v-flex xs12 sm4 md1 lg1>
                            <v-badge left color="#7979d2">
                              <label>หมดอายุ</label>
                            </v-badge>
                          </v-flex>
                        </v-layout>
                      </v-row>
                      <v-row>
                        <v-container fluid grid-list-md>
                          <v-layout v-if="showTable" wrap>
                            <v-flex
                              xs12
                              sm6
                              md4
                              v-for="i in dataReg"
                              :key="`${i.reqId}${i.reqYear}`"
                              style="padding: 8px"
                            >
                              <!-- <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }"> v-bind="attrs" v-on="on"-->
                              <v-card
                                ripple
                                @click="toDetail(i)"
                                :color="getColor(i)"
                                hover
                              >
                                <v-container fluid grid-list-md>
                                  <v-layout justify-center align-center wrap>
                                    <v-flex
                                      xs12
                                      sm12
                                      md12
                                      lg12
                                      pl-2
                                      subheading
                                      font-weight-medium
                                      class="blue-grey lighten-5 primary--text"
                                    >
                                      <i class="ri-information-line"></i>
                                      <label style="padding-left: 5px"
                                        >คำร้อง {{ i.reqId }}/{{
                                          i.reqYear
                                        }}</label
                                      >
                                    </v-flex>
                                    <v-flex xs12 pl-2>
                                      <v-divider />
                                      <v-layout wrap>
                                        <v-flex xs12 sm12 md12 lg12 body-1>
                                          <i class="ri-car-line"></i>
                                          <label>
                                            {{ formatDate(i.travelDate) }}
                                            <v-badge
                                              right
                                              :color="getColor(i)"
                                            ></v-badge>
                                          </label>
                                        </v-flex>
                                        <v-flex xs12 sm12 md12 lg12>
                                          <label style="font-size: 15px">
                                            {{ i.station }}
                                          </label>
                                        </v-flex>
                                      </v-layout>
                                      <!-- <v-layout row>
                                          <v-flex xs12 sm6 md11 lg11 body-1>
                                            <label>
                                              {{ i.station }}
                                              <v-badge right :color="getColor(i.status)"></v-badge>
                                            </label>
                                          </v-flex>
                                        </v-layout> -->
                                    </v-flex>
                                  </v-layout>
                                </v-container>
                              </v-card>
                              <!-- </template>
                                  <span>{{ i.issueDate }}</span>
                                </v-tooltip> -->
                            </v-flex>
                          </v-layout>
                          <v-layout row justify-center align-center v-if="txtResponse">
                            <center><h1 style="color: red;">{{ txtResponse }}</h1></center>
                          </v-layout>
                        </v-container>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Dialog :alertDialog="alertDialog" :waitDialog="waitDialog" />
  </div>
</template>
<script>
import { getRegisterByPid } from "@/api/register";
import { putPayment } from "@/api/payment";
import mapList from "@/js/borderlist";
import { get, call, sync } from "vuex-pathify";
import Dialog from "@/components/ui/dialog/Dialog";
import Swal from "sweetalert2";

export default {
  name: "Verify",
  props: {
    returnDetail: {
      type: Object,
      default: () => {
        return null;
      },
    },
    flagBack: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  components: {
    Dialog,
  },
  data() {
    return {
      alertDialog: {
        val: false,
        msg: "",
        type: "",
      },
      waitDialog: false,
      showTable: false, // TODO: set true for dev
      disBTN: true,
      filterTable: "",
      body: [
        {
          req: 110,
          station: "สนง.ออกหนังสือผ่านแดน อ.แม่สาย",
          fullName: "นายทดสอบ ระบบ",
          travelDate: "25640729",
          status: 2,
          statusDesc: "ยกเลิก",
        },
        {
          req: 114,
          station: "ที่ทำการปกครองจังหวัดสงขลา",
          fullName: "นางสาวลอง ลงทะเบียน",
          travelDate: "25640628",
          status: 1,
          statusDesc: "ยืนยันแล้ว",
        },
        {
          req: 215,
          station: "ที่ทำการปกครองจังหวัดอุบลราชธานี",
          fullName: "นางรอคิว นานแล้ว",
          travelDate: "25640602",
          status: 0,
          statusDesc: "รอยืนยัน",
        },
        {
          req: 302,
          station: "ที่ทำการปกครองจังหวัดจันทบุรี",
          fullName: "นายเดินทาง ไกลแสนไกล",
          travelDate: "25640701",
          status: 1,
          statusDesc: "ยืนยันแล้ว",
        },
      ],
      dataReg: [],
      e1: null,
      headers: this.headerTable(),
      pagination: {
        sortBy: "sequence",
        page: 1,
      },
      rowsPerPageItem: [
        { text: "5 รายการ", value: 5 },
        { text: "10 รายการ", value: 10 },
        { text: "25 รายการ", value: 25 },
        { text: "ทั้งหมด", value: -1 },
      ],
      windowWidth: this.$vuetify.breakpoint.width,
      txtResponse: "",
    };
  },
  watch: {
    // returnDetail: {
    //   immediate: true,
    //   deep: true,
    //   async handler(val) {
    //     const dataRow = this.dataReg.find((element) => { return element.reqId === val.reqId && element.reqYear === val.reqYear })
    //     if (dataRow.status !== val.status) await this.callVerifyReq()
    //     else this.dataReg = this.getVerify
    //   },
    // },
  },
  async beforeMount() {},
  async mounted() {
    await this.callVerifyReq();
    // try {
    //   const testCallApi = await getLogin();
    //   console.log(testCallApi);
    // } catch (err) {
    //   console.log(err.message);
    // }
    // this.waitDialog = true
    // console.log('returnDetail = ', this.returnDetail)
    // if (!this.flagBack && this.getVerify.length === 0) await this.callVerifyReq()
    // else if (!this.flagBack && this.getVerify.length > 0) this.dataReg = this.getVerify
    // else {
    //   const dataRow = this.dataReg.find((element) => { return (element.reqId === this.returnDetail.reqId) && (element.reqYear === this.returnDetail.reqYear) })
    //   if (dataRow.status !== this.returnDetail.status) await this.callVerifyReq()
    //   else this.dataReg = this.getVerify
    // }
    // this.waitToShow()
  },
  computed: {
    getVerify: get("verify/verify"),
    login: get("login/login"),
    ispay: sync("ispay/ispay"),
  },
  methods: {
    setVerify: call("verify/setVerify"),
    compare(a, b) {
      if (
        Number(`${a.reqYear}${a.reqId.toString().padStart(7, "0")}`) <
        Number(`${b.reqYear}${b.reqId.toString().padStart(7, "0")}`)
      ) {
        return 1;
      }
      if (
        Number(`${a.reqYear}${a.reqId.toString().padStart(7, "0")}`) >
        Number(`${b.reqYear}${b.reqId.toString().padStart(7, "0")}`)
      ) {
        return -1;
      }
      return 0;
    },
    async callVerifyReq() {
      // console.log("loginV", this.login);
      try {
        this.waitDialog = true;
        const register = await getRegisterByPid(this.login.userInfo.pid);
        // TODO: prod. this.login.userInfo.pid
        // TODO: dev 3730100699187
        if (register.error) return this.showTxtResponse("ไม่พบข้อมูล") // this.sweetAlert("warning", register.error);
        // const fat = father.result.filter(word => word.age < 12);
        this.dataReg = register.result;

        for (let i = 0; i < this.dataReg.length; i++) {
          this.dataReg[i].station = mapList.maplistplace(
            Number(
              `${this.dataReg[i].ccaa}${this.dataReg[i].borderCode
                .toString()
                .padStart(3, "0")}`
            )
          );
          this.dataReg[i].qrBar = `${this.dataReg[i].reqId
            .toString()
            .padStart(7, "0")}${this.dataReg[i].reqYear}`; // TODO: 7 หลัก reqId + 4 หลัก reqYear = 11 หลัก qr, bar
        }
        this.dataReg.sort(this.compare);
        // this.setVerify(this.dataReg)
        this.waitToShow();
        // console.log('dataReg = ', this.dataReg)
      } catch (err) {
        return this.showTxtResponse(err.message); // this.sweetAlert("error", err.message);
      }
    },
    showTxtResponse(txt) {
      this.waitDialog = false;
      this.txtResponse = txt;
    },
    waitToShow() {
      this.showTable = true;
      this.waitDialog = false;
    },
    async toDetail(v) {
      try {
        this.waitDialog = true;
        const { result, error } = await putPayment({ travelDate: v.travelDate })
        console.log(result)
        if (error) return this.sweetAlert('warning', error)
        this.ispay = result.isPay
        this.waitDialog = false;
        this.$router.push({
          name: 'qrcode',
          params: { dataDetail: v, flagPage: true },
        })
        .catch(() => {})
      } catch(e) {
        return this.sweetAlert('error', e.message)
      }
    },
    getColor(v) {
      const value = v.status
      const tDate = v.travelDate
      let color = ''
      let exDate = null
      if (tDate) {
        //console.log('tDate = ', tDate)
        const y = tDate.toString().substr(0, 4)
        const m = tDate.toString().substr(4, 2)
        const d = tDate.toString().substr(6, 2)
        const tmpTDate = new Date(Number(y - 543), Number(m - 1), d)
        //console.log('tmpTDate = ', tmpTDate)
        const currentDate = new Date()
        //console.log('currentDate = ', currentDate)
        const tmpDate = Math.floor((currentDate.getTime() - tmpTDate.getTime()) / (24 * 3600 * 1000))
        exDate = tmpDate
        //console.log('exDate = ', exDate)
      }

      // if (value === 1) return "#467645";
      if (value === 1) color = "#A5D6A7";
      //else if (value === 2) return "#800000";
      else if (value === 2) color = "#FF8A80";
      // รอยืนยัน = 0
      else if (value === 0) {
        color = '#7DD5FF'
        v.flagExpire = false
        if (exDate > 0) {
          color = '#7979d2'
          v.flagExpire = true
        }
      }
      return color
    },
    sweetAlert(type, msg) {
      this.waitDialog = false;
      return Swal.fire({
        icon: type,
        title: "แจ้งเตือน",
        text: msg,
        confirmButtonText: "ตกลง",
      });
    },
    getBG(no) {
      if (no % 2 === 0) return "#FAFAFA";
      else return "#ffffff";
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    callDialog(type = undefined, msg = undefined) {
      this.waitDialog = false;
      this.alertDialog.msg = msg;
      this.alertDialog.type = `headline white--text ${type} lighten-1`;
      this.alertDialog.val = true;
    },
    formatDate(date) {
      return this.dateFormat(date).toStringType1ThaiFull();
    },
    headerTable() {
      return [
        {
          key: 1,
          text: "ลำดับ",
          value: "no",
          rowSpan: 1,
          colSpan: 1,
          sortable: false,
        },
        // {
        //   key: 2,
        //   text: 'เลขที่ลงทะเบียน',
        //   value: 'req',
        //   rowSpan: 1,
        //   colSpan: 1,
        //   sortable: true,
        // },
        {
          key: 2,
          text: "ชื่อด่าน",
          value: "station",
          rowSpan: 1,
          colSpan: 1,
          sortable: true,
        },
        // {
        //   key: 4,
        //   text: 'วันที่นัดทำรายการที่ด่าน',
        //   value: 'issueDate',
        //   rowSpan: 1,
        //   colSpan: 1,
        //   sortable: true,
        // },
        // {
        //   key: 3,
        //   text: 'สถานะ',
        //   width: '100px',
        //   value: 'status',
        //   rowSpan: 1,
        //   colSpan: 1,
        //   sortable: true,
        // },
      ];
    },
  },
};
</script>
<style scoped src="@/assets/styles/dbt-style.css"></style>
