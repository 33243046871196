<template>
  <v-app class="padding mobile-ui">
    <v-main class="padding">
      <section class="bg-ui">
        <div class="menu-bg" :class="{ 'no-fixed': list.lenght == 0 }">
          <div class="dbt-title" v-html="selectedTopic"></div>
          <span class="versionLabel">v.{{ version }}</span>
          <span class="logout" @click="authLogout" v-if="isLogin5Feild"
            >ลงชื่อออก<i class="ri-logout-box-r-line"></i
          ></span>
          <MenuPanel
            class="menuVerPc"
            :menulist="list"
            :btnExpand="false"
            :tooltip="true"
            :btnNext="true"
            @selected="getSel"
            ref="menu"
          ></MenuPanel>

          <!---
        menulist  : list menu
        btnExpand : show ปุ่ม แสดงเมนูทั้งหมด //default = false (ไม่จำเป็นต้องใส่ props)
        tooltip   : set ให้แสดง tooptips   //default = false (ไม่จำเป็นต้องใส่ props)
        btnNext   : set ให้แสดง ปุ่ม next , prev //default = true (ไม่จำเป็นต้องใส่ props)
        @selected : event เมื่อ คลิ๊ก return object menulist และ index ที่เลือก
      -->
        </div>
        <router-view />
      </section>
    </v-main>
    <Login5Feild ref="login5Feild"></Login5Feild>
    <Dialog :alertDialog="alertDialog" :waitDialog="waitDialog" />
  </v-app>
</template>

<script>
import MenuPanel from "./components/ui/menuPanel.vue";
import { postLogin, delLogin5Feild } from "@/api/auth";
import Login5Feild from "@/components/ui/verify/login5Feild";
import Dialog from "@/components/ui/dialog/Dialog";
import { get } from "vuex-pathify";
import Swal from "sweetalert2";
import login5FeildVue from "./components/ui/verify/login5Feild.vue";

export default {
  components: {
    MenuPanel,
    Login5Feild,
    Dialog,
  },
  name: "App",
  data() {
    return {
      alertDialog: {
        val: false,
        msg: "",
        type: "",
      },
      version: process.env.VUE_APP_VERSION,
      scroll: 0,
      selectedTopic: "ขอหนังสือ",
      waitDialog: false,
      datalogin: null,
      list: [
        /* prop ที่ส่งให้ component */
        {
          customIcon: "<i class='ri-user-add-fill'></i>",
          text: "ขอหนังสือ",
          path: "/register",
        },
        {
          customIcon: "<i class='ri-user-search-fill'></i>",
          text: "ตรวจสอบ",
          path: "/Verify",
        },
        {
          customIcon: "<i class='ri-account-box-fill'></i>",
          text: "ข้อมูลหนังสือผ่านแดน",
          path: "/Detail",
        },
        {
          customIcon: "<i class='ri-draft-fill'></i>",
          text: "คู่มือ",
          path: "/Manual",
        },
      ],
      isLogin5Feild: false,
    };
  },
  mounted() {
    this.authLogin();
  },
  computed: {
    login: get("login/login"),
  },
  methods: {
    async authLogout() {
      this.$refs.login5Feild.deleteData();
      this.isLogin5Feild = false;
      this.waitDialog = true;
      let result = await delLogin5Feild();
      if (!result.error) {
        this.datalogin = await this.$refs.login5Feild.show();
        this.$store.set("login/login", this.datalogin.result.data);
        this.isLogin5Feild = true;
        this.$router.push("/register").catch(() => {});
        this.waitDialog = false;
      }
      this.waitDialog = false;
    },
    async authLogin() {
      try {
        this.waitDialog = true;
        let result = await postLogin();
        if (result.data) {
          console.log("post login result");
          console.log(result);
          console.log("post login result.data");
          console.log(result.data);
          console.log("set store login");
          this.$store.set("login/login", result.data);
          if (result.data.userInfo.login_type == 0) {
            this.isLogin5Feild = true;
          } else {
            this.isLogin5Feild = false;
          }
          this.$router.push("/register").catch(() => {});
          this.waitDialog = false;
        } else {
          this.datalogin = await this.$refs.login5Feild.show();
          this.$store.set("login/login", this.datalogin.result.data);
          this.isLogin5Feild = true;
          this.$router.push("/register").catch(() => {});
          this.waitDialog = false;
        }
        this.selectedTopic = "ขอหนังสือ";
      } catch (err) {
        return this.showTxtResponse(err.message); // this.sweetAlert("error", err.message);
      }
    },
    showTxtResponse(txt) {
      this.waitDialog = false;
      this.txtResponse = txt;
    },
    changePage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    callDialog(type = undefined, msg = undefined) {
      this.waitDialog = false;
      this.alertDialog.msg = msg;
      this.alertDialog.type = `headline white--text ${type} lighten-1`;
      this.alertDialog.val = true;
    },
    getSel(compEmitData) {
      this.selectedTopic = compEmitData.text;
      this.changePage(compEmitData.path);
    },
  },
};
</script>

<style>
@import url("./assets/styles/main.css");
.padding {
  padding: 0px !important;
  margin: 0px;
  max-height: 100vh;
  overflow: hidden;
}
@media only screen and (max-width: 600px) {
  .padding {
    padding: 0px !important;
    margin: 0px;
    max-height: 100vh;
    overflow: hidden;
  }

  .container {
    padding: 0px;
  }

  body {
    background-image: url("./assets/cut.png");
  }
}
</style>
<style scoped src="@/assets/styles/dbt-style.css"></style>
