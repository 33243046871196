<template>
  <div>
    <register />
  </div>
</template>

<script>
import register from "../components/ui/register/mainregister.vue";

export default {
  components: {
    register,
  },
  name: "Register",
  data() {
    return {};
  },
};
</script>

<style scoped src="@/assets/styles/dbt-style.css"></style>
