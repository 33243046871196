<template>
  <div class="dbt-content">
    <v-row>
      <v-col md="10" sm="12" offset-md="1">
        <v-row>
          <v-col sm="12">
            <v-card elevation="2" class="dbt-content-card">
              <div class="title">
                <i class="ri-account-box-fill"></i>
                ข้อมูลหนังสือผ่านแดน
              </div>
              <div class="context">
                <v-row>
                  <v-container fluid grid-list-md>
                    <v-layout wrap v-if="!isShowData">
                      <v-flex style="padding: 8px">
                        <center>
                          <h1 style="color: red">ไม่พบข้อมูล</h1>
                        </center>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap v-if="isShowData">
                      <v-flex
                        v-for="i in listData"
                        :key="i.key"
                        xs12
                        sm6
                        md4
                        style="padding: 8px"
                      >
                        <v-card>
                          <v-container fluid grid-list-md>
                            <v-layout justify-center align-center wrap>
                              <v-flex xs12 pl-2 style="display: flex">
                                <v-icon color="green" medium>
                                  ri-book-open-line
                                </v-icon>
                                <div class="dbt-label">
                                  {{ i.bplaceDesc }}
                                </div>
                              </v-flex>
                              <v-flex xs12 pl-2>
                                <div class="dbt-label">
                                  {{ `เลขที่หนังสือ : ${i.noBook}` }}
                                </div>
                              </v-flex>
                              <v-flex xs12 pl-2>
                                <div class="dbt-label">
                                  {{ `เดินทางไปประเทศ : ${i.country}` }}
                                </div>
                              </v-flex>
                              <v-flex xs12 subheading>
                                <center>
                                  <v-btn
                                    class="btn dbt-btn-blue"
                                    @click="showDetail(i)"
                                    ><v-icon left>ri-search-line</v-icon>
                                    ดูข้อมูลหนังสือ
                                  </v-btn>
                                </center>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Dialog :alertDialog="alertDialog" :waitDialog="waitDialog" />
  </div>
</template>
<script>
import { getBookListAPI } from "@/api/book.js";
import { getRegisterByReq } from "@/api/register.js";
import Dialog from "@/components/ui/dialog/Dialog";
import { get } from "vuex-pathify";
import borderlist from "@/js/borderlist.js";

export default {
  name: "Display",
  data() {
    return {
      listData: [],
      listRegister: null,
      isShowData: false,
      waitDialog: false,
      alertDialog: {
        val: false,
        msg: "",
        type: "",
      },
      systemDate: null,
    };
  },
  computed: {
    login: get("login/login"),
  },
  components: {
    Dialog,
  },
  methods: {
    showDetail(data) {
      for (let i = 0; i < this.listRegister.length; i++) {
        if (
          `${data.ccaa}${data.reqId}${data.reqYear}` ===
          `${this.listRegister[i].ccaa}${this.listRegister[i].reqId}${this.listRegister[i].reqYear}`
        ) {
          this.$emit("returnBook", this.listRegister[i]);
        }
      }
    },
    async getToday() {
      try {
        const diffTime = localStorage.getItem("system-date:diff-time");
        const serverDateTime = new Date(
          Date.now() + (diffTime != null ? Number(diffTime) : 0)
        );
        var year = serverDateTime.getFullYear() + 543;

        var month = (1 + serverDateTime.getMonth()).toString();
        month = month.length > 1 ? month : "0" + month;

        var day = serverDateTime.getDate().toString();
        day = day.length > 1 ? day : "0" + day;

        return `${year}${month}${day}`;
      } catch (error) {
        return error;
      }
    },
    compare(a, b) {
      if (
        Number(`${a.reqYear}${a.reqId.toString().padStart(7, "0")}`) <
        Number(`${b.reqYear}${b.reqId.toString().padStart(7, "0")}`)
      ) {
        return 1;
      }
      if (
        Number(`${a.reqYear}${a.reqId.toString().padStart(7, "0")}`) >
        Number(`${b.reqYear}${b.reqId.toString().padStart(7, "0")}`)
      ) {
        return -1;
      }
      return 0;
    },
  },
  async mounted() {
    // console.log("loginD", this.login);
    this.waitDialog = true;
    const register = await getRegisterByReq(
      `?pid=${this.login.userInfo.pid}&&status_lt=2`
    );
    let bookList = await getBookListAPI(this.login.userInfo.pid);
    console.log(register);
    this.systemDate = await this.getToday();
    if (register.result) {
      this.isShowData = true;
      this.listRegister = register.result;
      for (let i = 0; i < register.result.length; i++) {
        let borderCityDesc = "";
        if (register.result[i].borderCity === 48) {
          borderCityDesc = "เมียนมา";
        } else if (register.result[i].borderCity === 50) {
          borderCityDesc = "มาเลเซีย";
        } else if (register.result[i].borderCity === 56) {
          borderCityDesc = "ลาว";
        } else if (register.result[i].borderCity === 57) {
          borderCityDesc = "กัมพูชา";
        }
        if (register.result[i].status === 0) {
          if (register.result[i].travelDate <= Number(this.systemDate)) {
            this.listData.push({
              noBook: `${
                register.result[i].bpopNo !== 0
                  ? String(register.result[i].bpopNo) + "/"
                  : ""
              }${
                register.result[i].bpopNo !== 0
                  ? register.result[i].bpopYear
                  : ""
              }`,
              country: borderCityDesc,
              reqId: register.result[i].reqId,
              reqYear: register.result[i].reqYear,
              ccaa: register.result[i].ccaa,
              bplaceDesc: borderlist.maplistplace(
                Number(
                  `${register.result[i].ccaa}${String(
                    register.result[i].borderCode
                  ).padStart(3, "0")}`
                )
              ),
            });
          }
        } else {
          if (bookList.result) {
            for (let k = 0; k < bookList.result.length; k++) {
              if (
                `${register.result[i].bpopNo}${register.result[i].bpopYear}` ===
                `${bookList.result[k].bpopNo}${bookList.result[k].bpopYear}`
              ) {
                register.result[i].issueDate = bookList.result[k].issueDate;
                register.result[i].expireDate = bookList.result[k].expireDate;
                this.listData.push({
                  noBook: `${
                    register.result[i].bpopNo !== 0
                      ? String(register.result[i].bpopNo) + "/"
                      : ""
                  }${
                    register.result[i].bpopNo !== 0
                      ? register.result[i].bpopYear
                      : ""
                  }`,
                  country: borderCityDesc,
                  reqId: register.result[i].reqId,
                  reqYear: register.result[i].reqYear,
                  ccaa: register.result[i].ccaa,
                  bplaceDesc: borderlist.maplistplace(
                    Number(
                      `${register.result[i].ccaa}${String(
                        register.result[i].borderCode
                      ).padStart(3, "0")}`
                    )
                  ),
                });
              }
            }
          }
        }
      }
      this.listData.sort(this.compare);
    }
    this.waitDialog = false;
  },
};
</script>
<style scoped src="@/assets/styles/dbt-style.css"></style>
