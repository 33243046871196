<template>
  <div>
    <div style="font-family: THSarabunNew; color: white">.</div>
    <canvas id="myCanvas" style="display: none"></canvas>
    <img
      id="myBook"
      style="width: -webkit-fill-available; border: 1px solid #ccc"
    />
    <v-btn class="btn dbt-btn-blue">
      <a
        class="button"
        style="color: white; text-decoration: unset"
        :href="imageDownload"
        download="example_book.png"
        >ดาวน์โหลดตัวอย่างหนังสือผ่านแดน</a
      >
    </v-btn>
    <img id="krut" style="display: none" />
    <img id="face" style="display: none" />
    <img id="faceF1" style="display: none" />
    <img id="faceF2" style="display: none" />
    <img id="faceF3" style="display: none" />
    <img id="faceF4" style="display: none" />
  </div>
</template>

<script>
import garuda from "./garuda";
import province from "@/js/bornprovince.js";

export default {
  name: "exBookTemp",
  props: {
    dataPara: {
      type: Object,
    },
    face: {
      type: String,
    },
  },
  data() {
    return {
      imageDownload: null,
    };
  },
  methods: {
    bpsDetailS(ccaa, province, aaDesc) {
      if (Number(String(ccaa).substring(2, 4)) == 0) {
        if (String(ccaa).substring(0, 2) == 10) {
          return `ที่ทำการปกครอง${province}`;
        } else {
          return `ที่ทำการปกครองจังหวัด${province}`;
        }
      } else {
        if (String(ccaa).substring(0, 2) == 10) {
          return `ที่ว่าการ${aaDesc}`;
        } else {
          return `ที่ว่าการอำเภอ${aaDesc}`;
        }
      }
    },
    formatName(title, fname, lname, titleSex, titleCode) {
      let nameFormat = "";
      if (titleCode === 999) {
        if (titleSex === 5 || titleSex === 6) {
          if (title === "พระ") {
            nameFormat = `${title}${fname} ${lname}`;
          } else {
            nameFormat = `${title}(${fname} ${lname})`;
          }
        }
      } else if (titleSex === 5 || titleSex === 6) {
        if (title === "พระ") {
          nameFormat = `${title}${fname} ${lname}`;
        } else {
          nameFormat = `${title}(${fname} ${lname})`;
        }
      } else {
        nameFormat = `${title}${fname} ${lname}`;
      }
      return nameFormat;
    },
    // ...format,
    GenerateBook() {
      const getImage = (url) => {
        return new Promise((resolve) => {
          let img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.src = url;
        });
      };

      const getMask = (data = {}) => {
        let ImageExMask = require("./maskEx.png");
        return getImage(ImageExMask).then((img) => {
          data.imageExMask = img;

          var imageKrut = document.getElementById("krut");
          imageKrut.src = garuda.image;

          var imagePerson = document.getElementById("face");
          imagePerson.src = this.face;

          var imageFaceF4 = document.getElementById("faceF4");
          var imageFaceF3 = document.getElementById("faceF3");
          var imageFaceF2 = document.getElementById("faceF2");
          var imageFaceF1 = document.getElementById("faceF1");

          if (this.dataPara.follower > 3) {
            imageFaceF4.src = this.dataPara.listFollow[3].face;

            imageFaceF3.src = this.dataPara.listFollow[2].face;

            imageFaceF2.src = this.dataPara.listFollow[1].face;

            imageFaceF1.src = this.dataPara.listFollow[0].face;
          } else if (this.dataPara.follower > 2) {
            imageFaceF3.src = this.dataPara.listFollow[2].face;

            imageFaceF2.src = this.dataPara.listFollow[1].face;

            imageFaceF1.src = this.dataPara.listFollow[0].face;
          } else if (this.dataPara.follower > 1) {
            imageFaceF2.src = this.dataPara.listFollow[1].face;

            imageFaceF1.src = this.dataPara.listFollow[0].face;
          } else if (this.dataPara.follower > 0) {
            imageFaceF1.src = this.dataPara.listFollow[0].face;
          }

          return Promise.resolve(data);
        });
      };

      const processImage = (data) => {
        let canvas = document.getElementById("myCanvas");
        let ctx = canvas.getContext("2d");
        canvas.width = 2480 / 1;
        canvas.height = 3508 / 1;
        ctx.rect(0, 0, 2480 / 1, 3508 / 1);
        ctx.fill();
        ctx.drawImage(data.imageExMask, 0, 0, 2480, 3508);

        var imageGaruda = document.getElementById("krut");
        ctx.drawImage(imageGaruda, 1100, 40, 315, 335);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`คำร้องเล่มที่`, 200, 100);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`BOOK No.`, 200, 170);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          `${this.dataPara.ccaa}${String(this.dataPara.borderCode).padStart(
            3,
            "0"
          )}/${this.dataPara.bpopYear}`,
          420,
          135
        );

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`เลขที่`, 1940, 100);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`No.`, 1940, 170);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`${this.dataPara.bpopNo}`, 2070, 135);

        ctx.font = "bold 80px THSarabunNew";
        ctx.fillText(`ประเทศไทย`, 1130, 410);

        ctx.font = "bold 60px THSarabunNew";
        ctx.fillText(`THE KINGDOM OF THAILAND`, 1000, 490);

        ctx.font = "bold 60px THSarabunNew";
        ctx.fillText(`หนังสือผ่านแดนชั่วคราว`, 1080, 570);

        ctx.font = "bold 60px THSarabunNew";
        ctx.fillText(`TEMPORARY BORDER PASS`, 1020, 650);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`จังหวัด`, 200, 560);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`PROVINCE`, 200, 630);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          province.mapThaiprovince(
            Number(String(this.dataPara.ccaa).substring(0, 2)) * 100
          ),
          350,
          560
        );

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          province.mapEngprovince(
            Number(String(this.dataPara.ccaa).substring(0, 2)) * 100
          ),
          400,
          630
        );

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`ชื่อ-ชื่อสกุล`, 900, 760);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          this.formatName(
            this.dataPara.title,
            this.dataPara.fname,
            this.dataPara.lname,
            this.dataPara.titleSex,
            this.dataPara.titleCode
          ),
          1120,
          760
        );

        let nameEng = this.dataPara.ename.split("#");

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`SURNAME`, 900, 840);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`${nameEng[3].toUpperCase()}`, 1130, 840);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`NAME`, 1600, 840);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`${nameEng[1].toUpperCase()}`, 1730, 840);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`สัญชาติ`, 900, 920);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`ไทย`, 1080, 920);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`อาชีพ`, 1600, 920);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`${this.dataPara.occupation}`, 1720, 920);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`NATIONALITY`, 900, 1000);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`THAI`, 1170, 1000);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`OCCUPATION`, 1600, 1000);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`${this.dataPara.occupationEng}`, 1850, 1000);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`เลขประจำตัวประชาชน`, 900, 1080);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`${this.PIDFormat(this.dataPara.pid)}`, 1280, 1080);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`ส่วนสูง`, 1780, 1080);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`เมตร`, 2100, 1080);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`PERSONAL IDENTIFICATION NO.`, 900, 1160);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`HEIGHT`, 1780, 1160);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`METRE`, 2100, 1160);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          `${
            this.dataPara.height === 0
              ? " "
              : String(this.dataPara.height).substring(0, 1) +
                "." +
                String(this.dataPara.height).substring(1)
          }`,
          1950,
          1120
        );

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`วันเกิด`, 900, 1240);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          `${this.dateFormat(this.dataPara.dob).toStringType1ThaiShort()}`,
          1020,
          1240
        );

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`ภูมิลำเนา`, 1600, 1240);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`${this.dataPara.bornPlace}`, 1770, 1240);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`DATE OF BIRTH`, 900, 1320);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          `${this.dateFormat(
            this.dataPara.dob - 5430000
          ).toStringType1EnglishShort()}`,
          1200,
          1320
        );

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`DOMICILE`, 1600, 1320);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`${this.dataPara.bornPlaceEng}`, 1800, 1320);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`เพศ`, 900, 1400);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`${this.dataPara.sex === 1 ? "ชาย" : "หญิง"}`, 980, 1400);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`ตำหนิ`, 1130, 1400);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          `${
            this.dataPara.spePecul.replace("-", "") === ""
              ? ""
              : this.dataPara.spePecul
          }`,
          1250,
          1400
        );

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`SEX`, 900, 1480);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          `${this.dataPara.sex === 1 ? "Male" : "Female"}`,
          980,
          1480
        );

        ctx.font = "55px THSarabunNew";
        ctx.fillText(`SPECIAL PECULIARITIES`, 1130, 1480);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          `${
            this.dataPara.spePeculEng.replace("-", "") === ""
              ? ""
              : this.dataPara.spePecul
          }`,
          1550,
          1480
        );

        var imageFace = document.getElementById("face");
        ctx.drawImage(imageFace, 250, 900, 320, 370);

        ctx.font = "55px THSarabunNew";
        ctx.fillText(
          `...............................................`,
          200,
          1390
        );

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`ลายมือชื่อหรือลายพิมพ์นิ้วมือ`, 200, 1470);

        ctx.font = "45px THSarabunNew";
        ctx.fillText(`ผู้ถือหนังสือผ่านแดนชั่วคราว`, 200, 1520);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`SIGNATURE OR THUMB PRINT OF BEARER`, 200, 1590);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          `บุตรที่จะเดินทางพร้อมกับผู้ถือหนังสือผ่านแดน จำนวน`,
          850,
          1670
        );

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`คน`, 1800, 1670);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`NUMBER OF CHILDREN ACCOMPANYING BEARER`, 850, 1750);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(`PERSONS`, 1800, 1750);

        ctx.font = "bold 55px THSarabunNew";
        ctx.fillText(
          `${this.dataPara.follower === 0 ? "" : this.dataPara.follower}`,
          1700,
          1670
        );

        //ผู้ติดตาม 1

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`1.`, 200, 1820);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`ชื่อ`, 570, 1820);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`NAME`, 570, 1880);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`เลขประจำตัวประชาชน`, 570, 1940);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`PERSONAL IDENTIFICATION NO.`, 570, 2000);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`อายุ           ปี       เพศ`, 570, 2060);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`AGE          YEARS  SEX`, 570, 2120);

        if (this.dataPara.follower > 0) {
          var imageFaceF1 = document.getElementById("faceF1");
          ctx.drawImage(imageFaceF1, 270, 1840, 250, 250);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[0].title}${this.dataPara.listFollow[0].fname} ${this.dataPara.listFollow[0].lname}`,
            640,
            1820
          );

          nameEng = this.dataPara.listFollow[0].ename.split("#");

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${
              nameEng[0]
            }${nameEng[1].toUpperCase()} ${nameEng[2].toUpperCase()}`,
            690,
            1880
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.PIDFormat(this.dataPara.listFollow[0].pid)}`,
            920,
            1940
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`${this.dataPara.listFollow[0].age}`, 650, 2060);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[0].sex === 1 ? "ชาย" : "หญิง"}`,
            930,
            2060
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`${this.dataPara.listFollow[0].age}`, 650, 2120);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[0].sex === 1 ? "Male" : "Female"}`,
            940,
            2120
          );
        } else {
          ctx.beginPath();
          ctx.rect(270, 1840, 250, 250);
          ctx.stroke();

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 640, 1820);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 690, 1880);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 920, 1940);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 650, 2060);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 930, 2060);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 650, 2120);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 940, 2120);
        }

        //ผู้ติดตาม 2

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`2.`, 1240, 1820);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`ชื่อ`, 1610, 1820);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`NAME`, 1610, 1880);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`เลขประจำตัวประชาชน`, 1610, 1940);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`PERSONAL IDENTIFICATION NO.`, 1610, 2000);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`อายุ           ปี       เพศ`, 1610, 2060);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`AGE          YEARS  SEX`, 1610, 2120);

        if (this.dataPara.follower > 1) {
          var imageFaceF2 = document.getElementById("faceF2");
          ctx.drawImage(imageFaceF2, 1310, 1840, 250, 250);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[1].title}${this.dataPara.listFollow[1].fname} ${this.dataPara.listFollow[1].lname}`,
            1680,
            1820
          );

          nameEng = this.dataPara.listFollow[1].ename.split("#");

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${
              nameEng[0]
            }${nameEng[1].toUpperCase()} ${nameEng[2].toUpperCase()}`,
            1730,
            1880
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.PIDFormat(this.dataPara.listFollow[1].pid)}`,
            1960,
            1940
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`${this.dataPara.listFollow[1].age}`, 1690, 2060);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[1].sex === 1 ? "ชาย" : "หญิง"}`,
            1970,
            2060
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`${this.dataPara.listFollow[1].age}`, 1690, 2120);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[1].sex === 1 ? "Male" : "Female"}`,
            1980,
            2120
          );
        } else {
          ctx.beginPath();
          ctx.rect(1310, 1840, 250, 250);
          ctx.stroke();

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1680, 1820);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1730, 1880);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1960, 1940);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1690, 2060);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1970, 2060);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1690, 2120);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1980, 2120);
        }

        //ผู้ติดตาม 3

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`3.`, 200, 2200);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`ชื่อ`, 570, 2200);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`NAME`, 570, 2260);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`เลขประจำตัวประชาชน`, 570, 2320);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`PERSONAL IDENTIFICATION NO.`, 570, 2380);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`อายุ           ปี       เพศ`, 570, 2440);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`AGE          YEARS  SEX`, 570, 2500);

        if (this.dataPara.follower > 2) {
          var imageFaceF3 = document.getElementById("faceF3");
          ctx.drawImage(imageFaceF3, 270, 2220, 250, 250);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[1].title}${this.dataPara.listFollow[1].fname} ${this.dataPara.listFollow[1].lname}`,
            640,
            2200
          );

          nameEng = this.dataPara.listFollow[1].ename.split("#");

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${
              nameEng[0]
            }${nameEng[1].toUpperCase()} ${nameEng[2].toUpperCase()}`,
            690,
            2260
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.PIDFormat(this.dataPara.listFollow[1].pid)}`,
            920,
            2320
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`${this.dataPara.listFollow[1].age}`, 650, 2440);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[1].sex === 1 ? "ชาย" : "หญิง"}`,
            930,
            2440
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`${this.dataPara.listFollow[1].age}`, 650, 2500);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[1].sex === 1 ? "Male" : "Female"}`,
            940,
            2500
          );
        } else {
          ctx.beginPath();
          ctx.rect(270, 2220, 250, 250);
          ctx.stroke();

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 640, 2200);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 690, 2260);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 920, 2320);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 650, 2440);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 930, 2440);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 650, 2500);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 940, 2500);
        }

        //ผู้ติดตาม 4

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`4.`, 1240, 2200);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`ชื่อ`, 1610, 2200);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`NAME`, 1610, 2260);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`เลขประจำตัวประชาชน`, 1610, 2320);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`PERSONAL IDENTIFICATION NO.`, 1610, 2380);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`อายุ           ปี       เพศ`, 1610, 2440);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`AGE          YEARS  SEX`, 1610, 2500);

        if (this.dataPara.follower > 3) {
          var imageFaceF4 = document.getElementById("faceF4");
          ctx.drawImage(imageFaceF4, 1310, 2220, 250, 250);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[1].title}${this.dataPara.listFollow[1].fname} ${this.dataPara.listFollow[1].lname}`,
            1680,
            2200
          );

          nameEng = this.dataPara.listFollow[1].ename.split("#");

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${
              nameEng[0]
            }${nameEng[1].toUpperCase()} ${nameEng[2].toUpperCase()}`,
            1730,
            2260
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.PIDFormat(this.dataPara.listFollow[1].pid)}`,
            1960,
            2320
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`${this.dataPara.listFollow[1].age}`, 1690, 2440);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[1].sex === 1 ? "ชาย" : "หญิง"}`,
            1970,
            2440
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`${this.dataPara.listFollow[1].age}`, 1690, 2500);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dataPara.listFollow[1].sex === 1 ? "Male" : "Female"}`,
            1980,
            2500
          );
        } else {
          ctx.beginPath();
          ctx.rect(1310, 2220, 250, 250);
          ctx.stroke();

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1680, 2200);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1730, 2260);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1960, 2320);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1690, 2440);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1970, 2440);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1690, 2500);

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(`-`, 1980, 2500);
        }

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`วันที่ออก`, 180, 2750);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`DATE OF ISSUE`, 180, 2810);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`วันที่หมดอายุ`, 180, 2900);

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`DATE OF EXPIRE`, 180, 2960);

        // ข้อมูลวันหมดอายุ ลายมือชื่อเจ้าหน้าที่
        if (this.dataPara.bpopNo !== "-") {
          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dateFormat(
              this.dataPara.issueDate
            ).toStringType1ThaiShort()}`,
            340,
            2750
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dateFormat(
              this.dataPara.issueDate - 5430000
            ).toStringType1EnglishShort()}`,
            440,
            2810
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dateFormat(
              this.dataPara.expireDate
            ).toStringType1ThaiShort()}`,
            400,
            2900
          );

          ctx.font = "bold 50px THSarabunNew";
          ctx.fillText(
            `${this.dateFormat(
              this.dataPara.expireDate - 5430000
            ).toStringType1EnglishShort()}`,
            450,
            2960
          );
        }

        // ctx.font = "bold 50px THSarabunNew";
        // ctx.fillText(`()`, 1240, 2810);

        ctx.font = "bold 50px THSarabunNew";
        ctx.fillText(`นายอำเภอ`, 1180, 2870);

        ctx.font = "bold 50px THSarabunNew";
        ctx.fillText(
          `ลายมือชื่อและตราผู้อนุมัติการออกหนังสือผ่านแดน`,
          900,
          2930
        );

        ctx.font = "50px THSarabunNew";
        ctx.fillText(
          `SIGNATURE AND SEAL OF AUTHORIZED ISSUING OFFICIAL`,
          800,
          2990
        );

        ctx.font = "50px THSarabunNew";
        ctx.fillText(
          `..................................................`,
          1680,
          2800
        );

        // ctx.font = "bold 50px THSarabunNew";
        // ctx.fillText(`()`, 1870, 2860);

        ctx.font = "bold 50px THSarabunNew";
        ctx.fillText(`เจ้าหน้าที่ผู้ออกหนังสือผ่านแดน`, 1680, 2920);
        let moveDe = "";
        if (this.dataPara.borderCity === 48) {
          moveDe = "(พำนักได้ไม่เกินหนึ่งสัปดาห์)";
        } else if (this.dataPara.borderCity === 56) {
          moveDe = "(พำนักได้ไม่เกิน 3 วัน 2 คืน)";
        }

        ctx.font = "50px THSarabunNew";
        ctx.fillText(`${moveDe}`, 180, 3040);

        document.getElementById("myBook").src = canvas.toDataURL();
        this.imageDownload = document.getElementById("myBook").src;
      };

      Promise.resolve().then(getMask).then(processImage);
    },
  },
  mounted() {
    setTimeout(() => {
      this.GenerateBook();
    }, 500);
  },
};
</script>

<style scoped src="@/assets/styles/dbt-style.css"></style>
<style scoped src="@/assets/styles/font.css"></style>
