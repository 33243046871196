import { render, staticRenderFns } from "./Followerlist.vue?vue&type=template&id=64fa9ba3&scoped=true"
import script from "./Followerlist.vue?vue&type=script&lang=js"
export * from "./Followerlist.vue?vue&type=script&lang=js"
import style0 from "@/assets/styles/dbt-style.css?vue&type=style&index=0&id=64fa9ba3&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64fa9ba3",
  null
  
)

export default component.exports