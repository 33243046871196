<template>
  <div>
    <div style="font-family: AngsanaUPC; color: white">.</div>
    <canvas id="myCanvas" style="display: none"></canvas>
    <img id="myBook" class="displayExBook" style="border: 1px solid #ccc" />
    <v-btn class="btn dbt-btn-blue">
      <a
        class="button"
        style="color: white; text-decoration: unset"
        :href="imageDownload"
        download="example_book.png"
        >ดาวน์โหลดตัวอย่างหนังสือผ่านแดน</a
      >
    </v-btn>
    <img id="krut" style="display: none" />
    <img id="face" style="display: none" />
  </div>
</template>

<script>
import garuda from "./garuda";

export default {
  name: "exBook",
  props: {
    dataPara: {
      type: Object,
    },
    face: {
      type: String,
    },
  },
  data() {
    return {
      imageDownload: null,
    };
  },
  methods: {
    bpsDetailS(ccaa, province, aaDesc) {
      if (Number(String(ccaa).substring(2, 4)) == 0) {
        if (String(ccaa).substring(0, 2) == 10) {
          return `ที่ทำการปกครอง${province}`;
        } else {
          return `ที่ทำการปกครองจังหวัด${province}`;
        }
      } else {
        if (String(ccaa).substring(0, 2) == 10) {
          return `ที่ว่าการ${aaDesc}`;
        } else {
          return `ที่ว่าการอำเภอ${aaDesc}`;
        }
      }
    },
    bpsDetailSEn(ccaa, province, aaDesc) {
      if (Number(String(ccaa).substring(2, 4)) == 0) {
        return `${province} Provincial Administration Office`;
      } else {
        return `${aaDesc} District`;
      }
    },
    formatAddr(addr) {
      let addrF;
      if (addr.villageNo != "" && Number(addr.villageNo) != 0) {
        addrF = addr.houseNo + " VILAGE NO." + addr.villageNo;
      } else {
        addrF = addr.houseNo;
      }

      addrF +=
        " " +
        addr.subdistrictDesc +
        " SUB-DISTRICT," +
        addr.districtDesc +
        " DISTRICT," +
        addr.provinceDesc +
        " PROVINCE";

      return addrF;
    },
    PIDFormatMask(PID, mask) {
      if (PID == null) return "";
      PID = Number(PID);
      PID = PID.toString().padStart(13, "0");
      if (PID.length < 13) return "";
      return `${PID[0]}${mask}${PID.substring(1, 5)}${mask}${PID.substring(
        5,
        10
      )}${mask}${PID.substring(10, 12)}${mask}${PID[12]}`;
    },
    formatName(title, fname, lname, titleSex, titleCode) {
      let nameFormat = "";
      if (titleCode === 999) {
        if (titleSex === 5 || titleSex === 6) {
          if (title === "พระ") {
            nameFormat = `${title}${fname} ${lname}`;
          } else {
            nameFormat = `${title}(${fname} ${lname})`;
          }
        }
      } else if (titleSex === 5 || titleSex === 6) {
        if (title === "พระ") {
          nameFormat = `${title}${fname} ${lname}`;
        } else {
          nameFormat = `${title}(${fname} ${lname})`;
        }
      } else {
        nameFormat = `${title}${fname} ${lname}`;
      }
      return nameFormat;
    },
    GenerateBook() {
      const getImage = (url) => {
        return new Promise((resolve) => {
          let img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.src = url;
        });
      };

      const getMaskBook = (data = {}) => {
        let ImageExMask = require("./maskExBook.png");
        return getImage(ImageExMask).then((img) => {
          data.imageExMask = img;

          var imageKrut = document.getElementById("krut");
          imageKrut.src = garuda.image;

          var imagePerson = document.getElementById("face");
          imagePerson.src = this.face;
          return Promise.resolve(data);
        });
      };

      const getThai = (data = {}) => {
        let ImageThai = require("./thai.png");
        return getImage(ImageThai).then((img) => {
          data.imageThai = img;
          return Promise.resolve(data);
        });
      };

      const processImage = (data) => {
        let canvas = document.getElementById("myCanvas");
        let ctx = canvas.getContext("2d");
        canvas.width = 1039 / 1;
        canvas.height = 1476 / 1;
        ctx.rect(0, 0, 1039 / 1, 1476 / 1);
        ctx.fill();
        ctx.drawImage(data.imageExMask, 0, 0, 1039, 1476);

        ctx.drawImage(data.imageThai, 20, 750, 280, 100);

        var imageFace = document.getElementById("face");
        ctx.drawImage(imageFace, 40, 960, 240, 240);

        ctx.font = "35px AngsanaUPC";
        ctx.fillText(`ที่อยู่ / Address`, 30, 60);

        const addr1 = this.dataPara.addr1Eng.split("#");
        const addr2 = this.dataPara.addr2Eng.split("#");
        let addrFormat = this.addressFormat(
          `${this.dataPara.addr1}#${this.dataPara.addr2}`,
          this.SHORT_PREFIX
        ).getFormat();

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(`ที่อยู่ ${addrFormat}`, 30, 100);
        ctx.font = "bold 25px AngsanaUPC";
        ctx.fillText(
          `${this.formatAddr({
            houseNo: addr1[0].toUpperCase(),
            villageNo: addr1[1].toUpperCase(),
            subdistrictDesc: addr2[0].toUpperCase(),
            districtDesc: addr2[1].toUpperCase(),
            provinceDesc: addr2[2].toUpperCase(),
          })}`,
          30,
          135
        );

        ctx.font = "35px AngsanaUPC";
        ctx.fillText(
          `ส่วนสูง / Height     ตำหนิ / Special Peculiarities`,
          30,
          280
        );

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${
            this.dataPara.height === 0
              ? " "
              : String(this.dataPara.height).substring(0, 1) +
                "." +
                String(this.dataPara.height).substring(1)
          }`,
          30,
          320
        );

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${
            this.dataPara.spePecul.replace("-", "") === ""
              ? "-"
              : this.dataPara.spePecul
          } / ${
            this.dataPara.spePeculEng.replace("-", "") === ""
              ? "-"
              : this.dataPara.spePecul
          }`,
          212,
          320
        );

        ctx.font = "35px AngsanaUPC";
        ctx.fillText(`อาชีพ / Occupation`, 630, 280);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${this.dataPara.occupation} / ${this.dataPara.occupationEng}`,
          630,
          320
        );

        ctx.font = "35px AngsanaUPC";
        ctx.fillText(`ชื่อบิดา / Father Name`, 30, 390);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${this.dataPara.faname} / ${this.dataPara.fanameEng}`,
          30,
          430
        );

        ctx.font = "35px AngsanaUPC";
        ctx.fillText(`ชื่อมารดา / Mother Name`, 520, 390);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${this.dataPara.maname} / ${this.dataPara.manameEng}`,
          520,
          430
        );

        ctx.font = "25px AngsanaUPC";
        ctx.fillText(
          `ลายมือชื่อและตราเจ้าพนักงานผู้ออกหนังสือผ่านแดน`,
          600,
          680
        );

        ctx.font = "25px AngsanaUPC";
        ctx.fillText(
          `Signature and Seal of Authorized Issue Official`,
          620,
          700
        );

        ctx.font = "bold 40px AngsanaUPC";
        ctx.fillText(`เลขที่หนังสือผ่านแดน`, 440, 800);

        ctx.font = "bold 40px AngsanaUPC";
        ctx.fillText(`BORDER PASS NO.`, 440, 830);

        ctx.font = "bold 50px AngsanaUPC";
        ctx.fillText(
          `${this.dataPara.bpNo === null ? "" : this.dataPara.bpNo}`,
          730,
          815
        );

        ctx.font = "35px AngsanaUPC";
        ctx.fillText(`ชื่อ สกุล`, 320, 880);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          this.formatName(
            this.dataPara.title,
            this.dataPara.fname,
            this.dataPara.lname,
            this.dataPara.titleSex,
            this.dataPara.titleCode
          ),
          320,
          910
        );

        ctx.font = "35px AngsanaUPC";
        ctx.fillText(`Name Surname`, 320, 940);

        let nameEng = this.dataPara.ename.split("#");

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${nameEng[0]} ${
            nameEng[1].substring(0, 1).toUpperCase() +
            nameEng[1].substring(1).toLowerCase()
          } ${
            nameEng[3].substring(0, 1).toUpperCase() +
            nameEng[3].substring(1).toLowerCase()
          }`,
          320,
          970
        );

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(`สัญชาติ / Nationality`, 320, 1000);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(`ไทย / THAI`, 320, 1030);

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(`เพศ / Sex`, 510, 1000);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${this.dataPara.sex === 1 ? "ช" : "ญ"} / ${
            this.dataPara.sex === 1 ? "M" : "F"
          }`,
          510,
          1030
        );

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(
          `เลขประจำตัวประชาชน / Personal Identification No.`,
          600,
          1000
        );

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${this.PIDFormatMask(this.dataPara.pid, " ")}`,
          600,
          1030
        );

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(`วันที่เกิด`, 320, 1060);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${this.dateFormat(this.dataPara.dob).toStringType1ThaiShort()}`,
          320,
          1090
        );

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(`สถานที่เกิด`, 490, 1060);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(`${this.dataPara.bornPlace}`, 630, 1060);

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(`Place of Birth`, 490, 1090);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${this.dataPara.bornPlaceEng.toUpperCase()} PROVINCE`,
          630,
          1090
        );

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(`Date of Birth`, 320, 1120);

        ctx.font = "bold 35px AngsanaUPC";
        ctx.fillText(
          `${this.dateFormat(this.dataPara.dob - 5430000)
            .toStringType1EnglishShort()
            .toUpperCase()}`,
          320,
          1150
        );

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(`ออกให้โดย / Authority`, 700, 1120);

        let ccCode = ["90", "91", "94", "95", "96"];
        if (ccCode.includes(this.dataPara.ccaa.toString().substring(0, 2))) {
          ctx.font = "bold 25px AngsanaUPC";
          ctx.fillText(
            `${this.bpsDetailS(
              this.dataPara.ccaa,
              this.dataPara.ccDescTh,
              this.dataPara.aaDescTh
            )} /`,
            600,
            1150
          );
          ctx.fillText(
            ` ${this.bpsDetailSEn(
              this.dataPara.ccaa,
              this.dataPara.ccDescEn,
              this.dataPara.aaDescEn
            )}`,
            600,
            1180
          );
        } else {
          ctx.font = "bold 25px AngsanaUPC";
          ctx.fillText(
            `อ.${
              this.dataPara.aaDescTh
            } / ${this.dataPara.aaDescEn.toUpperCase()} DISTRICT`,
            600,
            1150
          );
        }

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(`วันที่ออก / Date of Issue`, 320, 1180);

        ctx.font = "30px AngsanaUPC";
        ctx.fillText(`วันที่หมดอายุ / Date of Expiry`, 320, 1240);

        if (this.dataPara.bpNo !== null) {
          ctx.font = "bold 35px AngsanaUPC";
          ctx.fillText(
            `${this.dateFormat(
              this.dataPara.issueDate
            ).toStringType1ThaiShort()} / ${this.dateFormat(
              this.dataPara.issueDate - 5430000
            )
              .toStringType1EnglishShort()
              .toUpperCase()}`,
            320,
            1210
          );

          ctx.font = "bold 35px AngsanaUPC";
          ctx.fillText(
            `${this.dateFormat(
              this.dataPara.expireDate
            ).toStringType1ThaiShort()} / ${this.dateFormat(
              this.dataPara.expireDate - 5430000
            )
              .toStringType1EnglishShort()
              .toUpperCase()}`,
            320,
            1270
          );
        }

        ctx.font = "25px AngsanaUPC";
        ctx.fillText(
          `ลายมือชื่อหรือลายพิมพ์นิ้วมือผู้ถือหนังสือผ่านแดน`,
          630,
          1270
        );

        ctx.font = "25px AngsanaUPC";
        ctx.fillText(`Signature or Thumb Print of Bearer`, 680, 1290);

        document.getElementById("myBook").src = canvas.toDataURL();
        this.imageDownload = document.getElementById("myBook").src;
      };

      Promise.resolve().then(getMaskBook).then(getThai).then(processImage);
    },
  },
  mounted() {
    setTimeout(() => {
      this.GenerateBook();
    }, 500);
  },
};
</script>

<style scoped src="@/assets/styles/dbt-style.css"></style>
<style scoped src="@/assets/styles/font.css"></style>
