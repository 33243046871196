<template>
  <div class="dbt-content">
    <v-row>
      <v-col md="10" sm="12" offset-md="1">
        <v-row>
          <v-col sm="12">
            <v-card elevation="2" class="dbt-content-card">
              <div class="title">
                <i class="ri-team-fill"></i>
                เพิ่มผู้ติดตาม</div>
              <div class="context">
                <v-row justify="center">
                  <v-flex
                    pt-2
                    pb-2
                    style="padding: 0px 10px 0px 10px"
                  >
                  <v-expansion-panels v-model="panel" multiple accordion>
                      <v-expansion-panel
                        v-for="(follnames, i) in follname" :key="follnames.name"
                        @click="onExpansionPanelClick(i)"
                      >
                      <v-expansion-panel-header>
                        <v-row>
                          <div style="padding: 6px 0px 0px 0px"><v-checkbox v-model="checkbox[i]"></v-checkbox></div>
                          <div style="padding: 15px 0px 0px 0px"><v-icon>mdi-account-circle</v-icon> {{ follnames.name }}</div> 
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12">  
                            <v-flex
                              md="12"
                              sm="12"
                              style="padding: 0px 10px 0px 10px"
                            >
                              <div class="dbt-label1">ชื่อพ่อภาษาอังกฤษ</div>
                              <v-text-field  v-model="follname1.fnameeng[i]" :maxlength="40" @keydown.native="keyFilter($event, 'nameEnG')" @keydown.space="(event) => event.preventDefault()"/>
                            </v-flex>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-flex
                              md="12"
                              sm="12"
                              style="padding: 0px 10px 0px 10px"
                            >
                              <div class="dbt-label1">ชื่อเเม่ภาษาอังกฤษ</div>
                              <v-text-field v-model="follname1.mnameeng[i]" :maxlength="40" @keydown.native="keyFilter($event, 'nameEnG')" @keydown.space="(event) => event.preventDefault()" />
                            </v-flex>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-flex
                              md="12"
                              sm="12"
                              style="padding: 0px 10px 0px 10px"
                            >
                              <div class="dbt-label1">จังหวัดเกิด</div>
                                <v-combobox
                                  prefix="*"
                                  class="red--text"
                                  v-model="follname1.bornplace[i]"
                                  :items="bornplacelist"
                                  item-text="text"
                                  item-value="value"
                                  attach
                                  return-object
                                />
                            </v-flex>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-flex
                              md="12"
                              sm="12"
                              style="padding: 0px 10px 0px 10px"
                            >
                              <div class="dbt-label1">ส่วนสูง (เซนติเมตร)</div>
                              <v-text-field
                                v-model="follname1.height[i]"
                                :maxlength="3" 
                                @keydown.space="(event) => event.preventDefault()"
                                @keydown.native="keyFilter($event, 'number')"
                              />
                            </v-flex>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content> 
                    </v-expansion-panel>
                  </v-expansion-panels>
                  </v-flex>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-flex
                        md="12"
                        sm="12"
                        style="padding: 0px 20px 0px 20px"
                      >
                        <div style="text-align: center; color: red;">*เฉพาะผู้ติดตามที่มีบัตรประจำตัวประชาชนแล้ว</div>
                      </v-flex>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div style="flex-direction: column-reverse; float: right; position:fixed; right:20px; bottom:6%; margin:0px; padding:0px; z-index: 1">
                <div style="padding-bottom: 20px;">
                  <v-btn class="dbt-btn-green" fab large @click.prevent="qrcode"><v-icon>mdi-chevron-double-right</v-icon></v-btn>
                </div>
                <div style="padding-bottom: 10px;">
                   <v-btn class="dbt-btn-blue" fab large @click.prevent="back"><i class="ri-arrow-left-line"></i></v-btn>        
                </div>
              </div>
              <v-card-actions class="grey lighten-3"/>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import keyFilter from '@/js/keyFilter.js';
import { sync, get } from 'vuex-pathify'
import { getbirthAPI } from "@/api/birth.js";
import { getsmcardAPI } from "@/api/smcard.js";
import { getpopAPI } from "@/api/pop.js";
import { getpicAPI } from "@/api/imgcard.js";
import province from '@/js/bornprovince.js';
import Swal from 'sweetalert2'

export default {
  name: 'Follower',
  props: {
    pagecheck: {
      type: Number,
      default: () => { return null },
      required: true,
    }
  },
  data() {
    return {
      keyFilter: keyFilter,
      expanded: [],
      bornplace: [],
      checkbox: [],
      height: [],
      fnamee: [],
      fnamethai: [],
      mnamee: [],
      mnamethai: [],
      engname: [],
      followername: [],
      panel: [],
      checkboxtest: [],
      follname: [],
      data: [],
      follname1: {
        pid: [],
        bp1Number: [],
        name: [],
        height: [],
        fnamee: [],
        fnameeng: [],
        fnameth: [],
        mnamee: [],
        mnameeng: [],
        mnameth: [],
        bornplace: [],
        engname: [],
      },
      follselect: [],
      bornplacelist:[],
      bp1: [],
      checkborn: [],
      addressFormat: null,
      checkinsert: [],
      testext: null
    };
  },
  mounted () {
    if (this.pagecheck === 1) {
      this.seteditdata()
    } else {
      this.getdata()
    }
  },
  methods: {
    clearlist() {
      this.follname1.pid = []
      this.follname1.bp1Number = []
      this.follname1.name = []
      this.follname1.height = []
      this.follname1.fnamee = []
      this.follname1.fnameeng = []
      this.follname1.fnameth = []
      this.follname1.mnamee = []
      this.follname1.mnameeng = []
      this.follname1.mnameth = []
      this.follname1.bornplace = []
      this.follname1.engname = []
    },
    async seteditdata() {
      let pve = province.province()
      this.bornplacelist = []
      this.clearlist()
      this.checkbox = []
      this.checkboxtest = []
      this.panel = []
      for (let j = 0; j < pve.length; j++) {  
        this.bornplacelist.push({
          text: pve[j].thai,
          value: pve[j].code
        })
      }
      for (let a = 0; a < this.followerlist.length; a++) {
        let pic = await getpicAPI(this.followerlist[a].pid)
        if (pic.result) {
          this.follname.push({
            title: this.followerlist[a].title.description,
            fname: this.followerlist[a].firstName,
            lname: this.followerlist[a].lastName,
            name: this.formatFullname(this.followerlist[a].title.description, this.followerlist[a].firstName, this.followerlist[a].middleName, this.followerlist[a].lastName),
            pid: this.followerlist[a].pid,
            sex: this.followerlist[a].sex.code,
            dateOfBirth: this.followerlist[a].dateOfBirth,
            age: this.followerlist[a].dateOfBirth
          })
        }
      }
      for (let b = 0; b < this.followeredit.bp1Number.length; b++) {
        this.follname1.pid.push(this.followeredit.select[b].pid)
        this.follname1.bp1Number.push(this.followeredit.bp1Number[b])
        this.follname1.name.push(this.followeredit.follername[b])
        this.follname1.height.push(this.followeredit.Height[b])
        this.follname1.fnamee.push(this.followeredit.FanameEng[b])
        this.follname1.fnameeng.push(this.followeredit.FanameEng[b])
        this.follname1.fnameth.push(this.followeredit.Fanamethai[b])
        this.follname1.mnamee.push(this.followeredit.ManameEng[b])
        this.follname1.mnameeng.push(this.followeredit.ManameEng[b])
        this.follname1.mnameth.push(this.followeredit.Manamethai[b])
        this.follname1.engname.push(this.followeredit.Engname[b])
        if (this.followeredit.BornPlace[b]) {
          this.follname1.bornplace.push({ value: this.followeredit.BornPlace[b].value, text: this.followeredit.BornPlace[b].text})
        } else {
          this.follname1.bornplace.push("")
        }
        if (this.followeredit.Checkbox[b] === undefined) {
          this.checkboxtest.push(false)
        } else {
          this.checkboxtest.push(this.followeredit.Checkbox[b])
        }
      }
      for (let c = 0; c < this.checkboxtest.length; c++) {
        this.checkbox.push(this.checkboxtest[c])
        if (this.checkboxtest[c] === true) {
          this.panel.push(c)
        }
      }
    },
    async getdata() {
      let pv
      let bornsearch
      let search
      let searchdata
      pv = province.province()
      /* this.birth = {
          addDate: 25621106,
          father: {
            pid: 1119900222056,
            addressFlag: 4,
            addressFormat: "118#7####หนองใหญ่#ปราสาท#สุรินทร์",
            age: 29,
            birthCountryCode: 99,
            localCountryCode: 0,
            nameFormat: "0#นาย#ธนบดี##มหาธนานุสรณ์",
            nationalityCode: 99,
            passport: "",
            passportDate: 0,
            ccaa: 0,
            birthProvinceCode: 11,
            alienCertificateNo: " ",
            alienCertificateDate: 0
          },
          house: {
            hid: 32940000018,
            addressFormat: "ทะเบียนบ้านกลาง 16#0####กังแอน#ปราสาท#สุรินทร์",
            ccaattmm: 32050100
          },
          mother: {
            pid: 3102201340033,
            addressFlag: 3,
            addressFormat: "118#7####หนองใหญ่#ปราสาท#สุรินทร์",
            age: 39,
            birthCountryCode: 99,
            localCountryCode: 0,
            nameFormat: "0#นาง#จารุณี##ทองประเสริฐ",
            nationalityCode: 99,
            passport: "",
            passportDate: 0,
            ccaa: 0,
            birthProvinceCode: 10,
            alienCertificateNo: " ",
            alienCertificateDate: 0
          },
          place: {
            addressFlag: 2,
            ccaattmm: 32050102,
            houseNo: "602",
            name: "",
            rcode: "3294",
            soi: "0000",
            thanon: "0000",
            trok: "0000",
            code: 1,
            addressFormat: "602#2####กังแอน#ปราสาท#สุรินทร์"
          },
          terminate: {
            date: 0,
            cause: ""
            }
          } */
      this.follname = []
      this.bornplacelist = []
      this.clearlist()
      this.checkbox = []
      for (let j = 0; j < pv.length; j++) {  
        this.bornplacelist.push({
          text: pv[j].thai,
          value: pv[j].code
        })
      }
      for (let a = 0; a < this.followerlist.length; a++) {
        let pic = await getpicAPI(this.followerlist[a].pid)
        if (pic.result) {
          this.follname.push({
            title: this.followerlist[a].title.description,
            fname: this.followerlist[a].firstName,
            lname: this.followerlist[a].lastName,
            name: this.formatFullname(this.followerlist[a].title.description, this.followerlist[a].firstName, this.followerlist[a].middleName, this.followerlist[a].lastName),
            pid: this.followerlist[a].pid,
            sex: this.followerlist[a].sex.code,
            dateOfBirth: this.followerlist[a].dateOfBirth,
            age: this.followerlist[a].dateOfBirth
          })
        }
      }
      if (this.regisfirst.followerregis !== null) {
        for (let j = 0; j < this.follname.length; j++) {
          this.follname1.fnamee.push('')
          this.follname1.fnameeng.push('')
          this.follname1.mnamee.push('')
          this.follname1.mnameeng.push('')
          this.follname1.height.push('')
          this.follname1.bornplace.push('')
          this.follname1.engname.push('')
          this.follname1.fnameth.push('')
          this.follname1.mnameth.push('')
          this.follname1.bp1Number.push('')
          this.follname1.pid.push('')
          this.follname1.name.push('')
        }
        for (let j = 0; j < this.follname.length; j++) {
          for (let k = 0; k < this.regisfirst.followerregis.length; k++) {
            if (this.follname[j].pid === this.regisfirst.followerregis[k].pid) {
              let smcard = await getsmcardAPI(this.regisfirst.followerregis[k].pid) 
              if (smcard.result) {
                search = this.follname.filter(word => word.pid === this.regisfirst.followerregis[k].pid)
                searchdata = this.regisfirst.followerregis.filter(word => word.pid === search[0].pid)
                bornsearch = this.bornplacelist.filter(word => word.text === this.regisfirst.followerregis[k].bornPlace)
                this.checkbox.push(true)
                this.panel.push(j)
                this.follname1.height.splice(j, 1, searchdata[0].height)
                this.follname1.bornplace.splice(j, 1, { value: bornsearch[0].value, text: bornsearch[0].text })
                this.follname1.engname.splice(j, 1, `${smcard.result.englishName.title}#${smcard.result.englishName.firstName}#${smcard.result.englishName.middleName}#${smcard.result.englishName.lastName}`)
                this.follname1.fnameth.splice(j, 1, searchdata[0].faname)
                this.follname1.mnameth.splice(j, 1, searchdata[0].maname)
                this.follname1.bp1Number.splice(j, 1, smcard.result.bp1Number)
                this.follname1.pid.splice(j, 1, searchdata[0].pid)
                this.follname1.name.splice(j, 1, this.follname[j].name)
                if (this.smcardfirst.sex === 1) {
                  this.follname1.fnamee.splice(j, 1, this.smcardfirst.englishName.firstName)
                  this.follname1.fnameeng.splice(j, 1, this.smcardfirst.englishName.firstName)
                } else {
                  this.follname1.mnamee.splice(j, 1, this.smcardfirst.englishName.firstName)
                  this.follname1.mnameeng.splice(j, 1, this.smcardfirst.englishName.firstName)
                }
              }
            }
          }
        }
      }
    },
    back() {
      this.$router.push({ name: 'registerdetail', params: { pagecheck: 1 } }).catch(() => {})
    },
    async onExpansionPanelClick(event) {
      if (this.follname1.pid[event] === this.follname[event].pid && this.pagecheck !== 1) {
        this.testext = null
      } else {
        if (this.regisfirst.followerregis !== null) {
          if (this.smcardfirst.sex === 1) {
            let smcard = await getsmcardAPI(this.follname[event].pid)
            let birth = await getbirthAPI(this.follname[event].pid)
            let pop = await getpopAPI(this.follname[event].pid)
            if (smcard.result && pop.result) {
              this.follname1.pid.splice(event, 1, this.follname[event].pid)
              this.follname1.bp1Number.splice(event, 1, smcard.result.bp1Number)
              this.follname1.name.splice(event, 1, this.follname[event].name)
              this.follname1.height.splice(event, 1, this.regisfirst.followerregis[event].height)
              this.follname1.fnamee.splice(event, 1, this.smcardfirst.englishName.firstName)
              this.follname1.fnameeng.splice(event, 1, this.smcardfirst.englishName.firstName)
              this.follname1.fnameth.splice(event, 1, pop.result.fatherName)
              this.follname1.mnamee.splice(event, 1, '')
              this.follname1.mnameeng.splice(event, 1, '')
              this.follname1.mnameth.splice(event, 1, pop.result.motherName)
              this.follname1.engname.splice(event, 1, `${smcard.result.englishName.title}#${smcard.result.englishName.firstName}#${smcard.result.englishName.middleName}#${smcard.result.englishName.lastName}`)
              if (birth.result) {
                this.follname1.bornplace.splice(event, 1, { value: event, text: this.splitaddress(birth.result.place.addressFormat) })
              } else {
                let searchborn = this.bornplacelist.filter(word => word.text === this.regisfirst.followerregis[event].bornPlace)
                this.follname1.bornplace.splice(event, 1, { value: searchborn[0].value, text: searchborn[0].text })
              }
            }
          } else {
            let smcard = await getsmcardAPI(this.follname[event].pid)
            let birth = await getbirthAPI(this.follname[event].pid)
            let pop = await getpopAPI(this.follname[event].pid)
            if (smcard.result && pop.result) {
              this.follname1.pid.splice(event, 1, this.follname[event].pid)
              this.follname1.bp1Number.splice(event, 1, smcard.result.bp1Number)
              this.follname1.name.splice(event, 1, this.follname[event].name)
              this.follname1.height.splice(event, 1, this.regisfirst.followerregis[event].height)
              this.follname1.fnamee.splice(event, 1, '')
              this.follname1.fnameeng.splice(event, 1, '')
              this.follname1.fnameth.splice(event, 1, pop.result.fatherName)
              this.follname1.mnamee.splice(event, 1, this.smcardfirst.englishName.firstName)
              this.follname1.mnameeng.splice(event, 1, this.smcardfirst.englishName.firstName)
              this.follname1.mnameth.splice(event, 1, pop.result.motherName)
              if (birth.result) {
                this.follname1.bornplace.splice(event, 1, { value: event, text: this.splitaddress(birth.result.place.addressFormat) })
              } else {
                let searchborn = this.bornplacelist.filter(word => word.text === this.regisfirst.followerregis[event].bornPlace)
                this.follname1.bornplace.splice(event, 1, { value: searchborn[0].value, text: searchborn[0].text })
              }
            }
          }
        } else {
          if (this.smcardfirst.sex === 1) {
            for (let k = 0; k < this.follname.length; k++) {
              if (this.follname1.pid[k] === this.follname[k].pid) {
                this.testext = null
              } else {
                let smcard = await getsmcardAPI(this.follname[k].pid)
                let birth = await getbirthAPI(this.follname[k].pid)
                let pop = await getpopAPI(this.follname[k].pid)
                if (event === k) {
                  if (smcard.result && pop.result) {
                    this.follname1.pid.push(this.follname[event].pid)
                    this.follname1.bp1Number.push(smcard.result.bp1Number)
                    this.follname1.name.push(this.follname[event].name)
                    this.follname1.height.push('')
                    this.follname1.fnamee.push(this.smcardfirst.englishName.firstName)
                    this.follname1.fnameeng.push(this.smcardfirst.englishName.firstName)
                    this.follname1.fnameth.push(pop.result.fatherName)
                    this.follname1.mnamee.push('')
                    this.follname1.mnameeng.push('')
                    this.follname1.mnameth.push(pop.result.motherName)
                    this.follname1.engname.push(`${smcard.result.englishName.title}#${smcard.result.englishName.firstName}#${smcard.result.englishName.middleName}#${smcard.result.englishName.lastName}`)
                    if (birth.result) {
                      this.follname1.bornplace.push({ value: event, text: this.splitaddress(birth.result.place.addressFormat) })
                    } else {
                      this.follname1.bornplace.push('')
                    }
                  }
                } else {
                  if (smcard.result && pop.result) {
                    this.follname1.pid.push(this.follname[k].pid)
                    this.follname1.bp1Number.push(smcard.result.bp1Number)
                    this.follname1.name.push(this.follname[k].name)
                    this.follname1.height.push('')
                    this.follname1.fnamee.push(this.smcardfirst.englishName.firstName)
                    this.follname1.fnameeng.push(this.smcardfirst.englishName.firstName)
                    this.follname1.fnameth.push(pop.result.fatherName)
                    this.follname1.mnamee.push('')
                    this.follname1.mnameeng.push('')
                    this.follname1.mnameth.push(pop.result.motherName)
                    this.follname1.engname.push(`${smcard.result.englishName.title}#${smcard.result.englishName.firstName}#${smcard.result.englishName.middleName}#${smcard.result.englishName.lastName}`)
                    if (birth.result) {
                      this.follname1.bornplace.push({ value: k, text: this.splitaddress(birth.result.place.addressFormat) })
                    } else {
                      this.follname1.bornplace.push('')
                    }
                  }
                }
              }
            }
          } else {
            for (let k = 0; k < this.follname.length; k++) {
              if (this.follname1.pid[k] === this.follname[k].pid) {
                this.testext = null
              } else {
                let smcard = await getsmcardAPI(this.follname[k].pid)
                let birth = await getbirthAPI(this.follname[k].pid)
                let pop = await getpopAPI(this.follname[k].pid)
                if (event === k) {
                  if (smcard.result && pop.result) {
                    this.follname1.pid.push(this.follname[event].pid)
                    this.follname1.bp1Number.push(smcard.result.bp1Number)
                    this.follname1.name.push(this.follname[event].name)
                    this.follname1.height.push('')
                    this.follname1.fnamee.push('')
                    this.follname1.fnameeng.push('')
                    this.follname1.fnameth.push(pop.result.fatherName),
                    this.follname1.mnamee.push(this.smcardfirst.englishName.firstName)
                    this.follname1.mnameeng.push(this.smcardfirst.englishName.firstName)
                    this.follname1.mnameth.push(pop.result.motherName)
                    this.follname1.engname.push(`${smcard.result.englishName.title}#${smcard.result.englishName.firstName}#${smcard.result.englishName.middleName}#${smcard.result.englishName.lastName}`)
                    if (birth.result) {
                      this.follname1.bornplace.push({ value: event, text: this.splitaddress(birth.result.place.addressFormat) })
                    } else {
                      this.follname1.bornplace.push('')
                    }
                  }
                } else {
                  if (smcard.result && pop.result) {
                    this.follname1.pid.push(this.follname[k].pid)
                    this.follname1.bp1Number.push(smcard.result.bp1Number)
                    this.follname1.name.push(this.follname[k].name)
                    this.follname1.height.push('')
                    this.follname1.fnamee.push('')
                    this.follname1.fnameeng.push('')
                    this.follname1.fnameth.push(pop.result.fatherName),
                    this.follname1.mnamee.push(this.smcardfirst.englishName.firstName)
                    this.follname1.mnameeng.push(this.smcardfirst.englishName.firstName)
                    this.follname1.mnameth.push(pop.result.motherName)
                    this.follname1.engname.push(`${smcard.result.englishName.title}#${smcard.result.englishName.firstName}#${smcard.result.englishName.middleName}#${smcard.result.englishName.lastName}`)
                    if (birth.result) {
                      this.follname1.bornplace.push({ value: k, text: this.splitaddress(birth.result.place.addressFormat) })
                    } else {
                      this.follname1.bornplace.push('')
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    qrcode() {
      this.followername = []
      this.follselect = []
      this.height = []
      this.fnamee = []
      this.mnamee = []
      this.bornplace = []
      this.engname = []
      this.bp1 = []
      this.fnamethai = []
      this.mnamethai = []
      if (this.panel.length === 0) {
        Swal.fire({
          icon: 'warning',
          text: 'กรณาเลือกผู้ติดตาม',
          confirmButtonText: 'ตกลง',
        })
        return true
      } else {
        for (let p = 0; p < this.follname.length; p++) {
          if (this.checkbox[p] === true) {
            if (this.follname1.bornplace[p] === "") {
              Swal.fire({
                icon: 'warning',
                text: 'กรณาระบุจังหวัดเกิด',
                confirmButtonText: 'ตกลง',
              })
              return true
            }
          }
        }
      }
      if (this.regisfirst.Bordercity === 50) {
        if (this.panel.length > 8) {
          Swal.fire({
            icon: 'warning',
            text: 'สามารถเลือกผู้ติดตามมากที่สุด 8 คน',
            confirmButtonText: 'ตกลง',
          })
          return true
        }
      } else {
        if (this.panel.length > 4) {
          Swal.fire({
            icon: 'warning',
            text: 'สามารถเลือกผู้ติดตามมากที่สุด 4 คน',
            confirmButtonText: 'ตกลง',
          })
          return true
        }
      }
      for (let p = 0; p < this.follname.length; p++) {
        this.followername.push(this.follname1.name[p])
        this.fnamee.push(this.follname1.fnameeng[p])
        this.mnamee.push(this.follname1.mnameeng[p])
        this.height.push(this.follname1.height[p])
        this.bornplace.push(this.follname1.bornplace[p])
        this.engname.push(this.follname1.engname[p])
        this.bp1.push(this.follname1.bp1Number[p])
        this.fnamethai.push(this.follname1.fnameth[p])
        this.mnamethai.push(this.follname1.mnameth[p])
      }
      this.follower = {
        follername: this.followername.filter(item => item !== undefined),
        select: this.follname,
        Checkbox: this.checkbox,
        panel: this.panel,
        FanameEng: this.fnamee.filter(item => item !== undefined),
        Fanamethai: this.fnamethai.filter(item => item !== undefined),
        ManameEng: this.mnamee.filter(item => item !== undefined),
        Manamethai: this.mnamethai.filter(item => item !== undefined),
        Engname: this.engname.filter(item => item !== undefined),
        BornPlace: this.bornplace.filter(item => item !== undefined),
        Height: this.height.filter(item => item !== undefined),
        bp1Number: this.bp1.filter(item => item !== undefined)
      }
      this.page = 1
      this.$router.push("/register/followerlist").catch(()=>{});
    },
    splitaddress(address) {
      const Digitaddress = address.split('#')
      return Digitaddress[7]
    },
    splitname(name) {
      const Digitname = name.split('#')
      return Digitname[2]
    },
    formatFullname(title, firstName, middleName, lastName) {
      let t = `${title} ` || ''
      let f = `${firstName} ` || ''
      let m = `${middleName} ` || ''
      let l = `${lastName} ` || ''
      return `${t}${f}${m}${l}`
    },
  },
  computed: {
    follower: sync('follower/follower'),
    followeredit: get('follower/follower'),
    page: sync('page/page'),
    followerlist: sync('followerlist/followerlist'),
    smcardfirst: get('smcard/smcard'),
    regisfirst: get('data/data'),
  },
  watch: {
    async panel() {
      this.checkbox = []
      for (let k = 0; k < this.panel.length; k++) {
        this.checkbox[this.panel[k]] = true
      }
    }
  }
}
</script>

<style scoped src="@/assets/styles/dbt-style.css">
</style>