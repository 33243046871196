import axios from 'axios'
import withResolve from '@cdglib/js-resolve-style'

const getpopAPI = async (pid) => {
  const getApi = () => axios.get(`/api/v1/pop/${pid}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getfatherAPI = async (pid) => {
  const getApi = () => axios.get(`/api/v1/pop/father/${pid}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

const getmotherAPI = async (pid) => {
  const getApi = () => axios.get(`/api/v1/pop/mother/${pid}`)
  const [error, result] = await withResolve(getApi)()
  if (error) return { error }
  return { error, result: result.data }
}

export { getpopAPI, getfatherAPI, getmotherAPI }