<template>
  <div>
    <v-dialog
      v-model="alertDialog.val"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title
          :class="alertDialog.type"
          primary-title
        >
          <i class="ri-information-line"></i>
          แจ้งเตือน
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-layout justify-center align-center row pt-3>
              <h4>{{ alertDialog.msg }}</h4>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="alertDialog = false"
          >
            ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="waitDialog"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          กรุณารอสักครู่...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Dialog',
  props: {
    alertDialog: {
      type: Object,
      default: () => {
        return  {
          val: this.alert,
          msg: '',
          type: '',
        }
      },
      required: true,
    },
    waitDialog: {
      type: Boolean,
      default: () => { return false },
      required: true,
    },
  },
  data() {
    return {
      alert: false,
    }
  },
  watch: {
  },
  methods: {
  },
}
</script>
<style>
  
</style>
