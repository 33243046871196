import { make } from 'vuex-pathify'

const state = {
  verify: []
}

const actions = make.actions(state)
const mutations = make.mutations(state)
const getters = make.getters(state)

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}