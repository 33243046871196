const province = () => {
    return [
        {"code":1000,"thai":"กรุงเทพมหานคร","english":"Bangkok"},
        {"code":1100,"thai":"สมุทรปราการ","english":"Samut Prakan"},
        {"code":1200,"thai":"นนทบุรี","english":"Nonthaburi"},
        {"code":1300,"thai":"ปทุมธานี","english":"Pathum Thani"},
        {"code":1400,"thai":"พระนครศรีอยุธยา","english":"Phra Nakhon Si Ayutthaya"},
        {"code":1500,"thai":"อ่างทอง","english":"Ang Thong"},
        {"code":1600,"thai":"ลพบุรี","english":"Lop Buri"},
        {"code":1700,"thai":"สิงห์บุรี","english":"Sing Buri"},
        {"code":1800,"thai":"ชัยนาท","english":"Chai Nat"},
        {"code":1900,"thai":"สระบุรี","english":"Saraburi"},
        {"code":2000,"thai":"ชลบุรี","english":"Chon Buri"},
        {"code":2100,"thai":"ระยอง","english":"Rayong"},
        {"code":2200,"thai":"จันทบุรี","english":"Chanthaburi"},
        {"code":2300,"thai":"ตราด","english":"Trat"},
        {"code":2400,"thai":"ฉะเชิงเทรา","english":"Chachoengsao"},
        {"code":2500,"thai":"ปราจีนบุรี","english":"Prachin Buri"},    
        {"code":2600,"thai":"นครนายก","english":"Nakhon Nayok"},
        {"code":2700,"thai":"สระแก้ว","english":"Sa Kaeo"},
        {"code":3000,"thai":"นครราชสีมา","english":"Nakhon Ratchasima"},
        {"code":3100,"thai":"บุรีรัมย์","english":"Buri Ram"},
        {"code":3200,"thai":"สุรินทร์","english":"Surin"},
        {"code":3300,"thai":"ศรีสะเกษ","english":"Si Sa Ket"},
        {"code":3400,"thai":"อุบลราชธานี","english":"Ubon Ratchathani"},
        {"code":3500,"thai":"ยโสธร","english":"Yasothon"},
        {"code":3600,"thai":"ชัยภูมิ","english":"Chaiyaphum"},
        {"code":3700,"thai":"อำนาจเจริญ","english":"Amnat Charoen"},
        {"code":3800,"thai":"บึงกาฬ","english":"Bueng Kan"},
        {"code":3900,"thai":"หนองบัวลำภู","english":"Nong Bua Lam Phu"},
        {"code":4000,"thai":"ขอนแก่น","english":"Khon Kaen"},
        {"code":4100,"thai":"อุดรธานี","english":"Udon Thani"},
        {"code":4200,"thai":"เลย","english":"Loei"},
        {"code":4300,"thai":"หนองคาย","english":"Nong Khai"},
        {"code":4400,"thai":"มหาสารคาม","english":"Maha Sarakham"},
        {"code":4500,"thai":"ร้อยเอ็ด","english":"Roi Et"},
        {"code":4600,"thai":"กาฬสินธุ์","english":"Kalasin"},
        {"code":4700,"thai":"สกลนคร","english":"Sakon Nakhon"},
        {"code":4800,"thai":"นครพนม","english":"Nakhon Phanom"},
        {"code":4900,"thai":"มุกดาหาร","english":"Mukdahan"},
        {"code":5000,"thai":"เชียงใหม่","english":"Chiang Mai"},
        {"code":5100,"thai":"ลำพูน","english":"Lamphun"},
        {"code":5200,"thai":"ลำปาง","english":"Lampang"},
        {"code":5300,"thai":"อุตรดิตถ์","english":"Uttaradit"},
        {"code":5400,"thai":"แพร่","english":"Phrae"},
        {"code":5500,"thai":"น่าน","english":"Nan"},
        {"code":5600,"thai":"พะเยา","english":"Phayao"},
        {"code":5700,"thai":"เชียงราย","english":"Chiang Rai"},
        {"code":5800,"thai":"แม่ฮ่องสอน","english":"Mae Hong Son"},
        {"code":6000,"thai":"นครสวรรค์","english":"Nakhon Sawan"},
        {"code":6100,"thai":"อุทัยธานี","english":"Uthai Thani"},
        {"code":6200,"thai":"กำแพงเพชร","english":"Kamphaeng Phet"},
        {"code":6300,"thai":"ตาก","english":"Tak"},
        {"code":6400,"thai":"สุโขทัย","english":"Sukhothai"},
        {"code":6500,"thai":"พิษณุโลก","english":"Phitsanulok"},
        {"code":6600,"thai":"พิจิตร","english":"Phichit"},
        {"code":6700,"thai":"เพชรบูรณ์","english":"Phetchabun"},
        {"code":7000,"thai":"ราชบุรี","english":"Ratchaburi"},
        {"code":7100,"thai":"กาญจนบุรี","english":"Kanchanaburi"},
        {"code":7200,"thai":"สุพรรณบุรี","english":"Suphan Buri"},
        {"code":7300,"thai":"นครปฐม","english":"Nakhon Pathom"},
        {"code":7400,"thai":"สมุทรสาคร","english":"Samut Sakhon"},
        {"code":7500,"thai":"สมุทรสงคราม","english":"Samut Songkhram"},
        {"code":7600,"thai":"เพชรบุรี","english":"Phetchaburi"},
        {"code":7700,"thai":"ประจวบคีรีขันธ์","english":"Prachuap Khiri Khan"},
        {"code":8000,"thai":"นครศรีธรรมราช","english":"Nakhon Si Thammarat"},
        {"code":8100,"thai":"กระบี่","english":"Krabi"},
        {"code":8200,"thai":"พังงา","english":"Phangnga"},
        {"code":8300,"thai":"ภูเก็ต","english":"Phuket"},
        {"code":8400,"thai":"สุราษฎร์ธานี","english":"Surat Thani"},
        {"code":8500,"thai":"ระนอง","english":"Ranong"},
        {"code":8600,"thai":"ชุมพร","english":"Chumphon"},
        {"code":9000,"thai":"สงขลา","english":"Songkhla"},
        {"code":9100,"thai":"สตูล","english":"Satun"},
        {"code":9200,"thai":"ตรัง","english":"Trang"},
        {"code":9300,"thai":"พัทลุง","english":"Phatthalung"},
        {"code":9400,"thai":"ปัตตานี","english":"Pattani"},
        {"code":9500,"thai":"ยะลา","english":"Yala"},
        {"code":9600,"thai":"นราธิวาส","english":"Narathiwat"},
    ]
}

const mapEngprovince = (code) => {
    switch (code) {
        case 1000: return "Bangkok"
        case 1100: return "Samut Prakan"
        case 1200: return "Nonthaburi"
        case 1300: return "Pathum Thani"
        case 1400: return "Phra Nakhon Si Ayutthaya"
        case 1500: return "Ang Thong"
        case 1600: return "Lop Buri"
        case 1700: return "Sing Buri"
        case 1800: return "Chai Nat"
        case 1900: return "Saraburi"
        case 2000: return "Chon Buri"
        case 2100: return "Rayong"
        case 2200: return "Chanthaburi"
        case 2300: return "Trat"
        case 2400: return "Chachoengsao"
        case 2500: return "Prachin Buri"
        case 2600: return "Nakhon Nayok"
        case 2700: return "Sa Kaeo"
        case 3000: return "Nakhon Ratchasima"
        case 3100: return "Buri Ram"
        case 3200: return "Surin"
        case 3300: return "Si Sa Ket"
        case 3400: return "Ubon Ratchathani"
        case 3500: return "Yasothon"
        case 3600: return "Chaiyaphum"
        case 3700: return "Amnat Charoen"
        case 3800: return "Bueng Kan"
        case 3900: return "Nong Bua Lam Phu"
        case 4000: return "Khon Kaen"
        case 4100: return "Udon Thani"
        case 4200: return "Loei"
        case 4300: return "Nong Khai"
        case 4400: return "Maha Sarakham"
        case 4500: return "Roi Et"
        case 4600: return "Kalasin"
        case 4700: return "Sakon Nakhon"
        case 4800: return "Nakhon Phanom"
        case 4900: return "Mukdahan"
        case 5000: return "Chiang Mai"
        case 5100: return "Lamphun"
        case 5200: return "Lampang"
        case 5300: return "Uttaradit"
        case 5400: return "Phrae"
        case 5500: return "Nan"
        case 5600: return "Phayao"
        case 5700: return "Chiang Rai"
        case 5800: return "Mae Hong Son"
        case 6000: return "Nakhon Sawan"
        case 6100: return "Uthai Thani"
        case 6200: return "Kamphaeng Phet"
        case 6300: return "Tak"
        case 6400: return "Sukhothai"
        case 6500: return "Phitsanulok"
        case 6600: return "Phichit"
        case 6700: return "Phetchabun"
        case 7000: return "Ratchaburi"
        case 7100: return "Kanchanaburi"
        case 7200: return "Suphan Buri"
        case 7300: return "Nakhon Pathom"
        case 7400: return "Samut Sakhon"
        case 7500: return "Samut Songkhram"
        case 7600: return "Phetchaburi"
        case 7700: return "Prachuap Khiri Khan"
        case 8000: return "Nakhon Si Thammarat"
        case 8100: return "Krabi"
        case 8200: return "Phangnga"
        case 8300: return "Phuket"
        case 8400: return "Surat Thani"
        case 8500: return "Ranong"
        case 8600: return "Chumphon"
        case 9000: return "Songkhla"
        case 9100: return "Satun"
        case 9200: return "Trang"
        case 9300: return "Phatthalung"
        case 9400: return "Pattani"
        case 9500: return "Yala"
        case 9600: return "Narathiwat"
    }
}
  
const mapThaiprovince = (code) => {
    switch (code) {
        case 1000: return "กรุงเทพมหานคร"
        case 1100: return "สมุทรปราการ"
        case 1200: return "นนทบุรี"
        case 1300: return "ปทุมธานี"
        case 1400: return "พระนครศรีอยุธยา"
        case 1500: return "อ่างทอง"
        case 1600: return "ลพบุรี"
        case 1700: return "สิงห์บุรี"
        case 1800: return "ชัยนาท"
        case 1900: return "สระบุรี"
        case 2000: return "ชลบุรี"
        case 2100: return "ระยอง"
        case 2200: return "จันทบุรี"
        case 2300: return "ตราด"
        case 2400: return "ฉะเชิงเทรา"
        case 2500: return "ปราจีนบุรี"
        case 2600: return "นครนายก"
        case 2700: return "สระแก้ว"
        case 3000: return "นครราชสีมา"
        case 3100: return "บุรีรัมย์"
        case 3200: return "สุรินทร์"
        case 3300: return "ศรีสะเกษ"
        case 3400: return "อุบลราชธานี"
        case 3500: return "ยโสธร"
        case 3600: return "ชัยภูมิ"
        case 3700: return "อำนาจเจริญ"
        case 3800: return "บึงกาฬ"
        case 3900: return "หนองบัวลำภู"
        case 4000: return "ขอนแก่น"
        case 4100: return "อุดรธานี"
        case 4200: return "เลย"
        case 4300: return "หนองคาย"
        case 4400: return "มหาสารคาม"
        case 4500: return "ร้อยเอ็ด"
        case 4600: return "กาฬสินธุ์"
        case 4700: return "สกลนครn"
        case 4800: return "นครพนม"
        case 4900: return "มุกดาหาร"
        case 5000: return "เชียงใหม่"
        case 5100: return "ลำพูน"
        case 5200: return "ลำปาง"
        case 5300: return "อุตรดิตถ์"
        case 5400: return "แพร่"
        case 5500: return "น่าน"
        case 5600: return "พะเยา"
        case 5700: return "เชียงราย"
        case 5800: return "แม่ฮ่องสอน"
        case 6000: return "นครสวรรค์"
        case 6100: return "อุทัยธานี"
        case 6200: return "กำแพงเพชร"
        case 6300: return "ตาก"
        case 6400: return "สุโขทัย"
        case 6500: return "พิษณุโลก"
        case 6600: return "พิจิตร"
        case 6700: return "เพชรบูรณ์"
        case 7000: return "ราชบุรี"
        case 7100: return "กาญจนบุรี"
        case 7200: return "สุพรรณบุรี"
        case 7300: return "นครปฐม"
        case 7400: return "สมุทรสาคร"
        case 7500: return "สมุทรสงคราม"
        case 7600: return "เพชรบุรี"
        case 7700: return "ประจวบคีรีขันธ์"
        case 8000: return "นครศรีธรรมราช"
        case 8100: return "กระบี่"
        case 8200: return "พังงา"
        case 8300: return "ภูเก็ต"
        case 8400: return "สุราษฎร์ธานี"
        case 8500: return "ระนอง"
        case 8600: return "ชุมพร"
        case 9000: return "สงขลา"
        case 9100: return "สตูล"
        case 9200: return "ตรัง"
        case 9300: return "พัทลุง"
        case 9400: return "ปัตตานี"
        case 9500: return "ยะลา"
        case 9600: return "นราธิวาส"
    }
  }

  const mapprovince = (code) => {
    switch (code) {
        case "กรุงเทพมหานคร": return "Bangkok"
        case "สมุทรปราการ": return "Samut Prakan Province"
        case "นนทบุรี": return "Nonthaburi Province"
        case "ปทุมธานี": return "Pathum Thani Province"
        case "พระนครศรีอยุธยา": return "Phra Nakhon Si Ayutthaya Province"
        case "อ่างทอง": return "Ang Thong Province"
        case "ลพบุรี": return "Lop Buri Province"
        case "สิงห์บุรี": return "Sing Buri Province"
        case "ชัยนาท": return "Chai Nat Province"
        case "สระบุรี": return "Saraburi Province"
        case "ชลบุรี": return "Chon Buri Province"
        case "ระยอง": return "Rayong Province"
        case "จันทบุรี": return "Chanthaburi Province"
        case "ตราด": return "Trat Province"
        case "ฉะเชิงเทรา": return "Chachoengsao Province"
        case "ปราจีนบุรี": return "Prachin Buri Province"
        case "นครนายก": return "Nakhon Nayok Province"
        case "สระแก้ว": return "Sa Kaeo Province"
        case "นครราชสีมา": return "Nakhon Ratchasima Province"
        case "บุรีรัมย์": return "Buri Ram Province"
        case "สุรินทร์": return "Surin Province"
        case "ศรีสะเกษ": return "Si Sa Ket Province"
        case "อุบลราชธานี": return "Ubon Ratchathani Province"
        case "ยโสธร": return "Yasothon Province"
        case "ชัยภูมิ": return "Chaiyaphum Province"
        case "อำนาจเจริญ": return "Amnat Charoen Province"
        case "บึงกาฬ": return "Bueng Kan Province"
        case "หนองบัวลำภู": return "Nong Bua Lam Phu Province"
        case "ขอนแก่น": return "Khon Kaen Province"
        case "อุดรธานี": return "Udon Thani Province"
        case "เลย": return "Loei Province"
        case "หนองคาย": return "Nong Khai Province"
        case "มหาสารคาม": return "Maha Sarakham Province"
        case "ร้อยเอ็ด": return "Roi Et Province"
        case "กาฬสินธุ์": return "Kalasin Province"
        case "สกลนคร": return "Sakon Nakhon Province"
        case "นครพนม": return "Nakhon Phanom Province"
        case "มุกดาหาร": return "Mukdahan Province"
        case "เชียงใหม่": return "Chiang Mai Province"
        case "ลำพูน": return "Lamphun Province"
        case "ลำปาง": return "Lampang Province"
        case "อุตรดิตถ์": return "Uttaradit Province"
        case "แพร่": return "Phrae Province"
        case "น่าน": return "Nan Province"
        case "พะเยา": return "Phayao Province"
        case "เชียงราย": return "Chiang Rai Province"
        case "แม่ฮ่องสอน": return "Mae Hong Son Province"
        case "นครสวรรค์": return "Nakhon Sawan Province"
        case "อุทัยธานี": return "Uthai Thani Province"
        case "กำแพงเพชร": return "Kamphaeng Phet Province"
        case "ตาก": return "Tak Province"
        case "สุโขทัย": return "Sukhothai Province"
        case "พิษณุโลก": return "Phitsanulok Province"
        case "พิจิตร": return "Phichit Province"
        case "เพชรบูรณ์": return "Phetchabun Province"
        case "ราชบุรี": return "Ratchaburi Province"
        case "กาญจนบุรี": return "Kanchanaburi Province"
        case "สุพรรณบุรี": return "Suphan Buri Province"
        case "นครปฐม": return "Nakhon Pathom Province"
        case "สมุทรสาคร": return "Samut Sakhon Province"
        case "สมุทรสงคราม": return "Samut Songkhram Province"
        case "เพชรบุรี": return "Phetchaburi Province"
        case "ประจวบคีรีขันธ์": return "Prachuap Khiri Khan Province"
        case "นครศรีธรรมราช": return "Nakhon Si Thammarat Province"
        case "กระบี่": return "Krabi Province"
        case "พังงา": return "Phangnga Province"
        case "ภูเก็ต": return "Phuket Province"
        case "สุราษฎร์ธานี": return "Surat Thani Province"
        case "ระนอง": return "Ranong Province"
        case "ชุมพร": return "Chumphon Province"
        case "สงขลา": return "Songkhla Province"
        case "สตูล": return "Satun Province"
        case "ตรัง": return "Trang Province"
        case "พัทลุง": return "Phatthalung Province"
        case "ปัตตานี": return "Pattani Province"
        case "ยะลา": return "Yala Province"
        case "นราธิวาส": return "Narathiwat Province"
    }
  }

export default {
    province,
    mapEngprovince,
    mapprovince,
    mapThaiprovince
  }
